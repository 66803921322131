import { useState } from 'react';
import Dashboard from '../../components/Dashboard';
import Footer from '../../components/Footer';
import AppBar from '../../components/AppBar';
import DashBoardModal from '../../components/DashBoardModal';
import Box from '@mui/material/Box';
import RecapModal from '../../components/Dashboard/RecapModal';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
export default function DashboardPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalIcon, setModalIcon] = useState(null);

  const setContent = (value: any) => {
    setModalContent(value);
  };

  const setTitle = (value: string) => {
    setModalTitle(value);
  };

  const setIcon = (value: any) => {
    setModalIcon(value);
  };

  return (
    <Box sx={{ flexGrow: 1, height: '100vh' }}>
      <Grid container style={{ height: '100%' }}>
        <DashBoardModal
          open={isModalOpen}
          modalTitle={modalTitle}
          modalContent={modalContent}
          modalIcon={modalIcon}
          onClose={() => setIsModalOpen(false)}
        />
        <RecapModal />
        <Grid item xs={12} style={{ height: '7%' }}>
          <Box
            style={{
              height: '100%',
              backgroundColor: 'red',
              position: 'relative',
            }}
          >
            <AppBar
              onOpenModal={() => setIsModalOpen(true)}
              setModalContent={setContent}
              setModalTitle={setTitle}
              setModalIcon={setIcon}
            />
          </Box>
        </Grid>
        <Grid item xs={12} style={{ height: '88%' }}>
          <Box style={{ height: '100%' }}>
            <Dashboard
              onOpenModal={() => setIsModalOpen(true)}
              setModalContent={setContent}
              setModalTitle={setTitle}
              setModalIcon={setIcon}
            />
          </Box>
        </Grid>
        <Grid item xs={12} style={{ height: '5%' }}>
          <Box style={{ height: '100%', backgroundColor: 'red' }}>
            <Footer />
          </Box>
        </Grid>
      </Grid>
    </Box>
    // <>
    //   <Box
    //     sx={{
    //       display: 'flex',
    //       flexDirection: 'column',
    //       height: '100vh',
    //     }}
    //   >
    //     <DashBoardModal
    //       open={isModalOpen}
    //       modalTitle={modalTitle}
    //       modalContent={modalContent}
    //       modalIcon={modalIcon}
    //       onClose={() => setIsModalOpen(false)}
    //     />
    //     <RecapModal />
    //     <AppBar
    //       onOpenModal={() => setIsModalOpen(true)}
    //       setModalContent={setContent}
    //       setModalTitle={setTitle}
    //       setModalIcon={setIcon}
    //     />

    //     <Dashboard
    //       onOpenModal={() => setIsModalOpen(true)}
    //       setModalContent={setContent}
    //       setModalTitle={setTitle}
    //       setModalIcon={setIcon}
    //     />
    //     <Footer />
    //   </Box>
    // </>
  );
}
