// import * as React from 'react';
// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';

// export default function BasicSelect() {
//   const [selected, setSelected] = React.useState('');

//   const handleChange = (event: SelectChangeEvent) => {
//     setSelected(event.target.value as string);
//   };

//   return (
//     <Box sx={{ minWidth: 120 }}>
//       <FormControl fullWidth>
//         <InputLabel id="demo-simple-select-label">Mapbox</InputLabel>
//         <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           value={selected}
//           label="Mapbox"
//           onChange={handleChange}
//         >
//           <MenuItem value={10}>MapBox</MenuItem>
//           <MenuItem value={20}>SecureWatch Vivid</MenuItem>
//           <MenuItem value={30}>Custom Ortho</MenuItem>
//         </Select>
//       </FormControl>
//     </Box>
//   );
// }

// import { Box } from '@mui/material';
// import React, { useState } from 'react';
// import styled from 'styled-components';

// const SelectContainer = styled.div`
//   position: relative;
//   display: inline-block;
//   width: 100%;
// `;

// const StyledSelect = styled.select`
//   padding: 8px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   appearance: none;
//   width: 100%;
//   // backgroundcolor: red;
// `;

// const ArrowIcon = styled.div`
//   position: absolute;
//   top: 50%;
//   right: 10px;
//   // width: 100%;
//   transform: translateY(-50%);
// `;

// const StyledOption = styled.option`
//   // background-color: lightgray;
//   color: #333;
//   padding: 20px;
// `;

// interface SelectProps {
//   options: { value: string; label: string }[];
//   onChange: (selectedValue: string) => void;
// }

// const BasicSelect: React.FC<SelectProps> = ({ options, onChange }) => {
//   const [selectedValue, setSelectedValue] = useState(options[0]?.value || '');

//   const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const value = event.target.value;
//     setSelectedValue(value);
//     onChange(value);
//   };

//   return (
//     <SelectContainer style={{ width: '100%' }}>
//       <StyledSelect
//         value={selectedValue}
//         onChange={handleSelectChange}
//         style={{ backgroundColor: 'white' }}
//       >
//         {options.map((option) => (
//           <StyledOption key={option.value} value={option.value}>
//             {option.label}
//           </StyledOption>
//         ))}
//       </StyledSelect>
//       <ArrowIcon>&#9662;</ArrowIcon>
//     </SelectContainer>
//   );
// };

// export default BasicSelect;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface SelectProps {
  options: { value: string; label: string }[];
  onChange: (selectedValue: string) => void;
}

const BasicSelect: React.FC<SelectProps> = ({ options, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(options[0]?.value || '');

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value as string);
    onChange(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          onChange={handleSelectChange}
          size="small"
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
      </FormControl>
    </Box>
  );
};
export default BasicSelect;
