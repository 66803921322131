import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableBody,
  Box,
  Paper,
  Avatar,
  Grid,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
} from '@mui/material';

import UserModal from './userModal';
import useUsers from './useUsers';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';

const UsersTable = (props: any) => {
  const {
    tableContainerRef,
    columns,
    handleCopyClick,
    handleSortClick,
    tableBodyRef,
    usersArray,
    isLoading,
    handleRowClick,
    styles,
    isModalOpen,
    setIsModalOpen,
    lastEvaluatedKey,
    selectedUser,
  } = useUsers(props);
  const navigate = useNavigate();

  return isLoading ? (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  ) : (
    <>
      {/* <Box
        display="flex"
        justifyContent="end"
        alignItems="center"
        sx={{ m: 1, height: '8%' }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<MenuIcon />}
          onClick={() => navigate('/user-management')}
        >
          Accept users
        </Button>
      </Box> */}
      <Paper
        elevation={3}
        sx={{ width: '100%', overflowX: 'auto', height: '100%' }}
      >
        <Box
          ref={tableContainerRef}
          style={{
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <Table style={{ maxHeight: '100%' }}>
            <TableHead
              style={{
                top: 0,
                zIndex: 1,
                backgroundColor: 'white',
                height: '100%',
                // overflow: 'auto',
              }}
            >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    onClick={() => handleSortClick(column.id)}
                  >
                    <Typography
                      fontSize={14}
                      sx={{ color: '#223354', cursor: 'pointer' }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody ref={tableBodyRef}>
              {usersArray?.length > 0 ? (
                usersArray?.map((e: any) => (
                  <TableRow
                    sx={{ cursor: 'pointer' }}
                    hover
                    key={e.sub}
                    onClick={() => handleRowClick(e)}
                  >
                    <TableCell>
                      {e.sub}
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={(event) => handleCopyClick(event, e.sub)}
                      >
                        <FileCopyIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: '#EBEDF3',
                            color: '#71BF49',
                            borderRadius: 1,
                            width: 40,
                            height: 40,
                            fontSize: 14,
                          }}
                          variant="square"
                        >
                          {e.first_name ? e.first_name.charAt(0) : ''}
                        </Avatar>

                        <Grid ml={2}>
                          <Typography
                            sx={{
                              textAlign: 'start',
                              color: 'black',
                            }}
                          >
                            {e.first_name}
                          </Typography>
                          <Typography
                            sx={{
                              color: 'lightslategray',
                              textAlign: 'start',
                              fontSize: 'small',
                            }}
                          >
                            {e.last_name}
                          </Typography>
                        </Grid>
                      </Box>
                    </TableCell>
                    <TableCell>{e.email}</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          ...(styles[e['GSI1-PK'] as keyof typeof styles] ||
                            {}),

                          fontSize: 'small',
                          padding: '4px 8px',
                          borderRadius: '3px',
                          display: 'inline-grid',
                        }}
                      >
                        {e?.groups?.join(', ') || ''}
                      </Typography>
                    </TableCell>
                    <TableCell>{e.country}</TableCell>
                    <TableCell>
                      {e['GSI1-SK']
                        ? new Date(e['GSI1-SK']).toLocaleDateString('en-GB')
                        : ''}
                    </TableCell>
                    <TableCell> {e['area-processed'].toFixed(2)} km²</TableCell>
                    <TableCell>
                      {parseFloat(e?.credits ?? 0).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={16}
                    sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}
                  >
                    <Box sx={{ m: 1 }}>No data available in table</Box>
                  </TableCell>
                </TableRow>
              )}
              {lastEvaluatedKey !== null && (
                <TableRow>
                  <TableCell
                    colSpan={16}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <CircularProgress sx={{ height: 10, m: 1 }} size={20} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Dialog
            maxWidth="xl"
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          >
            <UserModal
              setIsModalOpen={setIsModalOpen}
              selectedUser={selectedUser}
            />
            <Divider />
          </Dialog>
        </Box>
      </Paper>
    </>
  );
};

export default UsersTable;
