import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import HelpIcon from '@mui/icons-material/Help';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import Avatar from '@mui/material/Avatar';
import { useUser } from '../AuthContext';
import Skeleton from '@mui/material/Skeleton';
import MenuList from './Menu/MenuList';
import { Link } from 'react-router-dom';
import logo from '../../assets/BE_Logo.svg';

const StyledTopToolBar = styled(Toolbar)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderRadius: 0,
  backgroundColor: 'white',
  height: '100%',

  '@media (min-width: 600px)': {
    minHeight: '40px',
  },
  '&:hover': {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  },
}));

interface AppBarProps {
  onOpenModal: any;
  setModalContent: any;
  setModalTitle: any;
  setModalIcon?: any;
}

const MuiStyledButton = styled(Button)(({ theme }) => ({
  border: '1px solid white',
  color: 'white',
  textTransform: 'lowercase',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '&:hover': {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  },
}));

export default function AppBar({
  onOpenModal,
  setModalContent,
  setModalTitle,
  setModalIcon,
}: AppBarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [component, setComponent] = useState('notification');
  const open = Boolean(anchorEl);
  const { user } = useUser();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type: string,
  ) => {
    setComponent(type);
    setAnchorEl(event.currentTarget);
  };

  const handleClickBottomBtns = (icon: any, title: string, content: any) => {
    onOpenModal();
    setModalTitle(title);
    setModalContent(content);
    setModalIcon(icon);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const helpContent = () => {
    return (
      <Box>
        <Box>
          Instructions on how to use each component is provided in the top right
          corner of the component. Simply click the question mark icon for help
          with that component.
        </Box>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <HelpIcon style={{ color: 'gray' }} />
        </Box>
      </Box>
    );
  };

  const displayButtons = () => {
    const buttonsArray = [
      {
        name: 'Load Existing',
        icon: <FileOpenIcon />,
        content: helpContent(),
        sx: { mr: 2 },
      },
      {
        name: 'Help',
        icon: <HelpIcon />,
        content: helpContent(),
        sx: { mr: 2 },
      },
      {
        name: 'change log',
        icon: <FormatListBulletedIcon />,
        content: helpContent(),
        sx: {},
      },
    ];
    return buttonsArray.map((x, i) => (
      <Box sx={x.sx} key={i}>
        <MuiStyledButton
          onClick={() => handleClickBottomBtns(x.icon, x.name, x.content)}
        >
          {' '}
          {x.icon}
          {x.name}
        </MuiStyledButton>
      </Box>
    ));
  };

  const handleLoading = () => {
    if (user === null) {
      return <Skeleton variant="rounded" width={410} height={60} />;
    }
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          maxHeight: '40px',
        }}
      >
        <Typography variant="h4">
          {parseFloat(user?.credits ?? 0).toFixed(2)} CREDITS{' '}
        </Typography>
        <Button onClick={(e) => handleClick(e, 'notifications')}>
          <MarkChatUnreadIcon />
        </Button>
        <Button
          onClick={(e) => handleClick(e, 'user')}
          sx={{ maxHeight: '40px' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography variant="h4">Hi, {user?.first_name}</Typography>
            <Avatar
              sx={{ bgcolor: '#ebedf3', borderRadius: 1, color: '#9ccf84' }}
              variant="square"
            >
              {user?.last_name ? user.last_name[0] : ''}
            </Avatar>
          </Box>
        </Button>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        // display: 'flex',
        // flexDirection: 'column',
        height: '100%',
        bgcolor: 'yellow',
        position: 'relative',
      }}
    >
      <MenuList
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        component={component}
      />
      <MuiAppBar sx={{ position: 'relative', height: '100%' }}>
        <StyledTopToolBar>
          <Box sx={{ flexGrow: 1 }}>
            <Link to={'/dashboard'}>
              <img alt="logo" style={{ width: 150 }} src={logo} />
            </Link>
          </Box>

          {handleLoading()}
        </StyledTopToolBar>
      </MuiAppBar>
    </Box>
  );
}
