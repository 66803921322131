import {
  Box,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TransactionsManagementTable from './transactionManagementTable';

const TransactionManagementCard = (props: any) => {
  return (
    <Grid>
      <CardHeader
        title={
          <Typography variant="h6" sx={{ textAlign: 'start', color: 'black' }}>
            Transactions
          </Typography>
        }
        subheader={
          <Typography
            variant="body2"
            sx={{ textAlign: 'start', color: 'lightslategray' }}
          >
            Manage Transactions
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Box>
            <TextField
              size="medium"
              variant="standard"
              placeholder="Search"
              sx={{ m: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <TransactionsManagementTable
          tableContainerRef={props.tableContainerRef}
          tableBodyRef={props.tableBodyRef}
          transactionsArray={props.transactionsArray}
          lastEvaluatedKey={props.lastEvaluatedKey}
        />
      </CardContent>
    </Grid>
  );
};
export default TransactionManagementCard;
