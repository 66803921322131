import BeTc from '../../../../assets/Brightearth/BeTc.png';
import BeFc from '../../../../assets/Brightearth/BeFc.png';
import BeNdvi from '../../../../assets/Brightearth/BeNdvi.png';
import BeDhm from '../../../../assets/Brightearth/BeDhm.png';
import BeDtm from '../../../../assets/Brightearth/BeDtm.png';
import BeCl from '../../../../assets/Brightearth/BeCl.png';
import BeDsm from '../../../../assets/Brightearth/BeDsm.png';
import BeClLegend from '../../../../assets/Brightearth/BeClLegend.png';
import * as Cesium from 'cesium';

const token =
  'eyJraWQiOiI5a04zWk8rd2NYWWk4UldpU1A4NmhMYTJFY0h5MG5pclFrRXEySUF1T3o4PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzYjI2MGIxZS00MTBhLTQ3NDAtOTE2Mi1mYmZiMTJjOTYzYjUiLCJ3ZWJzaXRlIjoiTHV4Y2FydGFcL0NhdGFsb2ciLCJjb2duaXRvOmdyb3VwcyI6WyJBZG1pbiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYWRkcmVzcyI6eyJmb3JtYXR0ZWQiOiIyMDggY2hlbWluIGRlcyBjYWlsbGFkZXMifSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfNkNJWmFxSmszIiwiY29nbml0bzp1c2VybmFtZSI6IjNiMjYwYjFlLTQxMGEtNDc0MC05MTYyLWZiZmIxMmM5NjNiNSIsIm9yaWdpbl9qdGkiOiI1ODJmMDk4Yi00ZTE5LTQwZmMtOGJhZi1iMjBiYmViZTE1MzgiLCJhdWQiOiI2c3R1NW10bWZwaDlpc2VhcTkwcmMyMHNjOCIsImV2ZW50X2lkIjoiM2M3OTRiY2ItZTI2Mi00OGZhLTlkMTItY2I2MjMzMDk2YmVlIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2OTU2NDY0NDgsIm5hbWUiOiJNYXhpbWUiLCJleHAiOjE2OTU3MzI4NDgsImlhdCI6MTY5NTY0NjQ0OCwiZmFtaWx5X25hbWUiOiJEaXppZXIiLCJqdGkiOiI0Zjg1NzYxNS00MWNiLTQ2MzEtOTY4Yy0zYWUxZWUyZjdmYTkiLCJlbWFpbCI6Im1kaXppZXJAbHV4Y2FydGEuY29tIn0.ahOVU-knxLYthbkM6isaM_15HAr-3oqD7ZDOUeNopRHCvb3uWylWMNw7aQ5ZcyK5caPr5ZX6DDoSWe9KCZ8xGFFzQwP0KfQVSh5xAqalhb1HD5AQeioG5d82rBeJFNZzmVifoBHcvAHGFKE0o9Ij4ywTyJH0JqHGTFLqsCveaKHbysgytURXn-V1xN_wBLqEuBDRNAPVdIRzQr2MvHJxnG6ifewqnHHrpU7U4S7MF5j7IlbD7m3eA5FUmxKtUS03hAhQF_NoIHftlBt8Aon96SDe-Kgq5qndzhU67yAm8z273mUmbOxsZsxxnYH0Io_WsQzISJIQa01nDfAX8IDTbg';

type Authorization = {
  Authorization: string;
};

type Imagery = {
  name: string;
  category: string;
  tooltip: string;
  iconUrl: string;
  composer: string | undefined;
  layer: string | undefined;
  headers: Authorization;
  credit: string | undefined;
  hasThreshold: boolean;
  legend: string | undefined;
};

type Overlay = {
  name: string;
  category: string;
  tooltip: string;
  iconUrl: string;
  composer: string;
  layer: string;
  headers: Authorization;
  credit: string | undefined;
  hasThreshold: boolean;
  legend: string | undefined;
  button: null;
};

type Imageries = Imagery[];

type Overlays = Overlay[];

export const imageriesTypes: Imageries = [
  {
    name: 'Mosaic True Colors V4',
    category: 'BrightEarth LuxCarta',
    tooltip: `BrightEarth Sentinel-2 Mosaic (RGB) V4 - 2021`,
    iconUrl: BeTc,
    composer: 'tc',
    layer: 'mosaicv4',
    headers: { Authorization: token },
    credit: 'imageryType.credit || undefined',
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'Mosaic False Colors V4',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth Sentinel-2 Mosaic (IrRG) V4 - 2021',
    iconUrl: BeFc,
    layer: 'mosaicv4',
    composer: 'fc',
    headers: { Authorization: token },
    credit: 'False color mosaic v4 - BrightEarth/Luxcarta 2022',
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'Mosaic True Colors V3',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth Sentinel-2 Mosaic (RGB) V3 - 2021',
    iconUrl: BeTc,
    layer: 'mosaicv3',
    composer: 'tc',
    headers: { Authorization: token },
    credit: 'True color mosaic v3 - BrightEarth/Luxcarta 2021',
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'Mosaic False Colors V3',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth Sentinel-2 Mosaic (IrRG) V3 - 2021',
    iconUrl: BeFc,
    layer: 'mosaicv3',
    composer: 'fc',
    headers: { Authorization: token },
    credit: 'False color mosaic v3 - BrightEarth/Luxcarta 2021',
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'Land Use Land Cover',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth Land Use Land Cover derived from Sentinel-2 Mosaic',
    iconUrl: BeCl,
    layer: 'clutterv2',
    composer: 'generic',
    headers: { Authorization: token },
    credit: 'LULC - BrightEarth/Luxcarta 2021',
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: "DTM",
    category: "BrightEarth LuxCarta",
    tooltip: "BrightEarth DTM (Digital terrain model - Bare ground)",
    iconUrl: BeDtm,
    layer: "dtmv2",
    composer: "cs",
    headers: { Authorization: token },
    credit: "DTM - BrightEarth/Luxcarta 2021",
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'DSM',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth DSM (Digital surface model)',
    iconUrl: BeDsm,
    layer: "dsm",
    composer: 'cs',
    headers: { Authorization: token },
    credit: 'DSM - BrightEarth/Luxcarta 2021',
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: "DHM",
    category: "BrightEarth LuxCarta",
    tooltip: "BrightEarth DHM (Digital height model)",
    iconUrl: BeDhm,
    layer: "dhmv2",
    composer: "cs",
    headers: { Authorization: token },
    credit: "DHM - BrightEarth/Luxcarta 2021",
    hasThreshold: false,
    legend: undefined,
  },
  // {
  //   name: 'Mosaic Water Index V3',
  //   category: 'BrightEarth LuxCarta',
  //   tooltip: 'BrightEarth Sentinel-2 Water Index (WI)',
  //   iconUrl: BeWi,
  //   layer: 'mosaicv3',
  //   composer: 'wi',
  //   headers: { Authorization: token },
  //   credit: 'NDVI - BrightEarth/Luxcarta 2020',
  //   hasThreshold: false,
  //   legend: undefined,
  // },
  // {
  //   name: 'LULC',
  //   category: 'Layers',
  //   tooltip: 'BrightEarth Land Use Land Cover derived from Sentinel-2 Mosaic',
  //   iconUrl: BeCl,
  //   composer: 'generic',
  //   layer: 'clutterv2',
  //   headers: { Authorization: token },
  //   credit: 'imageryType.credit || undefined',
  //   hasThreshold: false,
  //   legend: BeClLegend,
  // },
];



// imageryViewModels.push(new Cesium.ProviderViewModel({
//   name: "Earth at Night",
//   iconUrl: Cesium.buildModuleUrl("Widgets/Images/ImageryProviders/blackMarble.png"),
//   tooltip: "The lights of cities and villages trace the outlines of civilization \
// in this global view of the Earth at night as seen by NASA/NOAA's Suomi NPP satellite.",
//   creationFunction: () => {
//     return Cesium.IonImageryProvider.fromAssetId(3812);
//   }
// }));

// imageryViewModels.push(new Cesium.ProviderViewModel({
//   name: "Natural Earth\u00a0II",
//   iconUrl: Cesium.buildModuleUrl("Widgets/Images/ImageryProviders/naturalEarthII.png"),
//   tooltip: "Natural Earth II, darkened for contrast.\nhttp://www.naturalearthdata.com/",
//   creationFunction: () => {
//     return Cesium.TileMapServiceImageryProvider.fromUrl(
//       Cesium.buildModuleUrl("Assets/Textures/NaturalEarthII")
//     );
//   }
// }));