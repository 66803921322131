import { Box, Divider, Grid, InputAdornment, TextField } from '@mui/material';
import UserManagementTable from './userManagementTable';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback, useEffect, useState } from 'react';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { getUnconfirmedUser } from '../../../api/usersApi';
import { useConfirmUser } from '../../Dashboard/ConfirmUser';
import LinearProgress from '@mui/material/LinearProgress';

const UserManagementCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setUnconfirmedUsersList } = useConfirmUser();

  const fetchUnconfirmedUser = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await tokenWrapper(() => getUnconfirmedUser());
      setUnconfirmedUsersList(response);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching unconfirmed users: ', error);
    }
  }, []);
  useEffect(() => {
    fetchUnconfirmedUser();
  }, []);
  return isLoading ? (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  ) : (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Grid>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Box>
            <TextField
              size="medium"
              variant="standard"
              placeholder="Search"
              sx={{ m: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <UserManagementTable fetchUnconfirmedUser={fetchUnconfirmedUser} />
      </Grid>
    </Box>
  );
};
export default UserManagementCard;
