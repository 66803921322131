import { Box } from '@mui/material';
import TransactionsTable from './transactionsTable';

const TransactionCard = () => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <TransactionsTable />
    </Box>
  );
};
export default TransactionCard;
