import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box/Box';
import Divider from '@mui/material/Divider/Divider';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';

import GroupedSelect from './GroupedSelect';
import { useDashboardContext } from '../DashboardContext';
import LayersSelect from './LayersSelect';
import { LightTooltip } from '../../Tools/Tools';
import { Typography } from '@mui/material';
import { useState } from 'react';
import OutputSelect from './OutputSelect';
interface CheckboxItem {
  value: string;
  label: string;
}

export default function Layers() {
  const {
    formDataValues,
    changeExtractionType,
    handle2Dlayerschange,
    handle3Dlayerschange,
  } = useDashboardContext();
  const { extraction_type, layers_2D, layers_3D } = formDataValues;
  let type_3d: any = '';
  const [vectorsSelected, setVectosSelected] = useState(false);
  const [selected_3d, setSelected_3d] = useState('');

  const _2D: CheckboxItem[] = [
    { value: 'building', label: 'Buildings' },
    { value: 'tree', label: 'Trees' },
    { value: 'road', label: 'Roads' },
  ];

  const handleLayer = (type: string, type3d?: string) => {
    if (type === '2D') {
      return (
        <Box sx={{ width: '100%' }}>
          <LayersSelect
            checkboxItems={_2D}
            disabled={extraction_type === '3D' ? true : false}
            array={layers_2D}
            onChange={handle2Dlayerschange}
          />
        </Box>
      );
    }
  };

  const TooltipContent = () => {
    return (
      <Box sx={{ padding: 1, color: '#a7a7a7' }}>
        <Box sx={{ padding: 1 }}>Output formats explained</Box>
        <Divider />
        <Box sx={{ padding: 1 }}>
          <Box sx={{ color: 'black' }}>Default</Box>
          <Box>- GeoJSON for vectorsl</Box>
          <Box>- PNG with WLD for rasters</Box>
        </Box>
        <Box sx={{ padding: 1 }}>
          <Box sx={{ color: 'black' }}>Additional optional formats</Box>
          <Box>
            Select any of the options in the dropdown below should you wish to
            have these formats available for download.
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Typography variant="subtitle1" sx={{ ml: 1, mb: 1 }}>
        Coordinate system
      </Typography>
      <OutputSelect />
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Box sx={{ fontWeight: 600 }}>
        <Typography variant="subtitle1" sx={{ ml: 1, mb: 1 }}>
          Layers to generate
        </Typography>
      </Box>
      <FormControl style={{ width: '100%' }}>
        <RadioGroup
          style={{ width: '100%' }}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={extraction_type}
          onChange={(e: any) => changeExtractionType(e.target.value, type_3d)}
        >
          <Box
            sx={{
              border: '1px solid whitesmoke',
              borderRadius: 2,
              width: '100%',
            }}
          >
            <Box sx={{ paddingLeft: 2 }}>
              <FormControlLabel
                sx={{
                  width: '100%',
                  '.css-j204z7-MuiFormControlLabel-root': {
                    marginLeft: '-7px',
                  },
                }}
                value="2D"
                checked={extraction_type === '2D'}
                control={<Radio />}
                label={
                  <Box>
                    <Typography variant="body1">
                      2D Vector Layers Only
                    </Typography>
                    {handleLayer('2D')}
                  </Box>
                }
              />
            </Box>
          </Box>

          <Box
            sx={{
              border: '1px solid whitesmoke',
              borderRadius: 2,
              width: '100%',
            }}
          >
            <Box sx={{ padding: 2, paddingBottom: 0, paddingTop: 0 }}>
              <FormControlLabel
                value="3D,vectors"
                control={<Radio />}
                // onChange={() => {
                //   type_3d = 'vectors';
                //   setSelected_3d('vectors');
                // }}
                checked={extraction_type === '3D' && layers_3D[0] === 'vectors'}
                label={
                  <>
                    <Typography variant="body1">3D Vectors Layers</Typography>

                    <Typography
                      variant="body2"
                      sx={{ textAlign: 'start', color: 'lightslategray' }}
                    >
                      Buildings and Trees with heights, Roads
                    </Typography>
                  </>
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid whitesmoke',
              borderRadius: 2,
              width: '100%',
            }}
          >
            <Box
              sx={{
                padding: 2,
                paddingRight: 0,
                paddingBottom: 0,
                paddingTop: 0,
              }}
            >
              <FormControlLabel
                value="3D,vectors&rasters"
                control={<Radio />}
                // onChange={() => {
                //   type_3d = 'vectors&rasters';
                //   setSelected_3d('vectors&rasters');
                // }}
                checked={
                  extraction_type === '3D' && layers_3D[0] === 'vectors&rasters'
                }
                label={
                  <>
                    <Typography variant="body1">
                      3D Vectors and Rasters Layers{' '}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ textAlign: 'start', color: 'lightslategray' }}
                    >
                      All vector layers plus Land Use Map and DTM.
                    </Typography>
                  </>
                }
              />
            </Box>
          </Box>
        </RadioGroup>
      </FormControl>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

      <GroupedSelect />
    </>
  );
}
