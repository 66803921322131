import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';
import { useUser } from '../../../AuthContext';
import { useDashboardContext } from '../../DashboardContext';
import { apiCallWithToken } from '../../../apiCallWithToken';
import { createItemToDisplay } from '../../../Shared';
export const LinearProgressWithLabel = ({ orderId }: any) => {
  const { user } = useUser();
  const [progress, setProgress] = useState(0);
  const { results, setResults } = useDashboardContext();

  useEffect(() => {
    // Function to be run when the component is mounted
    const updateProgress = async () => {
      const userId = user?.['sub'];

      const response = await apiCallWithToken(
        `/users/${userId}/orders/${orderId}`,
      );
      const progress = response['progress'];
      const status = response['GSI2-PK'].split('#')[1];

      setProgress((prevProgress) => {
        // Increment progress by 1 (up to 100)
        const newProgress = prevProgress >= 100 ? 100 : progress * 100;
        const modifyArray = async (newStateValue: string) => {
          // Use the map function to create a new array with the updated object for the specified ID
          if (newStateValue === 'SUCCEEDED') {
            const newItem = await createItemToDisplay(userId, orderId);
            // console.log(obj.id, orderId)
            // Replace the entire object in the array with the new item
            const newArray = results.map((obj) =>
              obj.orderId === orderId ? newItem : obj,
            );
            setResults(newArray);
          } else {
            // If the state is not 'SUCCEEDED', update only the "state" property
            const newArray = results.map((obj) =>
              obj.id === orderId ? { ...obj, state: newStateValue } : obj,
            );
            setResults(newArray);
          }

          clearInterval(intervalId);
        };

        if (status === 'FAILED') {
          modifyArray('FAILED');
        }

        if (newProgress === 100) {
          modifyArray('SUCCEEDED');
        }

        return newProgress;
      });
    };

    // Run the updateProgress function every 5 seconds
    const intervalId = setInterval(updateProgress, 5000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={Math.min(progress, 100)} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          progress,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};
