import axios from 'axios';

export async function apiCallWithToken(
  endpoint: string,
  method: string = 'GET',
  body: any = null,
  contentType: string = 'application/json',
): Promise<any> {
  const refreshToken = localStorage.getItem('refresh_token');
  const accessToken = localStorage.getItem('access_token');
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  if (!accessToken || !refreshToken) {
    return { data: null, error: 'Access token or refresh token not found.' };
  }

  const config = {
    method: method, // HTTP method (get, post, put, delete, etc.)
    url: `${API_BASE_URL}${endpoint}`, // URL you're making the request to
    headers: {
      // Request headers
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': contentType,
    },
    data: body,
  };
  let responseData = { data: null, error: 'API request failed.' };
  try {
    const response = await axios(config);
    // Handle the successful response here
    responseData = response.data;
  } catch (error: any) {
    // Handle the error here
    if (error.response) {
      if (error.response.status === 401) {
        const refreshResponse = await fetch(`${API_BASE_URL}/token/refresh`, {
          method: 'POST',
          headers: {
            'Content-Type': contentType,
          },
          body: JSON.stringify({
            refresh_token: refreshToken,
          }),
        });

        if (refreshResponse.ok) {
          const newTokens = await refreshResponse.json();
          localStorage.setItem('access_token', newTokens.access_token);
          responseData = await apiCallWithToken(
            endpoint,
            method,
            body,
            contentType,
          ); // Retry the original request
        } else {
          responseData = {
            data: null,
            error: 'Failed to refresh access token.',
          };
        }
      }
      // The request was made and the server responded with a status code
      console.error('Error status code:', error.response.status);
      console.error('Error response data:', error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something else went wrong
      console.error('Error:', error.message);
    }
  }

  return responseData;
}
