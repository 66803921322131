import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { apiCallWithToken } from '../../apiCallWithToken';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { useDashboardContext } from '../../Dashboard/DashboardContext';
import { useDialog } from '../../apiCallWithToken/dialogContext';
import { useSnackbar } from '../../SnackbarProvider';
import { useUser } from '../../AuthContext';
type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function TemporaryDrawer() {
  const { isLeftMenuOpen, setLeftMenuOpen, results, setResults, viewerRef } =
    useDashboardContext();
  const { setShowDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { user } = useUser();
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setLeftMenuOpen(false);
    };

  const addLayerToMap = async (userId: string, orderId: string) => {
    const response = await tokenWrapper(() =>
      apiCallWithToken(`/users/${userId}/orders/${orderId}/details`),
    );
    if (response === null) {
      setShowDialog(true);
    }
    let res = [...results];
    res.unshift({
      params: response.detail.params,
      orderId: orderId,
      userId: userId,
      state: 'SUCCEEDED',
    });
    setResults(res);
    showSnackbar('Layer added to the map', '#4CAF50', 'white');
  };

  const addToResults = async () => {
    setResults([]);
    const orderId = 'b76f7b5eb3604138a5bc27c2b1df6c1e';
    const userId = 'adbffbe6-0f1a-49a9-9aea-69e9178f694d';
    // const userId = user?.sub;
    const response = await tokenWrapper(() =>
      apiCallWithToken(`/users/${userId}/orders/${orderId}/details`),
    );
    console.log(response);
    if (response === null) {
      setShowDialog(true);
    }
    let res = [...results];
    res.unshift({
      rectangle: null,
      layers: null,
      params: { name: 'test' },
      orderId: `${orderId}`,
      userId: userId,
      state: 'CREATED',
    });
    setResults(res);
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem key={'add3D'} disablePadding>
          <ListItemButton
            onClick={() =>
              addLayerToMap(
                '3222f926-b602-4041-8edb-37b617612e94',
                '4de02d25b76d4acbb25be52f23827886',
              )
            }
          >
            <ListItemText primary={'Add order 3D'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'add2D'} disablePadding>
          <ListItemButton
            onClick={() =>
              addLayerToMap(
                '3222f926-b602-4041-8edb-37b617612e94',
                '902c4b8ed81a491c96ead82e8240c958',
              )
            }
          >
            <ListItemText primary={'Add order ternopil'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'addNotWorking'} disablePadding>
          <ListItemButton
            onClick={() =>
              addLayerToMap(
                '6a78a9ed-891e-4b9d-baad-5330ed4d07f0',
                '00f6a64a06ca4373955e3daf3950b84d',
              )
            }
          >
            <ListItemText primary={'Add order grnoble'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'infos'} disablePadding>
          <ListItemButton
            onClick={() => console.log(viewerRef.current.cesiumElement)}
          >
            <ListItemText primary={'log viewerRef'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'infos'} disablePadding>
          <ListItemButton
            onClick={() => console.log(viewerRef.current.cesiumElement)}
          >
            <ListItemText primary={'log viewerRef'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'results'} disablePadding>
          <ListItemButton onClick={() => console.log(results)}>
            <ListItemText primary={'log results'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'results'} disablePadding>
          <ListItemButton onClick={() => addToResults()}>
            <ListItemText primary={'add element to results'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'results'} disablePadding>
          <ListItemButton onClick={() => localStorage.clear()}>
            <ListItemText primary={'Remove all tokens'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'results'} disablePadding>
          <ListItemButton
            onClick={() => localStorage.setItem('access_token', 'aa')}
          >
            <ListItemText primary={'Remove access tokens'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={'left'}>
        <Drawer
          anchor={'left'}
          open={isLeftMenuOpen}
          onClose={toggleDrawer('left', false)}
        >
          {list('left')}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
