import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useDashboardContext } from '../../../../DashboardContext';
import { useDialog } from '../../../../../apiCallWithToken/dialogContext';
import * as Cesium from 'cesium';

import StyleMenu, { COLOR_style, RFCOLOR_style } from '../StyleMenu';
export default function RadioLayer({
  order,
  layer,
  // radioValue,
  // setRadio,
  _3dTile,
}: any) {
  const [radioValue, setRadio] = useState('Hide');
  const {
    updateCesiumGlobe,
    setMapLoading,
    layersDisplayed,
    setLayersDisplayed,
    cesiumGlobe,
    results,
    viewerRef,
  } = useDashboardContext();

  const { setShowDialog } = useDialog();
  let viewer = viewerRef.current.cesiumElement;
  const { orderId } = order;

  const radioStyle = {
    padding: '5px',
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
  };

  React.useEffect(() => {
    console.log('order ==>', order);
    console.log('layer ==>', layer);
    setRadio('Hide');
  }, [results]);

  const createUniqueId = (layer: string, id: string, layerName: string) => {
    return `${id}-${layerName}-${layer}`;
  };

  const replaceName = (nameToReplace: string) => {
    const names = [
      { old: 'dtm', new: 'DTM' },
      { old: 'clutter', new: 'CLUTTER' },
    ];
    const nameToReturn = names.find((x: any) => x.old === nameToReplace);
    if (nameToReturn) {
      return nameToReturn.new;
    }
    return nameToReplace;
  };

  const add2d = (layer: any) => {
    console.log('creating new 2D');
    setMapLoading(true);
    const token = localStorage.getItem('access_token');
    if (!token) {
      setMapLoading(false);
      setShowDialog(true);
    } else {
      setMapLoading(false);

      const uniqueId = createUniqueId('2D', orderId, layer['layer_name']);
      const token = localStorage.getItem('access_token');
      const customResource = new Cesium.Resource({
        url: layer.urls.find((el: any) => el.type === '2D').url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const wms: any = new Cesium.WebMapServiceImageryProvider({
        url: customResource,
        tileWidth: 512,
        tileHeight: 512,
        enablePickFeatures: false,
        tilingScheme: new Cesium.WebMercatorTilingScheme(),
        crs: 'EPSG:3857',

        parameters: {
          _defaultAlpha: 0.1,
          service: 'WMS',
          version: '1.1.0',
          request: 'GetMap',
          format: 'image/png',
          transparent: true,

          id: uniqueId,
        },
        layers: layer['layer_name'],
      });
      wms.id = uniqueId;

      const imageryLayer = new Cesium.ImageryLayer(wms, {
        rectangle: order.rectangle,
      });
      viewer.imageryLayers.add(imageryLayer);
      setLayersDisplayed([
        ...layersDisplayed,
        { id: uniqueId, layer: imageryLayer, type: layer.type },
      ]);
      updateCesiumGlobe(!cesiumGlobe);
    }
  };

  const add3d = async (layer: any) => {
    console.log('3D added', viewer.scene.primitives);
    const uniqueId = createUniqueId('3D', orderId, layer['layer_name']);
    setMapLoading(true);
    const token = localStorage.getItem('access_token');
    if (!token) {
      setMapLoading(false);
      setShowDialog(true);
    } else {
      const customResource = new Cesium.Resource({
        url: layer.urls.find((el: any) => el.type === '3D').url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const tileset: any = await Cesium.Cesium3DTileset.fromUrl(customResource);
      tileset.lightColor = new Cesium.Cartesian3(1, 1, 1);
      tileset.style = layer.layer_name === 'tree' ? COLOR_style : RFCOLOR_style;
      tileset['id'] = uniqueId;
      viewer.scene.primitives.add(tileset);
      setMapLoading(false);
      setLayersDisplayed([
        ...layersDisplayed,
        { id: uniqueId, layer: tileset, type: layer.type },
      ]);
      updateCesiumGlobe(!cesiumGlobe);
    }
  };

  const create3dTile = (x: any) => {
    hide2dTile();
    if (
      viewer.scene.primitives._primitives.some(
        (x: any) => x.id === createUniqueId('3D', orderId, layer['layer_name']),
      )
    ) {
      const _3dTile = viewer.scene.primitives._primitives.find(
        (x: any) => x.id === createUniqueId('3D', orderId, layer['layer_name']),
      );

      _3dTile.show = true;

      console.log(layer.layer_name);
      return;
    }
    add3d(layer);
  };

  const create2dTile = (x: any) => {
    hide3dTile();
    // if (
    //   layersDisplayed.find(
    //     (x: any) => x.id === createUniqueId('2D', id, layerName),
    //   )
    // ) {
    //   const _2dTile = viewer.imageryLayers._layers.find(
    //     (x: any) =>
    //       x._imageryProvider.id === createUniqueId('2D', id, layerName),
    //   );
    //   _2dTile.show = true;
    //   return;
    // }
    if (
      viewer.imageryLayers._layers.some(
        (x: any) =>
          x._imageryProvider.id ===
          createUniqueId('2D', orderId, layer['layer_name']),
      )
    ) {
      const _2dTile = viewer.imageryLayers._layers.find(
        (x: any) =>
          x._imageryProvider.id ===
          createUniqueId('2D', orderId, layer['layer_name']),
      );
      _2dTile.show = true;
      return;
    }
    add2d(layer);
  };

  const displayCheckboxes = () => {
    if (layer.urls.length === 0) {
      return;
    }
    return layer.urls.map((x: any, i: number) => (
      <FormControlLabel
        value={x.type === '2D' ? '2D' : '3D'}
        control={
          <Radio
            sx={radioStyle}
            onChange={() =>
              x.type === '2D' ? create2dTile(x) : create3dTile(x)
            }
          />
        }
        label={x.type === '2D' ? '2D' : '3D'}
      />
    ));
  };

  const hide2dTile = () => {
    console.log('hide 2D');
    if (
      viewer.imageryLayers._layers.some(
        (x: any) =>
          x._imageryProvider.id ===
          createUniqueId('2D', orderId, layer['layer_name']),
      )
    ) {
      const layerToHide = viewer.imageryLayers._layers.find(
        (x: any) =>
          x._imageryProvider.id ===
          createUniqueId('2D', orderId, layer['layer_name']),
      );
      console.log('2D found', layerToHide);
      layerToHide.show = false;
      console.log('viewer.imageryLayers._layers', viewer.imageryLayers._layers);
    } else {
      console.log('2d file not found');
    }
  };

  const hide3dTile = () => {
    console.log('hide 3D');
    if (
      viewer.scene.primitives._primitives.some(
        (x: any) => x.id === createUniqueId('3D', orderId, layer['layer_name']),
      )
    ) {
      const layerToHide = viewer.scene.primitives._primitives.find(
        (x: any) => x.id === createUniqueId('3D', orderId, layer['layer_name']),
      );
      console.log('3D found', layerToHide);
      layerToHide.show = false;
      console.log(
        'viewer.scene.primitives._primitives',
        viewer.scene.primitives._primitives,
      );
    } else {
      console.log('3d tile not fopund');
    }
  };

  const removeLayer = () => {
    hide2dTile();
    hide3dTile();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={radioValue}
          onChange={(event) =>
            setRadio((event.target as HTMLInputElement).value)
          }
        >
          <FormControlLabel
            value="Hide"
            control={
              <Radio
                defaultChecked={true}
                sx={radioStyle}
                onChange={() => removeLayer()}
              />
            }
            label={<Typography fontSize={'small'}>{'Hide'}</Typography>}
          />

          {displayCheckboxes()}
        </RadioGroup>
      </FormControl>

      {radioValue === '3D' && (
        <StyleMenu
          layerName={layer['layer_name']}
          id={orderId}
          radioValue={radioValue}
          _3dTile={_3dTile}
          orderType={order.params.type}
        />
      )}
    </Box>
  );
}
