import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box/Box';

interface AccordionWrapperProps {
  array: any;
}

interface AccordionElement {
  title: string;
  icon: any;
  text: string;
}

export default function AccordionWrapper({ array }: AccordionWrapperProps) {
  const handleAccordionSummary = () => {
    return array?.map((e: AccordionElement, i: number) => (
      <Accordion key={i}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            {e.icon}
            <Typography>{e.title}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{e.text}</Typography>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return <Box>{handleAccordionSummary()}</Box>;
}
