import React, { createContext, useState, useContext, ReactNode } from 'react';
interface ProgressContextType {
  progressList: any | null;
  setProgressList: (userData: any) => void;
}

const ProgressContext = createContext<ProgressContextType | undefined>(
  undefined,
);

const useProgress = () => {
  const context = useContext(ProgressContext);
  if (!context) {
    throw new Error('useProgress must be used within a ProgressProvider');
  }
  return context;
};

const ProgressProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [progressList, setProgressList] = useState<any | null>(null);

  const contextValues: ProgressContextType = {
    progressList,
    setProgressList,
  };

  return (
    <ProgressContext.Provider value={contextValues}>
      {children}
    </ProgressContext.Provider>
  );
};

export { ProgressProvider, useProgress };
