import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Popover,
  Typography,
} from '@mui/material';
interface PopoverType {
  open: boolean;
  anchorEl: HTMLElement | null;
  handlePopoverClose: () => void;
  title: string;
  text: string;
}
const PopoverCard = (props: PopoverType) => {
  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={props.handlePopoverClose}
    >
      <Card sx={{ width: 300 }}>
        <CardHeader
          title={
            <Typography
              sx={{ textAlign: 'start' }}
              variant="body2"
              fontWeight="bold"
            >
              {props.title}
            </Typography>
          }
        />
        <Divider></Divider>
        <CardContent>
          <Box>{props.text}</Box>
        </CardContent>
      </Card>
    </Popover>
  );
};
export default PopoverCard;
