import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import * as Cesium from 'cesium';
import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton } from '@mui/material';
import { Button, Menu, InputLabel } from '@mui/material';
export const RFCOLOR_style = new Cesium.Cesium3DTileStyle({
  color: 'color(${RFCOLOR}, 1.0)',
  meta: {
    description: '"Colors from roof color."',
  },
});
export const COLOR_style = new Cesium.Cesium3DTileStyle({
  color: 'color(${COLOR}, 1.0)',
  meta: {
    description: '"Colors from tree color."',
  },
});
const StyleMenu = ({ layerName, radioValue, _3dTile, orderType }: any) => {
  const [selectedValue, setSelectedValue] = useState(
    layerName === 'tree' ? 'color' : 'rfcolor',
  );

  function clamp(value: any, min: any, max: any) {
    return Math.min(Math.max(value, min), max);
  }

  function get_color(value: any) {
    var colorRamp = [
      // low value, color
      [0, [0, 50, 255]],
      [10, [128, 255, 255]],
      [20, [0, 255, 0]],
      [40, [255, 0, 0]],
      [50, [255, 255, 0]],
      [200, [255, 255, 255]],
      [1000, [0, 0, 0]],
    ];

    // clamp the value to the range of the color ramp
    value = clamp(value, colorRamp[0][0], colorRamp[colorRamp.length - 1][0]);

    // determine the range of the color ramp where the value falls
    for (var i = 0; i < colorRamp.length - 1; i++) {
      var lo_value: any = colorRamp[i][0];
      var hi_value: any = colorRamp[i + 1][0];

      if (value >= lo_value && value <= hi_value) {
        // range found

        // normalize the value to the range 0.0 - 1.0
        var percent = (value - lo_value) / (hi_value - lo_value);
        var inv_percent = 1.0 - percent;

        var lo_color: any = colorRamp[i][1];
        var hi_color: any = colorRamp[i + 1][1];

        // interpolate between the colors
        var interp_color = [
          lo_color[0] * inv_percent + hi_color[0] * percent,
          lo_color[1] * inv_percent + hi_color[1] * percent,
          lo_color[2] * inv_percent + hi_color[2] * percent,
        ];

        return new Cesium.Color(
          interp_color[0] / 255.0,
          interp_color[1] / 255.0,
          interp_color[2] / 255.0,
        );
      }
    }
  }

  const AGLstyle: any = new Cesium.Cesium3DTileStyle();

  AGLstyle.color = {
    evaluateColor: function (feature: any, result: any) {
      const color = get_color(feature.getProperty('AGL'));

      // Make sure color is not undefined before using it
      if (color) {
        return Cesium.Color.clone(color, result);
      } else {
        // Handle the case where get_color returns undefined
        // You can choose to return a default color or do something else
        return Cesium.Color.clone(Cesium.Color.WHITE, result);
      }
    },
  };

  // const AGL_style = new Cesium.Cesium3DTileStyle({
  //   color: {
  //     conditions: [
  //       ['${AGL} < 5', 'color("purple")'],
  //       ['${AGL} < 10', 'color("red")'],
  //       ['${AGL} < 15', 'color("yellow")'],
  //       ['${AGL} < 20', 'color("blue")'],
  //       ['${AGL} < 50', 'color("black")'],
  //       ['true', 'color("blue")'],
  //     ],
  //   },
  //   show: '${AGL} > 0',
  //   meta: {
  //     description: '"Colors from height."',
  //   },
  // });

  const RFTYPE_style = new Cesium.Cesium3DTileStyle(
    // Color buildings based on the roof type enum attribute
    // Applicable to: BUILDING 2D & 3D
    {
      color: {
        conditions: [
          ['${RFTYPE} === "Flat"', 'color("PaleTurquoise")'], // Flat
          ['${RFTYPE} === "Gabled"', 'color("AntiqueWhite")'], // Gabled
          ['${RFTYPE} === "Hipped"', 'color("Brown")'], // Hipped
          ['${RFTYPE} === "Skillion"', 'color("Chocolate")'], // Skillion
          ['${RFTYPE} === "Mansard"', 'color("Crimson")'], // Mansard
          ['${RFTYPE} === "Round"', 'color("Chartreuse")'], // Round
          ['${RFTYPE} === "Dome"', 'color("GoldenRod")'], // Dome
          ['${RFTYPE} === "Silo"', 'color("Indigo")'], // Silo
          ['${RFTYPE} === "Other"', 'color("white")'], // Other

          ['true', 'color("white")'],
        ],
      },
      show: true,
      meta: {
        description: '"Colors from roof type."',
      },
    },
  );

  const CLUTTER_style = new Cesium.Cesium3DTileStyle(
    // Color buildings based on the roof type enum attribute
    // Applicable to: BUILDING-3D, TREE-3D
    {
      color: {
        conditions: [
          ['${CLUTTER} === "LOW VEGETATION"', 'color("#00ff00")'], // TREES
          ['${CLUTTER} === "VEGETATION"', 'color("#008000")'], // TREES
          ['${CLUTTER} === "DENSE VEGETATION"', 'color("#808000")'], // TREES

          ['${CLUTTER} === "VILLAGE"', 'color("#8080ff")'], // BUILDINGS
          ['${CLUTTER} === "RESIDENTIAL WITH TREES"', 'color("#ff80c0")'], // BUILDINGS
          ['${CLUTTER} === "RESIDENTIAL WITH FEW TREES"', 'color("#ff80ff")'], // BUILDINGS
          ['${CLUTTER} === "DENSE RESIDENTIAL"', 'color("#ff00ff")'], // BUILDINGS
          ['${CLUTTER} === "URBAN"', 'color("#ff0080")'], // BUILDINGS
          ['${CLUTTER} === "DENSE URBAN"', 'color("#8000ff")'], // BUILDINGS
          ['${CLUTTER} === "HIGH BUILDINGS"', 'color("#800040")'], // BUILDINGS
          ['${CLUTTER} === "BUILDING BLOCKS"', 'color("#010101")'], // BUILDINGS
          ['${CLUTTER} === "COMMERCIAL INDUSTRIAL"', 'color("#ff8000")'], // BUILDINGS
          ['${CLUTTER} === "AIRPORT"', 'color("#804000")'], // BUILDINGS

          ['true', 'color("white")'],
        ],
      },
      show: true,
      meta: {
        description: '"Colors from clutter value."',
      },
    },
  );

  const handleSelectChange = (value: string) => {
    let style;
    switch (value) {
      case 'agl':
        style = AGLstyle;
        break;
      case 'color':
        style = COLOR_style;
        break;
      case 'rfcolor':
        style = RFCOLOR_style;
        break;
      case 'rftype':
        style = RFTYPE_style;
        break;
      case 'clutter':
        style = CLUTTER_style;
        break;
      case 'default':
        style = new Cesium.Cesium3DTileStyle({
          color: layerName === 'tree' ? 'color("#00D000")' : 'color("#D0D0D0")',
          show: true,
          meta: {
            description: '"Uniform  color."',
          },
        });
        break;
      default:
        style = null;
        break;
    }

    setSelectedValue(value as string);

    if (_3dTile) {
      _3dTile.style = style;
    }
  };

  const handleOptions = () => {
    const optionsTree = [
      { value: 'color', label: 'Color' },

      { value: 'default', label: 'Uniform' },
      { value: 'agl', label: 'AGL' },
      { value: 'clutter', label: 'Clutter' },
    ];

    const optionsBuilding = [
      { value: 'rfcolor', label: 'RFColor' },

      { value: 'default', label: 'Uniform' },
      { value: 'agl', label: 'AGL' },
      { value: 'rftype', label: 'RFType' },
      { value: 'clutter', label: 'Clutter' },
    ];
    if (layerName === 'tree') {
      if (orderType === '2D')
        return optionsTree.filter((x: any) => x.value !== 'clutter');
      return optionsTree;
    } else {
      if (orderType === '2D')
        return optionsBuilding.filter((x: any) => x.value !== 'clutter');
      return optionsBuilding;
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [selectedValue, setSelectedValue] = useState<string>(options[1]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: string) => {
    setSelectedValue(value);
    // onChange(value);
    handleClose();
  };

  const renderSelect = () => {
    if (radioValue === '3D') {
      return (
        <FormControl>
          <IconButton onClick={handleClick} size="small">
            <ArrowDropDownIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {handleOptions().map((option, index) => (
              <MenuItem
                sx={
                  option.value === selectedValue ? { bgcolor: '#eef3f7' } : null
                }
                key={index}
                onClick={() => handleSelectChange(option.value)}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </FormControl>
      );
    }

    return null;
  };
  return renderSelect();
};
export default StyleMenu;
