import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import JobsManagementTable from './jobsManagementTable';
import ClearIcon from '@mui/icons-material/Clear';
import useOrders from '../ProcessingJobsCard/useOrders';

const JobsManagementCard = (props: any) => {
  const {
    state,
    searchQuery,
    type,

    setSearchQuery,
    handleModalOpen,
  } = useOrders();
  return (
    <Grid>
      <CardHeader
        title={
          <Typography variant="h6" sx={{ textAlign: 'start', color: 'black' }}>
            Orders
          </Typography>
        }
        subheader={
          <Typography
            variant="body2"
            sx={{ textAlign: 'start', color: 'lightslategray' }}
          >
            Manage Orders
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Box
          sx={{ textAlign: 'start', width: '50%', m: 2, mb: 8 }}
          display="flex"
          justifyContent="space-between"
        >
          <FormControl variant="standard" style={{ width: '15rem' }}>
            <InputLabel
              sx={{
                color: '#3F4254',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              State
            </InputLabel>

            <Select
              defaultValue={1}
              sx={{
                minWidth: '100%',
                '& .MuiSelect-select.MuiSelect-select': {
                  color: '#206294',
                },
              }}
            >
              {state.map((state) => (
                <MenuItem value={state.id} key={state.id}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      width: 'fit-content',
                      p: 0.5,
                      borderRadius: 2,
                    }}
                  >
                    {state.text}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" style={{ width: '15rem' }}>
            <InputLabel
              sx={{
                color: '#3F4254',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              Type
            </InputLabel>

            <Select
              defaultValue={1}
              sx={{
                minWidth: '100%',
                '& .MuiSelect-select.MuiSelect-select': {
                  color: '#206294',
                },
              }}
            >
              {type.map((type) => (
                <MenuItem value={type.id} key={type.id}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      width: 'fit-content',
                      p: 0.5,
                      borderRadius: 2,
                    }}
                  >
                    {type.text}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box alignSelf="end" justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              sx={{
                textTransform: 'none',
                height: '2.5rem',
                mr: 1,
              }}
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                height: '2.5rem',
                backgroundColor: '#71BF49',
              }}
              startIcon={<ClearIcon />}
            >
              Reset
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Box>
            <TextField
              size="medium"
              variant="standard"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              sx={{ m: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {props.selectedRow ? (
            <Box>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'red',
                  },
                }}
                startIcon={<DeleteIcon />}
                onClick={handleModalOpen}
              >
                Delete
              </Button>
            </Box>
          ) : null}
        </Box>

        <JobsManagementTable
          tableContainerRef={props.tableContainerRef}
          tableBodyRef={props.tableBodyRef}
          orderArray={props.orderArray}
          lastEvaluatedKey={props.lastEvaluatedKey}
          selectedRow={props.selectedRow}
          onRowClick={props.onRowClick}
          searchQuery={searchQuery}
        />
      </CardContent>
    </Grid>
  );
};
export default JobsManagementCard;
