import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableBody,
  Box,
  Paper,
  Avatar,
  Grid,
  CircularProgress,
  IconButton,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useOrders from '../ProcessingJobsCard/useOrders';
import CircularWithValueLabel from '../../UserDashBoard/ProcessingLog/CircularWithValueLabel';
const JobsManagementTable = (props: any) => {
  const isLoading = props.orderArray.length === 0;

  const columns = [
    { id: 'processId', label: 'Order ID' },
    { id: 'userFirstName', label: 'User' },
    { id: 'state', label: 'State' },
    { id: 'submitTime', label: 'Submit Time' },
    { id: 'startTime', label: 'Start Time' },
    { id: 'endTime', label: 'End Time' },
    { id: 'runtime', label: 'Runtime' },
    { id: 'progress', label: 'Progress' },
    { id: 'name', label: 'Name' },
    { id: 'type', label: 'Type' },
    { id: 'cost', label: 'Cost' },
    { id: 'area', label: 'Area' },
    { id: 'resolution', label: 'Resolution' },
    { id: 'srs', label: 'SRS' },
    { id: 'rasterFormats', label: 'Raster Formats' },
    { id: 'vectorFormats', label: 'Vector Formats' },
    { id: 'output', label: 'Output' },
  ];

  const {
    handleSortClick,

    handleCopyClick,
  } = useOrders();

  return (
    <Paper sx={{ width: '100%' }}>
      <Box
        ref={props.tableContainerRef}
        style={{
          maxHeight: `calc(100vh - (90px) )`,
          overflowY: 'auto',
        }}
      >
        <Table>
          <TableHead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'white',
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  onClick={() => handleSortClick(column.id)}
                >
                  <Typography
                    fontSize={14}
                    sx={{ color: '#223354', cursor: 'pointer' }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody ref={props.tableBodyRef}>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={16}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress sx={{ height: 10, m: 1 }} size={20} />
                </TableCell>
              </TableRow>
            ) : (
              props?.orderArray?.map((e: any) => (
                <TableRow
                  sx={{
                    cursor: 'pointer',
                    backgroundColor:
                      props.selectedRow === e ? '#E0E0E0' : 'transparent',
                  }}
                  hover
                  onClick={() => props.onRowClick(e)}
                  key={`${e?.sub}${e['GSI1-SK']}`}
                >
                  <TableCell>
                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        variant="body1"
                        sx={{ wordWrap: 'break-word', maxWidth: '150px' }}
                      >
                        {`${e?.SK?.split('#')[1]}`}
                      </Typography>
                      <Box alignSelf={'center'}>
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={(event) => handleCopyClick(event, e?.SK)}
                        >
                          <FileCopyIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: '#EBEDF3',
                          color: '#71BF49',
                          borderRadius: 1,
                          width: 40,
                          height: 40,
                          fontSize: 14,
                        }}
                      >
                        {e?.first_name?.charAt(0)}
                      </Avatar>

                      <Grid ml={2}>
                        <Typography
                          sx={{
                            textAlign: 'start',
                            color: 'black',
                          }}
                        >
                          {e['is_user_deleted'] === true
                            ? 'User deleted'
                            : e.first_name + ' ' + e.last_name}
                        </Typography>
                        <Typography
                          sx={{
                            color: 'lightslategray',
                            textAlign: 'start',
                            fontSize: 'small',
                          }}
                        >
                          {e?.email}
                        </Typography>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <CheckCircleIcon
                      sx={{
                        backgroundColor: '#EBEDF3',
                        color: '#71BF49',
                        width: '20px',
                        height: '20px',
                        padding: '3px 5px',
                        borderRadius: '3px',
                        display: 'inline-grid',
                      }}
                    ></CheckCircleIcon>
                  </TableCell>
                  <TableCell>
                    {new Date(e['GSI1-SK']).toLocaleDateString('en-GB')}
                  </TableCell>
                  <TableCell>
                    {new Date(e['GSI1-SK']).toLocaleDateString('en-GB')}
                  </TableCell>
                  <TableCell>
                    {new Date(e['GSI1-SK']).toLocaleDateString('en-GB')}
                  </TableCell>
                  <TableCell>{0}</TableCell>
                  <TableCell>
                    <CircularWithValueLabel
                      progress={e?.progress}
                    ></CircularWithValueLabel>{' '}
                  </TableCell>
                  <TableCell>{e?.params?.name}</TableCell>
                  <TableCell>{e?.params?.type}</TableCell>
                  <TableCell>{e?.cost}</TableCell>
                  <TableCell>{e?.area}</TableCell>
                  <TableCell>{e?.params?.resolution}</TableCell>
                  <TableCell>{e?.params?.srs}</TableCell>
                  <TableCell>
                    {e?.params?.formats?.raster?.map((r: any, index: any) => (
                      <Typography
                        key={`${index}${r}`}
                        sx={{
                          backgroundColor: '#EBEDF3',
                          color: '#71BF49',
                          fontSize: 'small',
                          padding: '4px 8px',

                          borderRadius: '3px',
                          display: 'inline-grid',
                          m: 0.5,
                        }}
                      >
                        {r}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    {e?.params?.formats?.vector?.map((v: any, index: any) => (
                      <Typography
                        key={`${index}${v}`}
                        sx={{
                          backgroundColor: '#EBEDF3',
                          color: '#71BF49',
                          fontSize: 'small',
                          padding: '4px 8px',
                          borderRadius: '3px',
                          display: 'inline-grid',
                          m: 0.5,
                        }}
                      >
                        {v}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          {props.lastEvaluatedKey !== null && (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={16}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress sx={{ height: 10, m: 1 }} size={20} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Box>
    </Paper>
  );
};

export default JobsManagementTable;
