import { Avatar, Box, Button, Divider, Grid, Typography } from '@mui/material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import LockIcon from '@mui/icons-material/Lock';
import PlaceIcon from '@mui/icons-material/Place';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import VerifiedIcon from '@mui/icons-material/Verified';
import useAdminInformation from './useAdminInformation';

const AdminInformation = () => {
  const { informationItems, items, user } = useAdminInformation();
  return (
    <>
      <Grid
        container
        minWidth={'100%'}
        padding={5}
        justifyContent="space-between"
      >
        <Grid sx={{ display: 'flex' }}>
          <Avatar
            sx={{
              backgroundColor: '#206294',
              borderRadius: 1,
              width: 125,
              height: 125,
            }}
            variant="square"
          >
            {user?.first_name?.charAt(0)}
          </Avatar>

          <Box ml={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: 'start',
                  color: 'black',
                  mr: 1,
                  '&:hover': {
                    color: '#206294',
                  },
                }}
              >
                {user?.first_name + ' ' + user?.last_name}
              </Typography>
              <VerifiedIcon
                fontSize="small"
                sx={{ color: '#71BF49' }}
              ></VerifiedIcon>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    color: '#206294',
                  },
                }}
              >
                <MarkEmailReadIcon fontSize="inherit" />
                <Typography
                  variant="body2"
                  sx={{
                    color: 'lightslategray',
                    textAlign: 'start',
                    ml: 1,
                    '&:hover': {
                      color: '#206294',
                    },
                  }}
                >
                  {user?.email}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    color: '#206294',
                  },
                }}
              >
                <LockIcon fontSize="inherit" sx={{ ml: 2 }} />
                <Typography
                  variant="body2"
                  sx={{
                    color: 'lightslategray',
                    textAlign: 'start',
                    ml: 1,
                    '&:hover': {
                      color: '#206294',
                    },
                  }}
                >
                  {(user && user.groups && user.groups.join(', ')) || ''}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    color: '#206294',
                  },
                }}
              >
                <PlaceIcon fontSize="inherit" sx={{ ml: 2 }} />
                <Typography
                  variant="body2"
                  sx={{
                    color: 'lightslategray',
                    textAlign: 'start',
                    ml: 1,
                    '&:hover': {
                      color: '#206294',
                    },
                  }}
                >
                  {user?.country}
                </Typography>
              </Box>
            </Box>
            <Box mt={3}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    color: '#71BF49',
                  },
                }}
              >
                <CheckIcon fontSize="inherit"></CheckIcon>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'lightslategray',
                    textAlign: 'start',
                    ml: 1,
                    '&:hover': {
                      color: '#71BF49',
                    },
                  }}
                >
                  Your permissions include viewing and approving users. You can
                  also view processing orders.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    color: 'red',
                  },
                }}
              >
                <ClearIcon fontSize="inherit"></ClearIcon>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'lightslategray',
                    textAlign: 'start',
                    ml: 1,
                    '&:hover': {
                      color: 'red',
                    },
                  }}
                >
                  You cannot edit users or web app configuration with your admin
                  permission level.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mb={3}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{
                color: '#71BF49',
                backgroundColor: '#EBEDF3',
                '&:hover': {
                  backgroundColor: '#71BF49',
                  color: 'white',
                },
              }}
            >
              {parseFloat(user?.credits ?? 0).toFixed(2)}
            </Button>
          </Box>

          <Grid container spacing={{ xs: 2 }}>
            {informationItems.map((item) => (
              <Grid item key={item.id}>
                <Typography fontSize={'small'} fontWeight="bold" mb={1}>
                  {item.title}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{
                    textTransform: 'none',
                    color: '#206294',
                    backgroundColor: '#E1F0FF',
                    '&:hover': {
                      color: 'white',
                    },
                  }}
                >
                  {item.text}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Divider />

      <Grid container spacing={2} columns={13} m={3}>
        {items.map((item) => (
          <Grid key={item.id} item xs={2} mr={2}>
            <Box display="flex" alignItems="center">
              {item.icon}
              <Box marginLeft={1}>
                <Typography
                  sx={{
                    color: 'black',
                    textAlign: 'start',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                  variant="h5"
                >
                  {item.text}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ textAlign: 'start', color: 'black' }}
                >
                  {item.number}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default AdminInformation;
