export const tokenWrapper = async (api: any) => {
  try {
    const response = await api();
    if (response && response.data !== null) {
      return response;
    } else if (
      response &&
      (response.error === 'Failed to refresh access token.' ||
        response.error === 'Access token or refresh token not found.')
    ) {
      return null;
    }
    return response;
  } catch (error) {
    console.error('Error in tokenWrapper:', error);
    throw error;
  }
};
