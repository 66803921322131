import { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useSnackbar } from '../../SnackbarProvider';
import axios from 'axios';
import Box from '@mui/material/Box';

const useSignUp = () => {
  const [isloading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const animationProps = useSpring({
    opacity: error ? 1 : 0,
    transform: error ? 'scale(1)' : 'scale(0.2)',
  });

  const animationPropsRegistration = useSpring({
    opacity: isRegistered ? 1 : 0,
    transform: isRegistered ? 'scale(1)' : 'scale(0.2)',
  });

  const AnimatedBox = animated(Box);

  useEffect(() => {
    axios
      .get(`https://restcountries.com/v3.1/all?fields=name`)
      .then((res) => setCountries(res.data));
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const url = `${process.env.REACT_APP_API_URL}/register`;
    setLoading(true);

    const body = {
      username: data.get('email'),
      password: data.get('password'),
      first_name: data.get('first_name'),
      last_name: data.get('last_name'),
      confirmPassword: data.get('confirm_password'),
      company_name: data.get('company_name'),
      country: selectedCountry,
    };
    const isEmptyElement = Object.values(body).some((value) => value === '');
    if (isEmptyElement) {
      setError('All the fields are required.');
      setLoading(false);
      return;
    }
    if (body.confirmPassword !== body.password) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }
    axios
      .post(url, body)
      .then((res) => {
        const userData = res.data;

        localStorage.setItem('refresh_token', userData.refresh_token);
        localStorage.setItem('access_token', userData.access_token);
        localStorage.setItem('isLogged', JSON.stringify(true));
        setLoading(false);
        showSnackbar(userData.detail, '#4CAF50');

        setError(null);
        setIsRegistered(true);
      })
      .catch((error) => {
        const { detail } = error.response.data;
        setError(detail);
        setLoading(false);
        showSnackbar(detail, 'tomato');
      });
  };
  return {
    handleSubmit,
    setSelectedCountry,
    countries,
    setError,
    showPassword,
    handleClickShowPassword,
    isRegistered,
    AnimatedBox,
    animationPropsRegistration,
    handleMouseDownPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    error,
    animationProps,
    isloading,
  };
};
export default useSignUp;
