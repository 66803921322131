import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import Authentication from './pages/Authentication';
import { customTheme } from './components/CustomTheme';
import { ThemeProvider } from '@mui/material/styles';
import BrightEarthPage from './pages/BrightEarth';
import UserDashboardPage from './pages/UserDashboard';
import UserManagementPage from './pages/AdminDashboard/UserManagement';
import ProcessingJobsManagementPage from './pages/AdminDashboard/ProcessingJobsManagement';
import TransactionManagementPage from './pages/AdminDashboard/TransactionManagement';
import AdminDashboardPage from './pages/AdminDashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from './components/AuthContext';

import SessionDialog from './components/SessionDialog';

function App() {
  const { isAuthenticated } = useUser();

  return (
    <Router>
      <SessionDialog />
      <ToastContainer />
      <ThemeProvider theme={customTheme}>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" replace={true} />
              ) : (
                <Navigate to="/sign-in" replace={true} />
              )
            }
          />

          <Route path="/sign-in" element={<Authentication />} />
          <Route path="/dashboard" element={<PrivateRoute />}>
            <Route index element={<Dashboard />} />
          </Route>
          <Route path="/user-dashboard" element={<PrivateRoute />}>
            <Route index element={<UserDashboardPage />} />
          </Route>
          <Route path="/admin-dashboard" element={<PrivateRoute />}>
            <Route index element={<AdminDashboardPage />} />
          </Route>

          {/* <Route path="/user-management" element={<PrivateRoute />}>
            <Route index element={<UserManagementPage />} />
          </Route> */}
          <Route path="/order-management" element={<PrivateRoute />}>
            <Route index element={<ProcessingJobsManagementPage />} />
          </Route>
          <Route path="/transaction-management" element={<PrivateRoute />}>
            <Route index element={<TransactionManagementPage />} />
          </Route>
          <Route path="/test" element={<PrivateRoute />}>
            <Route index element={<BrightEarthPage />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
