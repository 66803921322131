const countries = [
  { name: 'Afghanistan', zipCode: 'AF' },
  { name: 'Albania', zipCode: 'AL' },
  { name: 'Algeria', zipCode: 'DZ' },
  { name: 'Andorra', zipCode: 'AD' },
  { name: 'Angola', zipCode: 'AO' },
  { name: 'Antigua and Barbuda', zipCode: 'AG' },
  { name: 'Argentina', zipCode: 'AR' },
  { name: 'Armenia', zipCode: 'AM' },
  { name: 'Australia', zipCode: 'AU' },
  { name: 'Austria', zipCode: 'AT' },
  { name: 'Azerbaijan', zipCode: 'AZ' },
  { name: 'Bahamas', zipCode: 'BS' },
  { name: 'Bahrain', zipCode: 'BH' },
  { name: 'Bangladesh', zipCode: 'BD' },
  { name: 'Barbados', zipCode: 'BB' },
  { name: 'Belarus', zipCode: 'BY' },
  { name: 'Belgium', zipCode: 'BE' },
  { name: 'Belize', zipCode: 'BZ' },
  { name: 'Benin', zipCode: 'BJ' },
  { name: 'Bhutan', zipCode: 'BT' },
  { name: 'Bolivia', zipCode: 'BO' },
  { name: 'Bosnia and Herzegovina', zipCode: 'BA' },
  { name: 'Botswana', zipCode: 'BW' },
  { name: 'Brazil', zipCode: 'BR' },
  { name: 'Brunei', zipCode: 'BN' },
  { name: 'Bulgaria', zipCode: 'BG' },
  { name: 'Burkina Faso', zipCode: 'BF' },
  { name: 'Burundi', zipCode: 'BI' },
  { name: 'Cabo Verde', zipCode: 'CV' },
  { name: 'Cambodia', zipCode: 'KH' },
  { name: 'Cameroon', zipCode: 'CM' },
  { name: 'Canada', zipCode: 'CA' },
  { name: 'Central African Republic', zipCode: 'CF' },
  { name: 'Chad', zipCode: 'TD' },
  { name: 'Chile', zipCode: 'CL' },
  { name: 'China', zipCode: 'CN' },
  { name: 'Colombia', zipCode: 'CO' },
  { name: 'Comoros', zipCode: 'KM' },
  { name: 'Congo (Congo-Brazzaville)', zipCode: 'CG' },
  { name: 'Costa Rica', zipCode: 'CR' },
  { name: 'Croatia', zipCode: 'HR' },
  { name: 'Cuba', zipCode: 'CU' },
  { name: 'Cyprus', zipCode: 'CY' },
  { name: 'Czechia (Czech Republic)', zipCode: 'CZ' },
  { name: 'Denmark', zipCode: 'DK' },
  { name: 'Djibouti', zipCode: 'DJ' },
  { name: 'Dominica', zipCode: 'DM' },
  { name: 'Dominican Republic', zipCode: 'DO' },
  { name: 'Ecuador', zipCode: 'EC' },
  { name: 'Egypt', zipCode: 'EG' },
  { name: 'El Salvador', zipCode: 'SV' },
  { name: 'Equatorial Guinea', zipCode: 'GQ' },
  { name: 'Eritrea', zipCode: 'ER' },
  { name: 'Estonia', zipCode: 'EE' },
  { name: 'Eswatini (fmr. Swaziland)', zipCode: 'SZ' },
  { name: 'Ethiopia', zipCode: 'ET' },
  { name: 'Fiji', zipCode: 'FJ' },
  { name: 'Finland', zipCode: 'FI' },
  { name: 'France', zipCode: 'FR' },
  { name: 'Gabon', zipCode: 'GA' },
  { name: 'Gambia', zipCode: 'GM' },
  { name: 'Georgia', zipCode: 'GE' },
  { name: 'Germany', zipCode: 'DE' },
  { name: 'Ghana', zipCode: 'GH' },
  { name: 'Greece', zipCode: 'GR' },
  { name: 'Grenada', zipCode: 'GD' },
  { name: 'Guatemala', zipCode: 'GT' },
  { name: 'Guinea', zipCode: 'GN' },
  { name: 'Guinea-Bissau', zipCode: 'GW' },
  { name: 'Guyana', zipCode: 'GY' },
  { name: 'Haiti', zipCode: 'HT' },
  { name: 'Honduras', zipCode: 'HN' },
  { name: 'Hungary', zipCode: 'HU' },
  { name: 'Iceland', zipCode: 'IS' },
  { name: 'India', zipCode: 'IN' },
  { name: 'Indonesia', zipCode: 'ID' },
  { name: 'Iran', zipCode: 'IR' },
  { name: 'Iraq', zipCode: 'IQ' },
  { name: 'Ireland', zipCode: 'IE' },
  { name: 'Israel', zipCode: 'IL' },
  { name: 'Italy', zipCode: 'IT' },
  { name: 'Jamaica', zipCode: 'JM' },
  { name: 'Japan', zipCode: 'JP' },
  { name: 'Jordan', zipCode: 'JO' },
  { name: 'Kazakhstan', zipCode: 'KZ' },
  { name: 'Kenya', zipCode: 'KE' },
  { name: 'Kiribati', zipCode: 'KI' },
  { name: 'Kuwait', zipCode: 'KW' },
  { name: 'Kyrgyzstan', zipCode: 'KG' },
  { name: 'Laos', zipCode: 'LA' },
  { name: 'Latvia', zipCode: 'LV' },
  { name: 'Lebanon', zipCode: 'LB' },
  { name: 'Lesotho', zipCode: 'LS' },
  { name: 'Liberia', zipCode: 'LR' },
  { name: 'Libya', zipCode: 'LY' },
  { name: 'Liechtenstein', zipCode: 'LI' },
  { name: 'Lithuania', zipCode: 'LT' },
  { name: 'Luxembourg', zipCode: 'LU' },
  { name: 'Madagascar', zipCode: 'MG' },
  { name: 'Malawi', zipCode: 'MW' },
  { name: 'Malaysia', zipCode: 'MY' },
  { name: 'Maldives', zipCode: 'MV' },
  { name: 'Mali', zipCode: 'ML' },
  { name: 'Malta', zipCode: 'MT' },
  { name: 'Marshall Islands', zipCode: 'MH' },
  { name: 'Mauritania', zipCode: 'MR' },
  { name: 'Mauritius', zipCode: 'MU' },
  { name: 'Mexico', zipCode: 'MX' },
  { name: 'Micronesia', zipCode: 'FM' },
  { name: 'Moldova', zipCode: 'MD' },
  { name: 'Monaco', zipCode: 'MC' },
  { name: 'Mongolia', zipCode: 'MN' },
  { name: 'Montenegro', zipCode: 'ME' },
  { name: 'Morocco', zipCode: 'MA' },
  { name: 'Mozambique', zipCode: 'MZ' },
  { name: 'Myanmar (formerly Burma)', zipCode: 'MM' },
  { name: 'Namibia', zipCode: 'NA' },
  { name: 'Nauru', zipCode: 'NR' },
  { name: 'Nepal', zipCode: 'NP' },
  { name: 'Netherlands', zipCode: 'NL' },
  { name: 'New Zealand', zipCode: 'NZ' },
  { name: 'Nicaragua', zipCode: 'NI' },
  { name: 'Niger', zipCode: 'NE' },
  { name: 'Nigeria', zipCode: 'NG' },
  { name: 'North Korea', zipCode: 'KP' },
  { name: 'North Macedonia', zipCode: 'MK' },
  { name: 'Norway', zipCode: 'NO' },
  { name: 'Oman', zipCode: 'OM' },
  { name: 'Pakistan', zipCode: 'PK' },
  { name: 'Palau', zipCode: 'PW' },
  { name: 'Palestine State', zipCode: 'PS' },
  { name: 'Panama', zipCode: 'PA' },
  { name: 'Papua New Guinea', zipCode: 'PG' },
  { name: 'Paraguay', zipCode: 'PY' },
  { name: 'Peru', zipCode: 'PE' },
  { name: 'Philippines', zipCode: 'PH' },
  { name: 'Poland', zipCode: 'PL' },
  { name: 'Portugal', zipCode: 'PT' },
  { name: 'Qatar', zipCode: 'QA' },
  { name: 'Romania', zipCode: 'RO' },
  { name: 'Russia', zipCode: 'RU' },
  { name: 'Rwanda', zipCode: 'RW' },
  { name: 'Saint Kitts and Nevis', zipCode: 'KN' },
  { name: 'Saint Lucia', zipCode: 'LC' },
  { name: 'Saint Vincent and the Grenadines', zipCode: 'VC' },
  { name: 'Samoa', zipCode: 'WS' },
  { name: 'San Marino', zipCode: 'SM' },
  { name: 'Sao Tome and Principe', zipCode: 'ST' },
  { name: 'Saudi Arabia', zipCode: 'SA' },
  { name: 'Senegal', zipCode: 'SN' },
  { name: 'Serbia', zipCode: 'RS' },
  { name: 'Seychelles', zipCode: 'SC' },
  { name: 'Sierra Leone', zipCode: 'SL' },
  { name: 'Singapore', zipCode: 'SG' },
  { name: 'Slovakia', zipCode: 'SK' },
  { name: 'Slovenia', zipCode: 'SI' },
  { name: 'Solomon Islands', zipCode: 'SB' },
  { name: 'Somalia', zipCode: 'SO' },
  { name: 'South Africa', zipCode: 'ZA' },
  { name: 'South Korea', zipCode: 'KR' },
  { name: 'South Sudan', zipCode: 'SS' },
  { name: 'Spain', zipCode: 'ES' },
  { name: 'Sri Lanka', zipCode: 'LK' },
  { name: 'Sudan', zipCode: 'SD' },
  { name: 'Suriname', zipCode: 'SR' },
  { name: 'Sweden', zipCode: 'SE' },
  { name: 'Switzerland', zipCode: 'CH' },
  { name: 'Syria', zipCode: 'SY' },
  { name: 'Taiwan', zipCode: 'TW' },
  { name: 'Tajikistan', zipCode: 'TJ' },
  { name: 'Tanzania', zipCode: 'TZ' },
  { name: 'Thailand', zipCode: 'TH' },
  { name: 'Timor-Leste', zipCode: 'TL' },
  { name: 'Togo', zipCode: 'TG' },
  { name: 'Tonga', zipCode: 'TO' },
  { name: 'Trinidad and Tobago', zipCode: 'TT' },
  { name: 'Tunisia', zipCode: 'TN' },
  { name: 'Turkey', zipCode: 'TR' },
  { name: 'Turkmenistan', zipCode: 'TM' },
  { name: 'Tuvalu', zipCode: 'TV' },
  { name: 'Uganda', zipCode: 'UG' },
  { name: 'Ukraine', zipCode: 'UA' },
  { name: 'United Arab Emirates', zipCode: 'AE' },
  { name: 'United Kingdom', zipCode: 'GB' },
  { name: 'United States of America', zipCode: 'US' },
  { name: 'Uruguay', zipCode: 'UY' },
  { name: 'Uzbekistan', zipCode: 'UZ' },
  { name: 'Vanuatu', zipCode: 'VU' },
  { name: 'Vatican City', zipCode: 'VA' },
  { name: 'Venezuela', zipCode: 'VE' },
  { name: 'Vietnam', zipCode: 'VN' },
  { name: 'Yemen', zipCode: 'YE' },
  { name: 'Zambia', zipCode: 'ZM' },
  { name: 'Zimbabwe', zipCode: 'ZW' },
];

export default countries;
