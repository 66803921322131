import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import { useDialog } from '../apiCallWithToken/dialogContext';
import { tokenWrapper } from '../apiCallWithToken/tokenWrapper';
import { getUser } from '../../api/usersApi';
import { useAdminInfos } from '../Dashboard/AdminContext';
import { useUser } from '../AuthContext';

function SessionDialog() {
  const { setUser } = useUser();
  const { setAdminInfos } = useAdminInfos();
  const { showDialog, setShowDialog } = useDialog();
  const location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      const response = await tokenWrapper(() =>
        getUser(setUser, setAdminInfos),
      );
      if (response === null && location.pathname !== '/sign-in') {
        setShowDialog(true);
      }
    };
    fetchData();
  }, []);

  const handleCloseModal = () => {
    setShowDialog(false);
    window.location.href = '/sign-in';
  };

  if (showDialog && location.pathname !== '/sign-in') {
    return (
      <Dialog maxWidth="xl" open={true}>
        <Grid
          direction="column"
          container
          p={5}
          spacing={3}
          alignItems={'center'}
          maxWidth={'30rem'}
        >
          <Typography
            sx={{
              textAlign: 'center',
            }}
          >
            Your session has expired. Please sign in again to continue.
          </Typography>

          <Grid item>
            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button
                variant="contained"
                sx={{ background: '#206294' }}
                onClick={handleCloseModal}
              >
                ok
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
  return null;
}

export default SessionDialog;
