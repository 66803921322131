import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import MenuNotifications from '../MenuNotifications';
import MenuUser from '../MenuUser';
// Define your custom styles for the Menu
const CustomMenu = styled(Menu)({
  // backgroundColor: '#f0f0f0', // Customize the background color
  color: 'black', // Customize the text color
  borderRadius: '4px', // Customize the border radius
  '& .MuiMenuItem-root': {
    fontSize: '16px', // Customize the font size for menu items
  },
  '& .MuiMenuItem-root:hover': {
    backgroundColor: '#e0e0e0', // Customize the background color on hover
  },
});

interface BasicMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  component: any;
}

export default function MenuList({
  anchorEl,
  open,
  handleClose,
  component,
}: BasicMenuProps) {
  const width = 300;
  const height = 300;

  return (
    <CustomMenu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      {component === 'user' ? (
        <MenuUser height={height} width={width} />
      ) : (
        <MenuNotifications height={height} width={width} />
      )}
    </CustomMenu>
  );
}
