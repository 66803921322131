import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableBody,
  Box,
  Paper,
  CircularProgress,
} from '@mui/material';

import useOrders from './useOrders';
import Row from '../../UserDashBoard/ProcessingLog/Row';
import LinearProgress from '@mui/material/LinearProgress';

const ProcessingJobsTable = () => {
  const {
    columns,
    isLoading,
    orderArray,
    tableContainerRef,
    tableBodyRef,
    lastEvaluatedKey,
    handleDeleteOrder,
  } = useOrders();

  return isLoading ? (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  ) : (
    <Paper
      elevation={3}
      sx={{ width: '100%', overflowX: 'auto', height: '100%' }}
    >
      <Box
        ref={tableContainerRef}
        style={{
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Table style={{ maxHeight: '100%' }}>
          <TableHead
            style={{
              top: 0,
              zIndex: 1,
              backgroundColor: 'white',
              height: '100%',
            }}
          >
            <TableRow>
              <TableCell />

              {columns.map((column) => (
                <TableCell key={column.id}>
                  <Typography
                    fontSize={14}
                    sx={{ color: '#223354', cursor: 'pointer' }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody ref={tableBodyRef}>
            {orderArray?.length > 0 ? (
              orderArray?.map((e: any, id: any) => (
                <Row
                  row={e}
                  id={id}
                  page={'admin'}
                  key={id}
                  handleDeleteOrder={handleDeleteOrder}
                />
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={16}
                  sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}
                >
                  <Box sx={{ m: 1 }}>No data available in table</Box>
                </TableCell>
              </TableRow>
            )}
            {lastEvaluatedKey !== null && (
              <TableRow>
                <TableCell
                  colSpan={16}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress sx={{ height: 10, m: 1 }} size={20} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export default ProcessingJobsTable;
