import { apiCallWithToken } from '../components/apiCallWithToken';

export const getAllOrders = async (queryParams: string) => {
  try {
    const response = await apiCallWithToken(`/admin/orders${queryParams}`);

    return response;
  } catch (error) {
    console.log('Error fetching orders:', error);
    return [];
  }
};
export const getUserOrders = async (queryParams?: string) => {
  try {
    const response = await apiCallWithToken(`/users/me/orders${queryParams}`);

    return response;
  } catch (error) {
    console.log('Error fetching orders:', error);
    return [];
  }
};

export const getRunningOrders = async () => {
  try {
    const response = await apiCallWithToken(`/users/me/orders/running`);
    return response;
  } catch (error) {
    console.log('Error fetching orders:', error);
    return [];
  }
};
export const deleteOrder = async (
  orderId: string | undefined,
  userId: string | undefined,
) => {
  try {
    const response = await apiCallWithToken(
      `/users/${userId}/orders/${orderId}`,
      'DELETE',
    );
    return response;
  } catch (error: any) {
    console.log('Error:', error);
    return {};
  }
};
