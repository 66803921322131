import { apiCallWithToken } from '../apiCallWithToken';
import XMLParser from 'react-xml-parser';
import * as Cesium from 'cesium';

export async function getDetails(userId: string, id: string) {
  const details = await apiCallWithToken(
    `/users/${userId}/orders/${id}/details`,
  );
  return details;
}

export async function getLayers(userId: string, id: string) {
  const layers = await apiCallWithToken(
    `/users/${userId}/orders/${id}/ogc/layers`,
  );
  return layers;
}

export async function getRectangle(userId: string, orderId: string) {
  const response = await apiCallWithToken(
    `/users/${userId}/orders/${orderId}/ogc/?request=GetCapabilities&SERVICE=WMS`,
  );
  const xml = new XMLParser().parseFromString(response.body);
  const layers = xml.getElementsByTagName('Layer');
  const layerData = [];

  for (const layer of layers) {
    const name = layer.getElementsByTagName('Name')[0].value;
    const latLonBoundingBox =
      layer.getElementsByTagName('LatLonBoundingBox')[0];
    const minx = parseFloat(latLonBoundingBox.attributes.minx);
    const miny = parseFloat(latLonBoundingBox.attributes.miny);
    const maxx = parseFloat(latLonBoundingBox.attributes.maxx);
    const maxy = parseFloat(latLonBoundingBox.attributes.maxy);

    layerData.push({
      layerName: name,
      minx,
      miny,
      maxx,
      maxy,
    });
  }
  const result = layerData.reduce(
    (acc, current) => {
      // Find the minimum minx and miny
      acc.minx = Math.min(acc.minx, current.minx);
      acc.miny = Math.min(acc.miny, current.miny);
      // Find the maximum maxx and maxy
      acc.maxx = Math.max(acc.maxx, current.maxx);
      acc.maxy = Math.max(acc.maxy, current.maxy);
      return acc;
    },
    {
      minx: Infinity,
      miny: Infinity,
      maxx: -Infinity,
      maxy: -Infinity,
    },
  );
  const rec = Cesium.Rectangle.fromDegrees(
    result.minx,
    result.miny,
    result.maxx,
    result.maxy,
  );
  return rec;
}

interface IItem {
  rectangle: any;
  userId: string;
  orderId: string;
  state: string;
  params: any;
  layers: any;
}

export async function createItemToDisplay(userId: string, orderId: string) {
  const infos = await getDetails(userId, orderId);
  const layers = await getLayers(userId, orderId);
  const rectangle = await getRectangle(userId, orderId);
  let item: IItem = {
    rectangle: rectangle,
    params: infos.detail.params,
    userId: userId,
    orderId: orderId,
    state: infos.detail.state.split('#')[1],
    layers: layers.layers,
  };
  return item;
}
