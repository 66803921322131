import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import { useDashboardContext } from '../DashboardContext';
import Divider from '@mui/material/Divider/Divider';
import Alert from '@mui/material/Alert';
import { useUser } from '../../AuthContext';
import Billing from './billing';
import { apiCallWithToken } from '../../apiCallWithToken';
import Slide, { SlideProps } from '@mui/material/Slide';
import { useSnackbar } from '../../SnackbarProvider';
import { useDialog } from '../../apiCallWithToken/dialogContext';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, TextField, IconButton, InputAdornment } from '@mui/material';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionRight(props: TransitionProps) {
  return <Slide {...props} direction="right" />;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref,
) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: 2,
  p: 4,
};

export default function RecapModal() {
  const {
    results,
    setResults,
    isRecapModalOpen,
    setRecapModalOpen,
    formDataValues,
    cost,
    changeProjectName,
  } = useDashboardContext();
  const { showSnackbar } = useSnackbar();
  const { user } = useUser();
  const [, setOpen] = React.useState(false);
  const { setShowDialog } = useDialog();
  const [, setTransition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
  >(undefined);

  const handleClick =
    (Transition: React.ComponentType<TransitionProps>) => () => {
      setTransition(() => Transition);
      setOpen(true);
    };
  const [visibility, setVisibility] = React.useState<any>({});
  const toggleVisibility = (key: string) => {
    setVisibility((prevState: any) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const rename = (name: string) => {
    const dataToRename = [
      // { before: 'resolution', after: 'Resolution' },
      { before: 'extraction_type', after: 'Extraction type' },
      // { before: 'output_srs', after: 'Output srs' },
      { before: 'project_name', after: 'Project name' },
      { before: 'layers_2D', after: 'Layers 2D' },
      { before: 'layers_3D', after: 'Layers 3D' },
      { before: 'output_raster_formats', after: 'Output raster formats' },
      { before: 'output_vector_formats', after: 'Output vector formats' },
      { before: 'file', after: 'File' },
      { before: 'imagery_source', after: 'Imagery Source' },
      { before: 'wkt_aoi', after: 'AOI' },
      { before: 'input_cog_uri', after: 'S3 Bucket' },
      { before: 'aws_secret_access_key', after: 'AWS Secret Access Key' },
      { before: 'aws_access_key_id', after: 'AWS Access Key ID' },
    ];
    const exists = dataToRename.some((obj) => obj.before === name);

    if (exists) {
      const renamedObject = dataToRename.find((obj) => obj.before === name);
      if (renamedObject) {
        return renamedObject.after;
      }
    }
    return name;
  };

  const handleWarnings = () => {
    let errors = [];
    if (formDataValues['file'] === '' && formDataValues['wkt_aoi'] === '') {
      errors.push(
        <Alert key="error1" sx={{ mb: 1 }} severity="error">
          You must draw a polygon or import your file.
        </Alert>,
      );
    }
    if (user?.credits < cost) {
      errors.push(
        <Alert key="error2" sx={{ mb: 1 }} severity="error">
          The cost exceeds your available credits.
        </Alert>,
      );
    }
    if (formDataValues['project_name'] === '') {
      errors.push(
        <Alert key="error2" sx={{ mb: 1 }} severity="error">
          You must specify a project name.
        </Alert>,
      );
    }
    return errors.map((x, i) => x);
  };

  const handleBtnState = () => {
    if (formDataValues['project_name'] === '') {
      return true;
    }
    if (formDataValues['file'] === '' && formDataValues['wkt_aoi'] === '') {
      return true;
    }
    if (formDataValues['file'] === '' && formDataValues['wkt_aoi'] === '') {
      return true;
    }
    if (user?.credits < cost) {
      return true;
    }
    return false;
  };

  const showInfos = () => {
    const infos = formDataValues;
    // if (imagery_source !== 'UserImage') {
    //   infos.delete('input_cog_uri');
    // }
    // infos.delete('resolution');
    // infos.delete('output_srs');
    const keyValueArray = Object.entries(infos);

    return keyValueArray.map((x, i) => {
      const key = x[0];
      const value = x[1];
      switch (key) {
        case 'layers_2D':
          if (formDataValues['extraction_type'] === '3D') {
            return null;
          }
          break;
        case 'file':
          if (formDataValues['file'] === '') {
            return null;
          }
          break;
        case 'wkt_aoi':
          if (formDataValues['wkt_aoi'] === '') {
            return null;
          }
          break;
        case 'layers_3D':
          if (formDataValues['extraction_type'] === '2D') {
            return null;
          }
          break;

        case 'input_cog_uri':
        case 'aws_secret_access_key':
        case 'aws_access_key_id':
          if (formDataValues['imagery_source'] !== 'UserImage') {
            return null;
          }
          break;

        default:
          break;
      }

      if (key !== 'resolution' && key !== 'output_srs') {
        // Check if the key is 'aws_secret_access_key' or 'aws_access_key_id'
        const fieldType =
          key === 'aws_secret_access_key' || key === 'aws_access_key_id'
            ? 'password'
            : 'text';
        return (
          <Box m={3} key={key}>
            <TextField
              fullWidth
              label={rename(key)}
              id="outlined-basic"
              variant="outlined"
              size="small"
              type={visibility[key] ? 'text' : fieldType}
              value={value}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {fieldType === 'password' && (
                      <IconButton
                        onClick={() => toggleVisibility(key)}
                        edge="end"
                      >
                        {visibility[key] ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        );
      } else {
        return null;
      }
    });
  };

  const addLayerToRightWindow = async (orderId: string) => {
    const userId = user?.sub;
    const response = await tokenWrapper(() =>
      apiCallWithToken(`/users/${userId}/orders/${orderId}/details`),
    );
    if (response === null) {
      setShowDialog(true);
    }
    let res = [...results];
    res.unshift({
      rectangle: null,
      layers: null,
      params: { name: formDataValues['project_name'] },
      orderId: orderId,
      userId: userId,
      state: 'CREATED',
    });
    setResults(res);
    showSnackbar('Your order is created', '#4CAF50', 'white');
  };

  const startProcessing = async () => {
    setRecapModalOpen(false);
    handleClick(TransitionRight);
    console.log(formDataValues)
    const formData = new FormData();
    for (const key in formDataValues) {
      formData.append(key, formDataValues[key]);
    }
    const res = await apiCallWithToken(
      '/orders',
      'POST',
      formData,
      'multipart/form-data',
    );
    if (res.order_id === null || res.order_id === undefined || !res.order_id) {
      showSnackbar(
        'An error occured with your order, please try again.',
        'tomato',
        'white',
      );
      return;
    }
    addLayerToRightWindow(res.order_id);
    changeProjectName('');
    return;
  };

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={isRecapModalOpen}
        onClose={() => setRecapModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={isRecapModalOpen}>
          <Box sx={style}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Checkout
            </Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box display="flex" flexDirection="row">
              <Box sx={{ flex: 1, overflow: 'auto' }}>
                {handleWarnings()}
                {showInfos()}
              </Box>
              <Billing user={user} cost={cost}></Billing>
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                // bgcolor: 'red',
              }}
            >
              <Button
                variant="outlined"
                sx={{ m: 1 }}
                onClick={() => setRecapModalOpen(false)}
              >
                Cancel Order
              </Button>
              <Button
                variant="contained"
                disabled={handleBtnState()}
                onClick={() => startProcessing()}
              >
                Submit Order
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
