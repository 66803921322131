import {
  Box,
  Button,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import countryList from 'react-select-country-list';
import { useUser } from '../../AuthContext';
import { changeAttributes } from '../../../api/usersApi';
import { useSnackbar } from '../../SnackbarProvider';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { useDialog } from '../../apiCallWithToken/dialogContext';
import { apiCallWithToken } from '../../apiCallWithToken';

const PersonalInformation = () => {
  const [value, setValue] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const { setShowDialog } = useDialog();

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const options = useMemo(() => countryList().getData(), []);
  const { user, setUser } = useUser();
  const [firstName, setFirstName] = useState(user?.first_name || '');
  const [lastName, setLastName] = useState(user?.last_name || '');
  const { showSnackbar } = useSnackbar();

  const [selectedCountry, setSelectedCountry] = useState(
    options.findIndex((option) => option.label === user?.country) !== -1
      ? options[options.findIndex((option) => option.label === user?.country)]
          .label
      : value,
  );
  const changeHandler = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value as string);
    setSelectedCountry(event.target.value);
  };

  const handleSaveChanges = async () => {
    try {
      const response = await tokenWrapper(() =>
        changeAttributes(firstName, lastName, selectedCountry),
      );
      if (response === null && window.location.pathname !== '/sign-in') {
        setShowDialog(true);
      } else if (response && response.detail) {
        showSnackbar(response.detail, '#4CAF50');
      } else {
        showSnackbar('Error updating informations', 'tomato');
      }

      setModalOpen(false);
    } catch (error) {
      console.error('Error updating attributes:', error);
    }
  };
  const fetchme = async () => {
    const res = await tokenWrapper(() => apiCallWithToken('/users/me'));
    setUser(res);
  };
  useEffect(() => {
    fetchme();
  }, [modalOpen, setModalOpen]);

  return (
    <Box>
      <CardHeader
        title={
          <Typography variant="h5" sx={{ textAlign: 'start', color: 'black' }}>
            Update Personal Information{' '}
          </Typography>
        }
        action={
          <Button variant="contained" onClick={handleModalOpen}>
            Save Changes
          </Button>
        }
      />
      <Divider />

      <Grid m={2}>
        <Grid container spacing={2} columns={16} mt={1}>
          <Grid item xs={4} display="flex" alignItems="center">
            <Typography variant="body1">First Name:</Typography>
          </Grid>
          <Grid item xs={8} display="flex" alignItems="center">
            <TextField
              defaultValue={user?.first_name}
              fullWidth
              variant="outlined"
              placeholder="Enter first name"
              size="small"
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={16} mt={1}>
          <Grid item xs={4} display="flex" alignItems="center">
            <Typography variant="body1">Last Name:</Typography>
          </Grid>
          <Grid item xs={8} display="flex" alignItems="center">
            <TextField
              defaultValue={user?.last_name}
              fullWidth
              variant="outlined"
              placeholder="Enter Last Name"
              size="small"
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={16} mt={1}>
          <Grid item xs={4} display="flex" alignItems="center">
            <Typography variant="body1">Country:</Typography>
          </Grid>
          <Grid item xs={8} display="flex" alignItems="center">
            <Select
              defaultValue={
                options.findIndex(
                  (option) => option.label === user?.country,
                ) !== -1
                  ? options[
                      options.findIndex(
                        (option) => option.label === user?.country,
                      )
                    ].label
                  : value
              }
              onChange={changeHandler}
              size="small"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
              sx={{
                minWidth: '100%',
                textAlign: 'left',
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option.label}
                  value={option.label}
                  selected={
                    index ===
                    options.findIndex((opt) => opt.label === user?.country)
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
      {modalOpen && (
        <Dialog
          maxWidth="xl"
          open={!!modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <Grid direction="column" container p={3} spacing={3}>
            <Grid item>
              <Typography variant="h6">Confirmation</Typography>
              <Divider />
            </Grid>

            <Grid item>
              <Typography>
                {'Are you sure you want to update your personal information ?'}
              </Typography>
            </Grid>

            <Grid item>
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button variant="outlined" onClick={handleModalClose}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSaveChanges}>
                  Confirm
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Box>
  );
};
export default PersonalInformation;
