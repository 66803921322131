import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

interface CheckboxItem {
  value: string;
  label: string;
}

interface TwoDimensionSelectProps {
  checkboxItems: CheckboxItem[];
  array: any;
  disabled: boolean;
  onChange: any;
}

const LayersSelect: React.FC<TwoDimensionSelectProps> = ({
  checkboxItems,
  array,
  disabled,
  onChange,
}: any) => {
  return (
    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
      {checkboxItems.map((checkboxItem: CheckboxItem) => (
        <FormControlLabel
          key={checkboxItem.value}
          control={
            <Checkbox
              checked={array.includes(checkboxItem.value)}
              onChange={() => onChange(checkboxItem.value)}
              disabled={disabled}
              style={{ padding: 5 }}
            />
          }
          label={checkboxItem.label}
        />
      ))}
    </FormGroup>
  );
};
export default LayersSelect;
