import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { User } from '../../AuthContext';
type BillingType = {
  user: User | null;
  cost: number;
};
const Billing = (props: BillingType) => {
  return (
    <>
      <Box sx={{ flex: 1, m: 1, p: 1, pl: 2 }}>
        <Card sx={{}}>
          <CardHeader
            title={
              <Typography variant="body1" fontWeight="bold">
                Personal information
              </Typography>
            }
            style={{ width: 'max-content' }}
          />
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Your credits
                  </TableCell>
                  <TableCell>{props.user?.credits}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Order cost
                  </TableCell>
                  <TableCell>{props.cost}</TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: '#EBEDF3' }}>
                  <TableCell component="th" scope="row">
                    Total
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        props?.user?.credits < props?.cost
                          ? 'tomato'
                          : 'inherit',
                    }}
                  >
                    {((props.user?.credits ?? 0) - props.cost).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </>
  );
};
export default Billing;
