import { Box } from '@mui/material';
import ProcessingJobsTable from './processingJobsTable';
import LinearProgress from '@mui/material/LinearProgress';
import useOrders from './useOrders';

const ProcessingJobsCard = () => {
  const { isLoading } = useOrders();

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <ProcessingJobsTable />{' '}
    </Box>
  );
};
export default ProcessingJobsCard;
