import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import UsersCard from './UsersCard';
import ProcessingJobsCard from './ProcessingJobsCard';
import TransactionCard from './Transactions';
import { useState } from 'react';
import UserInformation from '../UserDashBoard/UserInformation';
import IconMenu from '../UserDashBoard/IconMenu';
import InformationItem from '../UserDashBoard/InformationItem';
import UserManagementCard from './UserManagement/userManagementCard';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,

  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export default function AdminDashBoard() {
  const [selectedMenu, setSelectedMenu] = useState(1);

  return (
    <Grid container sx={{ height: '100%', padding: 2 }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={2.5} sx={{ height: '100%', overflowX: 'auto' }}>
          <Item sx={{ padding: 1, marginRight: '10px' }}>
            <UserInformation />
            <IconMenu
              page={'admin'}
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
            />
          </Item>
          <Item sx={{ padding: 1, marginRight: '10px', marginTop: '10px' }}>
            <InformationItem />
          </Item>
        </Grid>

        <Grid
          container
          xs={9.5}
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <Item sx={{ width: '100%', height: '100%' }}>
            {selectedMenu === 1 && <ProcessingJobsCard />}

            {selectedMenu === 2 && <UsersCard />}
            {selectedMenu === 3 && <UserManagementCard />}

            {selectedMenu === 4 && <TransactionCard />}
          </Item>
        </Grid>
      </Grid>
    </Grid>
  );
}
