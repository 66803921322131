import { useState } from 'react';
import Footer from '../../components/Footer';
import AppBar from '../../components/AppBar';
import DashBoardModal from '../../components/DashBoardModal';
import UserDashboard from '../../components/UserDashBoard';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function UserDashboardPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalIcon, setModalIcon] = useState(null);

  const setContent = (value: any) => {
    setModalContent(value);
  };

  const setTitle = (value: string) => {
    setModalTitle(value);
  };

  const setIcon = (value: any) => {
    setModalIcon(value);
  };

  return (
    <Box sx={{ flexGrow: 1, height: '100vh', width: '100%' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} sx={{ height: '7%' }}>
          <AppBar
            onOpenModal={() => setIsModalOpen(true)}
            setModalContent={setContent}
            setModalTitle={setTitle}
            setModalIcon={setIcon}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ height: '88%', bgcolor: '#eef0f8', overflow: 'auto' }}
        >
          <UserDashboard />
        </Grid>
        <Grid item xs={12} sx={{ height: '5%' }}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
}
