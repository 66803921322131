import { Box, Snackbar } from '@mui/material';
import React, { createContext, useState, useContext, ReactNode } from 'react';

// const { showSnackbarError } = useSnackbarError(); use the useSnackbarError hook

//  showSnackbarError('Message de notification', 'red', <MenuIcon />)

interface SnackbarErrorContextType {
  showSnackbarError: (
    text: string,
    bgColor?: string,
    textColor?: string,

    icon?: ReactNode,
  ) => void;
  hideSnackbarError: () => void;
}

const SnackbarErrorContext = createContext<
  SnackbarErrorContextType | undefined
>(undefined);

const useSnackbarError = () => {
  const context = useContext(SnackbarErrorContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

const SnackbarErrorProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [bgColor, setBgColor] = useState<string | undefined>(undefined);
  const [icon, setIcon] = useState<ReactNode | undefined>(undefined);
  const [text, setText] = useState<string | undefined>(undefined);
  const [textColor, setTextColor] = useState<string>('black');

  const showSnackbarError = (
    text: string,
    bgColor: string | undefined = 'white',
    textColor: string | undefined = 'black',

    icon: ReactNode | undefined = '',
  ) => {
    setBgColor(bgColor);
    setIcon(icon);
    setText(text);
    setTextColor(textColor);
    setIsOpen(true);
  };
  const hideSnackbarError = () => {
    setIsOpen(false);
  };

  const contextValues: SnackbarErrorContextType = {
    showSnackbarError,
    hideSnackbarError,
  };

  return (
    <SnackbarErrorContext.Provider value={contextValues}>
      {children}
      {isOpen && (
        <Snackbar
          open={isOpen}
          onClose={hideSnackbarError}
          message={text}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          ContentProps={{
            sx: { backgroundColor: bgColor || 'white', color: textColor },
          }}
          action={icon && <Box>{icon}</Box>}
        />
      )}
    </SnackbarErrorContext.Provider>
  );
};

export { SnackbarErrorProvider, useSnackbarError };
