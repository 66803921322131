import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Divider from '@mui/material/Divider';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import { useUser } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import MenuDemo from './MenuDemo';
import bg from '../../../../assets/auth-bg-1.png';

interface BasicMenuProps {
  height: number;
  width: number;
}
//test
const CustomBox = styled(Box)(({ theme }) => ({
  color: 'black',
  borderRadius: 0,
  display: 'flex',
  fontSize: 12,
  alignItems: 'center',
  cursor: 'pointer',
  gap: '8px',
  '&:hover': {
    color: theme.palette.primary.main,
    // color: 'white',
  },
}));

const Item = (
  title: string,
  subTitle: string,
  height: number,
  icon: any,
  to: string,
) => {
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <CustomBox
        sx={{ height: height, display: 'flex', justifyContent: 'center' }}
      >
        <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid
            item
            xs={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {icon}
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Box style={{ fontSize: 12 }}>{title}</Box>
            <Box style={{ fontSize: 12 }}>{subTitle}</Box>
          </Grid>
        </Grid>
      </CustomBox>
    </Link>
  );
};

export default function MenuUser({ height, width }: BasicMenuProps) {
  const { user } = useUser();
  let itemHeight = height / 3;
  if (user?.groups?.includes('Demo')) {
    itemHeight = height / 4;
  }
  if (user?.groups?.includes('Admin')) {
    itemHeight = height / 5;
  }
  const navigate = useNavigate();

  const signOut = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('isLogged');
    localStorage.removeItem('refresh_token');
    window.location.reload();
    navigate('/');
  };

  return (
    <Box sx={{ height: height, width: width }}>
      <Box
        sx={{
          height: itemHeight,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          color: 'white',
          background: `url(${bg})`, // Use the imported bg variable
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Avatar
            sx={{ bgcolor: '#ebedf3', borderRadius: 1, color: '#9ccf84' }}
            variant="square"
          >
            {user?.last_name[0]}
          </Avatar>
          <Box>
            {user?.first_name} {user?.last_name}
          </Box>
        </Box>
      </Box>

      {Item(
        'My Dashboard',
        'Orders & account settings',
        itemHeight,
        <DashboardIcon style={{ color: '#9ccf84' }} />,
        '/user-dashboard',
      )}

      {user?.groups.includes('Admin') &&
        Item(
          'Admin Dashboard',
          'Orders & user management',
          itemHeight,
          <AdminPanelSettingsIcon style={{ color: '#9ccf84' }} />,
          '/admin-dashboard',
        )}
      {(user?.groups.includes('Admin') || user?.groups.includes('Demo')) && (
        <MenuDemo />
      )}

      {/* {user?.groups.includes('Demo') && <MenuDemo />} */}

      <Divider />
      <Box
        sx={{
          height: itemHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box>
          <Button variant="contained" onClick={signOut}>
            Sign out
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
