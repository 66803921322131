import React from 'react';
import BasicSelect from '../../Process/BasicSelect';
import { useDashboardContext } from '../../DashboardContext';
const OutputSelect: React.FC = () => {
  const { setOutputSrs } = useDashboardContext();
  const options = [
    { value: '3857', label: 'Pseudo-Mercator (epsg:3857)' },
    { value: '4326', label: 'Geographic (epsg:4326)' },
  ];
  const handleChange = (event: any) => {
    setOutputSrs(parseInt(event));
  };

  return (
    <BasicSelect options={options} onChange={(e: any) => handleChange(e)} />
  );
};

export default OutputSelect;
