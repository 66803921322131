/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { AllProcessingJobs } from '../../../contexts/types/processingJobType';
import { deleteOrder, getAllOrders } from '../../../api/ordersApi';
import { useDialog } from '../../apiCallWithToken/dialogContext';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { useSnackbar } from '../../SnackbarProvider';

const useOrders = () => {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null>(null);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('id');
  const fetchInProgressRef = useRef(false);
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [allOrders, setAllOrders] = useState<AllProcessingJobs[]>([]);
  let orderArray: AllProcessingJobs[] = allOrders;
  const { setShowDialog } = useDialog();
  const isLoading = allOrders.length === 0;
  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { showSnackbar } = useSnackbar();

  const columns = [
    { id: 'processId', label: 'Order ID' },
    { id: 'userFirstName', label: 'User' },
    { id: 'state', label: 'State' },

    { id: 'progress', label: 'Progress' },
    { id: 'name', label: 'Name' },

    { id: 'output', label: 'Output' },
    { id: 'download', label: 'Download' },
    { id: 'delete', label: 'Action' },
  ];
  const type = [
    { id: 1, text: 'Select' },
    { id: 2, text: '2DLayers' },
    { id: 3, text: '2dLayers' },
    { id: 4, text: 'AllLayers' },
  ];
  const state = [
    { id: 1, text: 'Select' },
    { id: 2, text: 'Cancelled/Deleted' },
    { id: 3, text: 'Failed' },
    { id: 4, text: 'Progress' },
    { id: 5, text: 'Success' },
  ];
  const fetchMoreJobs = useCallback(async () => {
    if (lastEvaluatedKey !== null) {
      if (fetchInProgressRef.current) {
        return;
      }
      fetchInProgressRef.current = true;
      try {
        const queryParams = lastEvaluatedKey
          ? `?last_evaluated_key=${lastEvaluatedKey}&limit=10&attributes=SK,GSI1-SK,progress,name,type,resolution,srs,formats,GSI2-PK,cost,area`
          : '?limit=10';

        const response = await tokenWrapper(() => getAllOrders(queryParams));
        if (response === null) {
          setShowDialog(true);
        }
        setAllOrders((prevOrders) => {
          return prevOrders
            ? [...prevOrders, ...response?.Items]
            : response?.Items;
        });
        setLastEvaluatedKey(response?.LastEvaluatedKey);
      } catch (error) {
        console.log('Error fetching more orders:', error);
      } finally {
        fetchInProgressRef.current = false;
      }
    }
  }, [lastEvaluatedKey, setAllOrders, setLastEvaluatedKey]);

  const handleRowClick = (rowUser: any) => {
    if (selectedRow === rowUser) {
      setSelectedRow(null);
    } else {
      setSelectedRow(rowUser);
    }
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleSortClick = (columnId: string) => {
    const isAscending = orderBy === columnId && order === 'asc';
    const newOrder = isAscending ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(columnId);
  };

  const descendingComparator = (
    a: AllProcessingJobs,
    b: AllProcessingJobs,
    orderBy: string,
  ) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
    return order === 'desc'
      ? (a: AllProcessingJobs, b: AllProcessingJobs) =>
          descendingComparator(a, b, orderBy)
      : (a: AllProcessingJobs, b: AllProcessingJobs) =>
          -descendingComparator(a, b, orderBy);
  };

  const stableSort = (
    array: AllProcessingJobs[],
    comparator: (a: AllProcessingJobs, b: AllProcessingJobs) => number,
  ) => {
    const stabilizedThis = array.map(
      (el, index) => [el, index] as [AllProcessingJobs, number],
    );
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  const handleCopyClick = (event: any, id: any) => {
    event.stopPropagation();

    navigator.clipboard
      .writeText(id)
      .then(() => {
        showSnackbar('Order Id copied', '#4CAF50', 'white');
      })
      .catch((err) => {
        showSnackbar('Failed to copy', 'tomato', 'white');

        console.error('Failed to copy: ', err);
      });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        lastEvaluatedKey &&
        tableBodyRef.current &&
        tableContainerRef.current &&
        tableBodyRef.current.scrollHeight -
          tableContainerRef.current.clientHeight <=
          tableContainerRef.current.scrollTop &&
        !fetchInProgressRef.current
      ) {
        fetchMoreJobs();
      }
    };

    const handleResize = () => {
      const container = tableContainerRef.current;
      const body = tableBodyRef.current;

      if (
        lastEvaluatedKey &&
        container &&
        body &&
        container.scrollHeight - container.clientHeight <=
          container.scrollTop &&
        !fetchInProgressRef.current
      ) {
        fetchMoreJobs();
      }
    };
    const containerRef = tableContainerRef.current;
    if (containerRef) {
      containerRef.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (containerRef) {
        containerRef.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [lastEvaluatedKey, fetchMoreJobs, tableBodyRef, tableContainerRef]);
  const queryParams =
    '?limit=30&attributes=SK,GSI1-SK,progress,name,type,resolution,srs,formats,GSI2-PK,cost,area';

  const fetchAllOrders = async () => {
    try {
      const data = await tokenWrapper(() => getAllOrders(queryParams));
      if (data === null) {
        setShowDialog(true);
      }
      setAllOrders(data.Items);
      setLastEvaluatedKey(data.LastEvaluatedKey);
    } catch (error) {
      console.error('Error fetching order data:', error);
    }
  };
  let deletedOrdersList: any = [];

  const handleDeleteOrder = useCallback(
    async (id?: string | undefined, userId?: string | undefined) => {
      try {
        await deleteOrder(id, userId);
        deletedOrdersList.push(id);
        const filteredList = allOrders?.filter(
          (e: any) => !deletedOrdersList.includes(e['SK'].split('#')[1]),
        );
        setAllOrders(filteredList);
      } catch (error) {
        console.error('Error: ', error);
      }
    },

    [allOrders],
  );
  useEffect(() => {
    fetchAllOrders();
    const intervalId = setInterval(() => {
      fetchAllOrders();
      const originalScrollTop = tableContainerRef?.current?.scrollTop;
      if (originalScrollTop !== undefined && tableContainerRef.current) {
        tableContainerRef.current.scrollTop = originalScrollTop;
      }
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);
  return {
    columns,
    isLoading,
    stableSort,
    orderArray,
    getComparator,
    selectedRow,
    searchQuery,
    order,
    type,
    handleCopyClick,
    state,
    orderBy,
    tableContainerRef,
    tableBodyRef,
    lastEvaluatedKey,
    setSearchQuery,
    setModalOpen,
    modalOpen,
    handleRowClick,
    handleModalOpen,
    handleSortClick,
    handleModalClose,
    fetchAllOrders,
    handleDeleteOrder,
  };
};
export default useOrders;
