import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconMenu from './IconMenu';
import UserInformation from './UserInformation';
import InformationItem from './InformationItem';
import { useState } from 'react';
import ProcessingLog from './ProcessingLog';
import ChangePassword from './ChangePassword';
import PersonalInformation from './PersonelInformation';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
  const [selectedMenu, setSelectedMenu] = useState(1);

  return (
    <Grid container sx={{ height: '100%', padding: 2 }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={2.5} sx={{ height: '100%', overflowX: 'auto' }}>
          <Item sx={{ padding: 1, marginRight: '10px' }}>
            <UserInformation />
            <IconMenu
              page={'user'}
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
            />
          </Item>
          <Item
            sx={{
              padding: 1,
              marginRight: '10px',
              marginTop: '10px',
              minHeight: '24%',
            }}
          >
            <InformationItem />
          </Item>
        </Grid>
        <Grid container xs={9.5} sx={{ width: '100%', height: '100%' }}>
          <Item sx={{ width: '100%', height: '100%' }}>
            {selectedMenu === 1 && <ProcessingLog />}
            {selectedMenu === 2 && <PersonalInformation />}
            {selectedMenu === 3 && <ChangePassword />}
          </Item>
        </Grid>
      </Grid>
    </Grid>
  );
}
