import React, { useRef, useEffect } from 'react';
import { Viewer } from 'resium';
import * as Cesium from 'cesium';
import { imageriesTypes } from './ImageriesType';
import overlays from '../../assets/Brightearth/overlays.png';

const BrightEarthPage = () => {
  const token =
    'eyJraWQiOiI5a04zWk8rd2NYWWk4UldpU1A4NmhMYTJFY0h5MG5pclFrRXEySUF1T3o4PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzYjI2MGIxZS00MTBhLTQ3NDAtOTE2Mi1mYmZiMTJjOTYzYjUiLCJ3ZWJzaXRlIjoiTHV4Y2FydGFcL0NhdGFsb2ciLCJjb2duaXRvOmdyb3VwcyI6WyJBZG1pbiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYWRkcmVzcyI6eyJmb3JtYXR0ZWQiOiIyMDggY2hlbWluIGRlcyBjYWlsbGFkZXMifSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfNkNJWmFxSmszIiwiY29nbml0bzp1c2VybmFtZSI6IjNiMjYwYjFlLTQxMGEtNDc0MC05MTYyLWZiZmIxMmM5NjNiNSIsIm9yaWdpbl9qdGkiOiI1ODJmMDk4Yi00ZTE5LTQwZmMtOGJhZi1iMjBiYmViZTE1MzgiLCJhdWQiOiI2c3R1NW10bWZwaDlpc2VhcTkwcmMyMHNjOCIsImV2ZW50X2lkIjoiM2M3OTRiY2ItZTI2Mi00OGZhLTlkMTItY2I2MjMzMDk2YmVlIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2OTU2NDY0NDgsIm5hbWUiOiJNYXhpbWUiLCJleHAiOjE2OTY1MjA3NDUsImlhdCI6MTY5NjQzNDM0NSwiZmFtaWx5X25hbWUiOiJEaXppZXIiLCJqdGkiOiI5NjM4YmQ4MS00NzIzLTQ0NDItOWU3Zi0xZmI4N2FmOTQ4YzMiLCJlbWFpbCI6Im1kaXppZXJAbHV4Y2FydGEuY29tIn0.RXmkU6RBjkMR49mVL6_K-ZwUfiw8I2s_5sTuaGk3dR_9w1zVoHyOecJJQYiC2j3pY-txHQouLuR7CpGCwU9irOAbaPWJ2QRwVoZpZ8FV8kCDvhFlmFW_TqS61rxO3XMaBZz3BuMV71MEe6vuJQpa28F2gMcdShOBjgKPIR8O5KVC7yE8t7z1oEZP9_fGk35v1suEQJK6sgHAiLVyJi-47vuYtMKpE2Y7woZAic-6IWplXQfMdTA0WgcvTDLvOmidkbicemVpJg85HiXMS2p5ErqNfBgXnyX4_JjoUyoiAhdkzC5j20aLwjFJseNjjcaYPuosFfHPslnt14m6UCUtsg';
  const viewerRef = useRef(null);
  Cesium.Ion.defaultAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNzFlZTE5Ni0wOTc3LTRlNDEtYmQ4Ny1hMGY0YzVhZDhjNGEiLCJpZCI6NjM3NzQsImlhdCI6MTYzODIwMzg1OH0.t_Qao4QXebBc5E1SIm2iSniMIXd3FU8Gg3HZft4frLU';
  var LxTileMapServiceImageryProvider = function (options) {
    var tms_server_base_url = 'https://mkee2exwgg.execute-api.eu-central-1.amazonaws.com/PreDev/tms';
    if (!options.url) {
      if (!options.layer)
        throw new Cesium.DeveloperError(
          'options.layer is required when options.url is not provided.',
        );
      if (!options.composer)
        throw new Cesium.DeveloperError(
          'options.composer is required when options.url is not provided.',
        );
      options.url =
        tms_server_base_url +
        '/' +
        options.layer +
        '/' +
        options.composer +
        '/{z}/{y}/{x}';
    } else {
      if (options.layer)
        throw new Cesium.DeveloperError(
          "you can't specify both options.layer and options.url.",
        );
      if (options.composer)
        throw new Cesium.DeveloperError(
          "you can't specify both options.composer and options.url.",
        );
    }
    if (options && (options.hasThreshold || options.threshold)) {
      this.hasThreshold = options.hasThreshold;
      if (options.threshold) this.threshold = options.threshold;
      else this.threshold = 0.5;

      this.baseUrl = options.url;
    }
    if (options && !options.maximumLevel) options.maximumLevel = 14;
    // initialize base provider
    options.headers = options.headers || {};
    options.headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`; // Set the Authorization header

    var resource = new Cesium.Resource({
      url: options.url,
      headers: options.headers,
    });

    Cesium.UrlTemplateImageryProvider.call(this, {
      url: resource,
      maximumLevel: options.maximumLevel,
    });
  };

  LxTileMapServiceImageryProvider.prototype = Object.create(
    Cesium.UrlTemplateImageryProvider.prototype,
  );

  LxTileMapServiceImageryProvider.prototype.constructor =
    LxTileMapServiceImageryProvider;

  const handleDefaultImagery = () => {
    const imageryViewModels = [];
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: 'Bing Maps Aerial',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingAerial.png',
        ),
        tooltip: 'Bing Maps aerial imagery \nhttp://www.bing.com/maps',
        creationFunction: function () {
          return new Cesium.BingMapsImageryProvider({
            url: '//dev.virtualearth.net',
            mapStyle: Cesium.BingMapsStyle.AERIAL,
          });
        },
      }),
    );
    //     imageryViewModels.push(new Cesium.ProviderViewModel({
    //       category: "Cesium Ion",
    //       name: "Bing Maps aerial",
    //       iconUrl: "https://cesium.com/downloads/cesiumjs/releases/1.70/Build/Cesium/Widgets/Images/ImageryProviders/earthAtNight.png",
    //       tooltip: "The lights of cities and villages trace the outlines of civilization \
    // in this global view of the Earth at night as seen by NASA/NOAA's Suomi NPP satellite.",
    //       creationFunction: function () {
    //         return Cesium.IonImageryProvider.fromAssetId(13933);
    //       }
    //     }));

    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        category: 'Cesium Ion',
        name: 'Earth at Night',
        iconUrl:
          'https://cesium.com/downloads/cesiumjs/releases/1.70/Build/Cesium/Widgets/Images/ImageryProviders/earthAtNight.png',
        tooltip:
          "The lights of cities and villages trace the outlines of civilization \
in this global view of the Earth at night as seen by NASA/NOAA's Suomi NPP satellite.",
        creationFunction: function () {
          return Cesium.IonImageryProvider.fromAssetId(3812);
        },
      }),
    );

    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        category: 'Cesium Ion',
        name: 'Natural Earth\u00a0II',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/naturalEarthII.png',
        ),
        tooltip:
          'Natural Earth II, darkened for contrast.\nhttp://www.naturalearthdata.com/',
        creationFunction: function () {
          return Cesium.TileMapServiceImageryProvider.fromUrl(
            Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII'),
          );
        },
      }),
    );
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        category: 'Other',
        name: 'Open\u00adStreet\u00adMap',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/openStreetMap.png',
        ),
        tooltip:
          'OpenStreetMap (OSM) is a collaborative project to create a free editable \
map of the world.\nhttp://www.openstreetmap.org',
        creationFunction: function () {
          return new Cesium.OpenStreetMapImageryProvider({
            url: 'https://tile.openstreetmap.org/',
          });
        },
      }),
    );
    return imageryViewModels;
  };

  const createProvideViewModels = () => {
    let providerViewModelItems = [];
    for (const imageryType of imageriesTypes) {
      providerViewModelItems.push(
        new Cesium.ProviderViewModel({
          name: imageryType.name,
          category: imageryType.category,
          tooltip: imageryType.tooltip,
          iconUrl: imageryType.iconUrl,
          creationFunction: function () {
            return new LxTileMapServiceImageryProvider({
              layer: imageryType.layer,
              composer: imageryType.composer,
              headers: imageryType.headers,
              credit: imageryType.credit || undefined,
              hasThreshold: imageryType.hasThreshold,
            });
          },
        }),
      );
    }
    return providerViewModelItems.concat(handleDefaultImagery());
  };

  // useEffect(() => {
  //   console.log(viewerRef.current.cesiumElement)
  // }, [])

  useEffect(() => {
    console.log(viewerRef);
  }, []);

  return (
    <Viewer
      timeline={false}
      animation={false}
      full
      ref={viewerRef}
      imageryProviderViewModels={createProvideViewModels()}
    ></Viewer>
  );
};

export default BrightEarthPage;

// import React from 'react';
// import { Viewer, ImageryLayerCollection } from 'resium';
// import * as Cesium from 'cesium';
// import { createDefaultImageryProviderViewModels } from 'cesium';
// const BrightEarthPage = () => {
//   const imageryProviderViewModels = createDefaultImageryProviderViewModels();

//   return (
//     <Viewer full>
//       {/* Render the default imagery providers */}
//       <ImageryLayerCollection>
//         {imageryProviderViewModels.map((viewModel, index) => (
//           <Cesium.ImageryProviderViewModel key={index} {...viewModel}>
//             <Cesium.WebMapTileServiceImageryProvider viewModel={viewModel} />
//           </Cesium.ImageryProviderViewModel>
//         ))}
//       </ImageryLayerCollection>

//       {/* Add custom imagery layers */}
//       <ImageryLayerCollection>
//         {/* Custom imagery layer 1 */}
//         <Cesium.ImageryLayer
//           imageryProvider={
//             new Cesium.UrlTemplateImageryProvider({
//               url: 'https://example.com/tiles/{z}/{x}/{y}.jpg',
//             })
//           }
//         />

//         {/* Custom imagery layer 2 */}
//         <Cesium.ImageryLayer
//           imageryProvider={new Cesium.OpenStreetMapImageryProvider()}
//         />
//       </ImageryLayerCollection>
//     </Viewer>
//   );
// };

// export default BrightEarthPage;
