import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { apiCallWithToken } from '../../../../apiCallWithToken';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function MenuDemo() {
  const [checked, setChecked] = useState(true);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const isCheckedString = isChecked.toString();
    setChecked(isChecked);
    await apiCallWithToken(`/demo-mode/${isCheckedString}`, 'PATCH');
    if (isChecked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  useEffect(() => {
    const getSwitcherStatus = async () => {
      const response = await apiCallWithToken('/demo-mode/status');
      if (response['is_demo_mode_enabled'] === true) {
        setChecked(true);
      } else {
        setChecked(false);
      }
      setLoading(false);
    };
    setLoading(true);
    getSwitcherStatus();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ height: 60 }}>
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={'100%'} height={60} />
        </Stack>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 60 }}>
      <Grid container>
        <Grid
          item
          xs={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography>Demo mode ({checked ? 'On' : 'Off'})</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
