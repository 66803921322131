/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { AllTransactions } from '../../../contexts/types/transactionType';
import { getAllTransactions } from '../../../api/transactionsApi';
import { useDialog } from '../../apiCallWithToken/dialogContext';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { useSnackbar } from '../../SnackbarProvider';

const useTransactions = () => {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null>(null);
  const fetchInProgressRef = useRef(false);

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('id');
  const { setShowDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [allTransactions, setAllTransactions] = useState<AllTransactions[]>([]);
  const isLoading = allTransactions.length === 0;
  const transactionsArray: AllTransactions[] = allTransactions;
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const fetchMoreTransctions = useCallback(async () => {
    if (lastEvaluatedKey !== null) {
      if (fetchInProgressRef.current) {
        return;
      }
      fetchInProgressRef.current = true;
      try {
        const queryParams = lastEvaluatedKey
          ? `?last_evaluated_key=${lastEvaluatedKey}&limit=10`
          : '?limit=10';

        const response = await tokenWrapper(() =>
          getAllTransactions(queryParams),
        );
        if (response === null) {
          setShowDialog(true);
        }
        setAllTransactions((prevTransactions) => {
          return prevTransactions
            ? [...prevTransactions, ...response?.Items]
            : response?.Items;
        });
        setLastEvaluatedKey(response?.LastEvaluatedKey);
      } catch (error) {
        console.log('Error fetching more transactions:', error);
      } finally {
        fetchInProgressRef.current = false;
      }
    }
  }, [lastEvaluatedKey, setAllTransactions, setLastEvaluatedKey]);

  const columns = [
    { id: 'id', label: '#' },
    { id: 'type', label: 'Type' },
    { id: 'userFirstName', label: 'User' },

    { id: 'amount', label: 'Amount' },
    { id: 'date', label: 'Date' },
    { id: 'progress', label: 'Progress ID' },
  ];

  const handleCopyClick = (event: any, id: any) => {
    event.stopPropagation();

    navigator.clipboard
      .writeText(id)
      .then(() => {
        showSnackbar('Transaction Id copied', '#4CAF50', 'white');
      })
      .catch((err) => {
        showSnackbar('Failed to copy', 'tomato', 'white');

        console.error('Failed to copy: ', err);
      });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (
        lastEvaluatedKey &&
        tableBodyRef.current &&
        tableContainerRef.current &&
        tableBodyRef.current.scrollHeight -
          tableContainerRef.current.clientHeight <=
          tableContainerRef.current.scrollTop &&
        !fetchInProgressRef.current
      ) {
        fetchMoreTransctions();
      }
    };

    const handleResize = () => {
      const container = tableContainerRef.current;
      const body = tableBodyRef.current;

      if (
        lastEvaluatedKey &&
        container &&
        body &&
        container.scrollHeight - container.clientHeight <=
          container.scrollTop &&
        !fetchInProgressRef.current
      ) {
        fetchMoreTransctions();
      }
    };
    const containerRef = tableContainerRef.current;
    if (containerRef) {
      containerRef.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (containerRef) {
        containerRef.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [lastEvaluatedKey, fetchMoreTransctions, tableBodyRef, tableContainerRef]);
  useEffect(() => {
    const queryParams = '?limit=30';
    const fetchTransactions = async () => {
      try {
        const data = await tokenWrapper(() => getAllTransactions(queryParams));
        if (data === null) {
          setShowDialog(true);
        }
        setAllTransactions(data.Items);

        setLastEvaluatedKey(data.LastEvaluatedKey);
      } catch (error) {
        console.error('Error fetching transaction data:', error);
      }
    };
    fetchTransactions();
  }, []);

  return {
    columns,
    isLoading,
    handleCopyClick,
    transactionsArray,
    tableContainerRef,
    tableBodyRef,
    order,
    orderBy,
    lastEvaluatedKey,
    fetchMoreTransctions,
  };
};
export default useTransactions;
