import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import PopoverCard from './popoverCard';
import { changePassword } from '../../../api/usersApi';
import { useSnackbar } from '../../SnackbarProvider';
import { useDialog } from '../../apiCallWithToken/dialogContext';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { useUser } from '../../AuthContext';

const ChangePassword = () => {
  const [popoverState, setPopoverState] = useState({
    open: false,
    anchorEl: null as HTMLElement | null,
    title: '',
    text: '',
  });
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { setShowDialog } = useDialog();
  const { user } = useUser();

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleInputChange = (event: any, field: any) => {
    const value = event.target.value;
    if (field === 'currentPassword') {
      setCurrentPassword(value);
    } else if (field === 'newPassword') {
      setNewPassword(value);
    } else if (field === 'confirmPassword') {
      setConfirmPassword(value);
    }
    const passwordIsValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        value,
      );
    if (!passwordIsValid) {
      setPasswordError(
        'Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character',
      );
    } else {
      setPasswordError('');
    }
    setButtonDisabled(
      currentPassword === '' || newPassword === '' || confirmPassword === '',
    );
  };

  const handleSaveChanges = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
    try {
      const response = await tokenWrapper(() =>
        changePassword(currentPassword, newPassword, user?.email),
      );
      if (response === null) {
        setShowDialog(true);
      } else if (response && response.detail) {
        showSnackbar(response.detail, '#4CAF50');
      } else {
        showSnackbar('Error updating password', 'tomato');

        console.error('Error updating attributes:', response);
      }
    } catch (error) {
      console.error('Error updating attributes:', error);
    }
    setModalOpen(false);
  };

  const handlePopoverClose = () => {
    setPopoverState({
      ...popoverState,
      open: false,
      anchorEl: null,
    });
  };

  return (
    <Box>
      <CardHeader
        title={
          <Typography variant="h5" sx={{ textAlign: 'start', color: 'black' }}>
            Change Password
          </Typography>
        }
        action={
          <Button
            variant="contained"
            onClick={handleModalOpen}
            disabled={buttonDisabled}
          >
            Update Password
          </Button>
        }
      />
      <Divider />

      <Grid m={2}>
        <Alert
          severity="info"
          sx={{
            color: '#206294',
            display: 'flex',
            minHeight: 50,
            alignItems: 'center',
          }}
        >
          To change your password, enter your existing password and choose a new
          password. When you are done, click 'Update Password'.
        </Alert>

        <Grid container spacing={2} columns={16} mt={1}>
          <Grid item xs={4} display="flex" alignItems="center">
            <Typography variant="body1">Current Password</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              placeholder="Current password"
              size="small"
              aria-owns={popoverState.open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onChange={(e) => handleInputChange(e, 'currentPassword')}
            />
            <PopoverCard
              open={popoverState.open}
              anchorEl={popoverState.anchorEl}
              handlePopoverClose={handlePopoverClose}
              title={popoverState.title}
              text={popoverState.text}
            />

            <Box
              sx={{
                textDecoration: 'none',
                textAlign: 'start',
                width: '100%',
                mt: 1,
              }}
            >
              <Link href="#" variant="caption" sx={{ textDecoration: 'none' }}>
                Forgot your password?
              </Link>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} columns={16} mt={1}>
          <Grid item xs={4} display="flex" alignItems="center">
            <Typography variant="body1">New Password</Typography>
          </Grid>
          <Grid item xs={8} display="flex" alignItems="center">
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              placeholder="New Password
              "
              size="small"
              aria-owns={popoverState.open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onChange={(e) => handleInputChange(e, 'newPassword')}

              // onMouseEnter={(e) =>
              //   handlePopoverOpen(
              //     e,
              //     'Password',
              //     'The password must consist of at least 8 characters and contain • a digit, • an uppercase letter, • a lowercase letter and • a special character.',
              //   )
              // }
              // onMouseLeave={handlePopoverClose}
              // onClick={handlePopoverClose}
            />
            <PopoverCard
              open={popoverState.open}
              anchorEl={popoverState.anchorEl}
              handlePopoverClose={handlePopoverClose}
              title={popoverState.title}
              text={popoverState.text}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={16} mt={1}>
          <Grid item xs={4} display="flex" alignItems="center">
            <Typography variant="body1">Confirm New Password</Typography>
          </Grid>
          <Grid item xs={8} display="flex" alignItems="center">
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              placeholder="Confirm New Password
              "
              size="small"
              aria-owns={popoverState.open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              // onMouseEnter={(e) =>
              //   handlePopoverOpen(
              //     e,
              //     'Confirm New Password',
              //     'Retype the exact password you chose above.',
              //   )
              // }
              // onMouseLeave={handlePopoverClose}
              // onClick={handlePopoverClose}
              onChange={(e) => handleInputChange(e, 'confirmPassword')}
            />
            <PopoverCard
              open={popoverState.open}
              anchorEl={popoverState.anchorEl}
              handlePopoverClose={handlePopoverClose}
              title={popoverState.title}
              text={popoverState.text}
            />
          </Grid>
        </Grid>
        {passwordError && <p>{passwordError}</p>}
      </Grid>
      {modalOpen && (
        <Dialog
          maxWidth="xl"
          open={!!modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <Grid direction="column" container p={3} spacing={3}>
            <Grid item>
              <Typography variant="h6">Confirmation</Typography>
              <Divider />
            </Grid>

            <Grid item>
              <Typography>
                {'Are you sure you want to change your password?'}
              </Typography>
            </Grid>

            <Grid item>
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button variant="outlined" onClick={handleModalClose}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSaveChanges}>
                  Confirm
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Box>
  );
};

export default ChangePassword;
