import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert, CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import countries from '../../../utils/CountriesList';

import useSignUp from './useSignUp';
interface SignUpProps {
  onChangeScreen: any;
}

export default function SignUp({ onChangeScreen }: SignUpProps) {
  const {
    handleSubmit,
    setSelectedCountry,
    setError,
    showPassword,
    handleClickShowPassword,
    isRegistered,
    AnimatedBox,
    animationPropsRegistration,
    handleMouseDownPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    error,
    animationProps,
    isloading,
  } = useSignUp();

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{
          m: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
          Sign up
        </Typography>
        <Grid
          container
          spacing={2}
          sm={12}
          alignItems={'center'}
          justifyContent="center"
        >
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="first_name"
              required
              fullWidth
              id="first_name"
              label="First Name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="last_name"
              label="Last Name"
              name="last_name"
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id="country"
              onChange={(e: any) => setSelectedCountry(e.target.textContent)}
              options={countries}
              getOptionLabel={(option: any) => option.name}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  label="Country*"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onFocus={() => setError(null)}
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{ color: '#206294' }}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onFocus={() => setError(null)}
              required
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              id="confirm_password"
              autoComplete="current-password"
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword(true)}
                      onMouseDown={() => setShowConfirmPassword(false)}
                      edge="end"
                      sx={{ color: '#206294' }}
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="company_name"
              label="Company name"
              id="company_name"
              autoComplete="company_name"
            />
          </Grid>
          {isRegistered && (
            <Grid item xs={12}>
              <AnimatedBox
                style={animationPropsRegistration}
                sx={{ width: '100%' }}
              >
                <Alert severity="success" sx={{ width: '100%' }}>
                  We have considered your request, you will receive an email
                  confirmation when your account will be activated.
                </Alert>
              </AnimatedBox>
            </Grid>
          )}
          {error && (
            <Grid item xs={12}>
              <AnimatedBox style={animationProps} sx={{ width: '100%' }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                  {error}
                </Alert>
              </AnimatedBox>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained">
              {isloading ? (
                <CircularProgress
                  sx={{ color: 'white', height: 10 }}
                  size={20}
                />
              ) : (
                'Sign Up'
              )}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Link
              sx={{ cursor: 'pointer' }}
              variant="body2"
              onClick={() => onChangeScreen({ name: 'SignIn', from: 'SignUp' })}
            >
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
