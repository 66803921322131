/* ----------------------------------------------------------------------------------------------------------------------- */
/* LxTileMapServiceImageryProvider - provider for Luxcarta global layers                                                   */
/* ----------------------------------------------------------------------------------------------------------------------- */
import * as Cesium from 'cesium';
import { imageriesTypes } from '../ImageriesType';
import VividImg from '../../../../assets/Brightearth/Vivid.png';
import MapBoxImg from '../../../../assets/Brightearth/MapBox.png';
// const resource = new Cesium.Resource({
//   url: 'https://mkee2exwgg.execute-api.eu-central-1.amazonaws.com/Prod/tms',
//   headers: {
//     Authorization: `Bearer ${localStorage.getItem('access_token')}`,
//   },
// });

const API_BASE_URL = process.env.REACT_APP_API_URL;

var LxTileMapServiceImageryProvider = function (options) {
  var tms_server_base_url = `${API_BASE_URL}/tms`;
  if (!options.url) {
    if (!options.layer)
      throw new Cesium.DeveloperError(
        'options.layer is required when options.url is not provided.',
      );
    if (!options.composer)
      throw new Cesium.DeveloperError(
        'options.composer is required when options.url is not provided.',
      );
    options.url =
      tms_server_base_url +
      '/' +
      options.layer +
      '/' +
      options.composer +
      '/{z}/{y}/{x}';
  } else {
    if (options.layer)
      throw new Cesium.DeveloperError(
        "you can't specify both options.layer and options.url.",
      );
    if (options.composer)
      throw new Cesium.DeveloperError(
        "you can't specify both options.composer and options.url.",
      );
  }
  if (options && (options.hasThreshold || options.threshold)) {
    this.hasThreshold = options.hasThreshold;
    if (options.threshold) this.threshold = options.threshold;
    else this.threshold = 0.5;

    this.baseUrl = options.url;
  }
  if (options && !options.maximumLevel) options.maximumLevel = 14;
  // initialize base provider
  options.headers = options.headers || {};
  options.headers.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`; // Set the Authorization header

  var resource = new Cesium.Resource({
    url: options.url,
    headers: options.headers,
  });

  Cesium.UrlTemplateImageryProvider.call(this, {
    url: resource,
    maximumLevel: options.maximumLevel,
  });
};

LxTileMapServiceImageryProvider.prototype = Object.create(
  Cesium.UrlTemplateImageryProvider.prototype,
);

LxTileMapServiceImageryProvider.prototype.constructor =
  LxTileMapServiceImageryProvider;
/* ----------------------------------------------------------------------------------------------------------------------- */

var LxTileMapServiceImageryProviderVivid = function (options) {
  var vivid_server_base_url = `${API_BASE_URL}/vividtile`;
  options.url = `${vivid_server_base_url}/{z}/{x}/{y}`;
  if (options && (options.hasThreshold || options.threshold)) {
    this.hasThreshold = options.hasThreshold;
    if (options.threshold) this.threshold = options.threshold;
    else this.threshold = 0.5;

    this.baseUrl = options.url;
  }
  if (options && !options.maximumLevel) options.maximumLevel = 14;
  // initialize base provider
  options.headers = options.headers || {};
  options.headers.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`; // Set the Authorization header

  var resource = new Cesium.Resource({
    url: options.url,
    headers: options.headers,
  });

  Cesium.UrlTemplateImageryProvider.call(this, {
    url: resource,
    tilingScheme: new Cesium.WebMercatorTilingScheme(),
  });
};

LxTileMapServiceImageryProviderVivid.prototype = Object.create(
  Cesium.UrlTemplateImageryProvider.prototype,
);

LxTileMapServiceImageryProviderVivid.prototype.constructor =
  LxTileMapServiceImageryProviderVivid;

var LxTileMapServiceImageryProviderMapBox = function (options) {
  const mbToken =
    'pk.eyJ1IjoibHV4Y2FydGEiLCJhIjoiY2xwYXk2Yno3MGUxdjJrcXJlNGtudmpvdCJ9.9k2Khly7CbJ_yUtvS87Q2A';
  var vivid_server_base_url = `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2?access_token=${mbToken}`;
  options.url = `${vivid_server_base_url}/{z}/{x}/{y}`;
  if (options && (options.hasThreshold || options.threshold)) {
    this.hasThreshold = options.hasThreshold;
    if (options.threshold) this.threshold = options.threshold;
    else this.threshold = 0.5;

    this.baseUrl = options.url;
  }
  if (options && !options.maximumLevel) options.maximumLevel = 14;
  // initialize base provider
  options.headers = options.headers || {};
  options.headers.Authorization = `Bearer ${localStorage.getItem(
    'access_token',
  )}`; // Set the Authorization header

  var resource = new Cesium.Resource({
    url: options.url,
    headers: options.headers,
  });

  Cesium.UrlTemplateImageryProvider.call(this, {
    url: resource,
    tilingScheme: new Cesium.WebMercatorTilingScheme(),
  });
};

LxTileMapServiceImageryProviderMapBox.prototype = Object.create(
  Cesium.UrlTemplateImageryProvider.prototype,
);

LxTileMapServiceImageryProviderMapBox.prototype.constructor =
  LxTileMapServiceImageryProviderMapBox;

export default function createProviderViewModels() {
  let providerViewModelItems = [];
  for (const imageryType of imageriesTypes) {
    providerViewModelItems.push(
      new Cesium.ProviderViewModel({
        name: imageryType.name,
        category: imageryType.category,
        tooltip: imageryType.tooltip,
        iconUrl: imageryType.iconUrl,
        creationFunction: function () {
          return new LxTileMapServiceImageryProvider({
            layer: imageryType.layer,
            composer: imageryType.composer,
            headers: imageryType.headers,
            credit: imageryType.credit || undefined,
            hasThreshold: imageryType.hasThreshold,
          });
        },
      }),
    );
  }

  providerViewModelItems.push(
    new Cesium.ProviderViewModel({
      name: 'Maxar / Vivid Basic',
      category: 'Other',
      tooltip: '',
      iconUrl: VividImg,
      creationFunction: function () {
        return new LxTileMapServiceImageryProviderVivid({
          headers: { Authorization: localStorage.getItem('access_token') },
          credit: '',
          hasThreshold: false,
        });
      },
    }),
  );
  var mapboxTerrainProvider = new Cesium.MapboxImageryProvider({
    mapId: 'mapbox.satellite',
    accessToken:
      'pk.eyJ1IjoibHV4Y2FydGEiLCJhIjoiY2xwYXk2Yno3MGUxdjJrcXJlNGtudmpvdCJ9.9k2Khly7CbJ_yUtvS87Q2A',
  });

  // Create a ProviderViewModel for Mapbox
  providerViewModelItems.push(
    new Cesium.ProviderViewModel({
      name: 'Mapbox',
      category: 'Other',
      iconUrl: MapBoxImg, // Replace with the path to your icon
      tooltip: 'Mapbox Terrain',
      creationFunction: function () {
        return mapboxTerrainProvider;
      },
    }),
  );

  return providerViewModelItems;
}

/* ----------------------------------------------------------------------------------------------------------------------- */
