import React, { createContext, useContext, useState, ReactNode } from 'react';

type confirmUserContextType = {
  unconfirmedUsersList: any | null;
  setUnconfirmedUsersList: (userData: any) => void;
};

const ConfirmUserContext = createContext<confirmUserContextType | undefined>(
  undefined,
);

export const ConfirmUserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [unconfirmedUsersList, setUnconfirmedUsersList] = useState<any | null>(
    null,
  );
  return (
    <ConfirmUserContext.Provider
      value={{ unconfirmedUsersList, setUnconfirmedUsersList }}
    >
      {children}
    </ConfirmUserContext.Provider>
  );
};

export const useConfirmUser = () => {
  const context = useContext(ConfirmUserContext);
  if (!context) {
    throw new Error(
      'useConfirmUser must be used within a confirmedUsersProvider',
    );
  }
  return context;
};
