import { Box, Grid, Typography } from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import MapIcon from '@mui/icons-material/Map';
import { useUser } from '../../AuthContext';
import Skeleton from '@mui/material/Skeleton';
const InformationItem = () => {
  const { user } = useUser();

  const handleLoading = (type: string) => {
    if (user === null) {
      return <Skeleton />;
    } else {
      return user[type];
    }
  };

  const items = [
    {
      id: 1,
      icon: <ContentPasteIcon sx={{ color: 'primary' }} />,
      text: 'Orders Processed',
      number: handleLoading('orders-processed'),
    },
    {
      id: 2,
      icon: <ContentPasteIcon sx={{ color: 'primary' }} />,
      text: 'Orders In Progress',
      number: handleLoading('orders-active'),
    },
    {
      id: 3,
      icon: <ContentPasteIcon sx={{ color: 'primary' }} />,
      text: 'Credits Spent',
      number: handleLoading('credits-used'),
    },
    {
      id: 4,
      icon: <MapIcon sx={{ color: 'primary' }} />,
      text: 'Area Processed',
      number: handleLoading('area-processed'),
    },
  ];

  return (
    <Box>
      {items.map((item) => (
        <Grid key={item.id} container xs={12} sx={{ mt: 1 }}>
          <Grid item xs={2}>
            {item.icon}
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h5"
              sx={{ textAlign: 'start', mr: 1, color: 'primary', flex: '30%' }}
            >
              {item.text}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              sx={{ textAlign: 'start', flex: '70%' }}
            >
              {item.number}{' '}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );

  return (
    <Grid container spacing={2} columns={16} m={1}>
      {items.map((item) => (
        <Grid key={item.id} item xs={4}>
          <Box display="flex" alignItems="center">
            {item.icon}
            <Box marginLeft={1}>
              <Typography
                variant="h5"
                sx={{ textAlign: 'start', color: 'black' }}
              >
                {item.text}
              </Typography>
              <Typography
                variant="h5"
                sx={{ textAlign: 'start', color: 'black' }}
              >
                {item.number}{' '}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
export default InformationItem;
