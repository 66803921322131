import CesiumGlobe from './CesiumGlobe';
import './index.css';
import Box from '@mui/material/Box/Box.js';
import CircularProgress from '@mui/material/CircularProgress';
import { useDashboardContext } from './DashboardContext';
import TemporaryDrawer from '../LocalTesting/Drawer';
import Grid from '@mui/material/Grid';

export default function Dashboard({
  onOpenModal,
  setModalContent,
  setModalTitle,
  setModalIcon,
}) {
  const { isMapLoading, setLeftMenuOpen, isLeftMenuOpen } =
    useDashboardContext();

  return (
    <Grid container sx={{ height: '100%' }}>
      <TemporaryDrawer />
      <Grid item xs={12} sx={{ position: 'relative' }}>
        {isMapLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
              position: 'absolute',
              bgcolor: 'rgba(255, 255, 255, 0.5)',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress color={'primary'} />
          </Box>
        ) : null}
        <CesiumGlobe
          onOpenModal={onOpenModal}
          setModalContent={setModalContent}
          setModalTitle={setModalTitle}
          setModalIcon={setModalIcon}
        />
      </Grid>
    </Grid>
  );
}
