import Box from '@mui/material/Box';
import AccordionSummary from '@mui/material/AccordionSummary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDashboardContext } from '../../DashboardContext';
import { useSnackbar } from '../../../SnackbarProvider';
import Grid from '@mui/material/Grid';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
export default function LayerAccordionHeader({ order }: any) {
  const { viewerRef, results, setResults, layersDisplayed } =
    useDashboardContext();
  const { showSnackbar } = useSnackbar();
  const viewer = viewerRef.current.cesiumElement;
  const { rectangle } = order;
  console.log(order);
  const handleDelete = (event: any, orderId: any) => {
    const viewer = viewerRef.current.cesiumElement;

    event.stopPropagation();

    const _3dTile = viewer.scene.primitives._primitives.find((x: any) =>
      layersDisplayed.some((y) => y.id === x.id),
    );

    const _2dTile = viewer.imageryLayers._layers.find((x: any) =>
      layersDisplayed.some((y) => y.id === x._imageryProvider.id),
    );
    viewer.imageryLayers.remove(_2dTile);
    viewer.scene.primitives.remove(_3dTile);
    viewer.scene.camera.flyHome();

    const updatedResults = Array.from(results).filter(
      (result) => result.orderId !== orderId,
    );
    setResults(updatedResults);

    showSnackbar('Order deleted successfully', '#4CAF50', 'white');
  };
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      style={{
        minHeight: '0px',
        padding: '0px 10px',
      }}
      sx={{
        '.MuiAccordionSummary-content.Mui-expanded': {
          margin: '10px',
          width: '100%',
        },
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Tooltip title={order.params?.name}>
            <Typography
              variant="h6"
              sx={{
                wordWrap: 'break-word',
                maxWidth: '230px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {order.params?.name}
            </Typography>
          </Tooltip>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Grid container>
            <Grid
              item
              xs={8}
              sx={{
                display: 'flex',

                alignItems: 'center',
              }}
            >
              <Typography
                variant="body1"
                style={{
                  color:
                    order.state === 'SUCCEEDED'
                      ? '#71BF49'
                      : order.state === 'CREATED'
                      ? '#206294'
                      : 'tomato',
                }}
              >
                {order.state === 'CREATED' ? 'RUNNING' : order.state}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                disabled={order.state === 'SUCCEEDED' ? false : true}
                onClick={(e) => {
                  e.stopPropagation();
                  console.log(order);
                  viewer.camera.flyTo({ destination: rectangle });
                }}
              >
                <ZoomInIcon
                  sx={
                    order.state === 'SUCCEEDED'
                      ? { color: '#206294' }
                      : { color: 'gray' }
                  }
                  fontSize="small"
                />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <IconButton
                disabled={
                  order.state === 'SUCCEEDED' || 'FAILED' ? false : true
                }
                onClick={(event) => {
                  handleDelete(event, order.orderId);
                }}
              >
                <DeleteIcon
                  sx={
                    order.state === 'SUCCEEDED' || 'FAILED'
                      ? { color: 'tomato' }
                      : { color: 'gray' }
                  }
                  fontSize="small"
                />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </AccordionSummary>
  );
}
