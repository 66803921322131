import { useState } from 'react';
import Footer from '../../../components/Footer';
import AppBar from '../../../components/AppBar';
import DashBoardModal from '../../../components/DashBoardModal';
import TransactionManagement from '../../../components/AdminDashboard/TransactionManagement';

export default function TransactionManagementPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalIcon, setModalIcon] = useState(null);

  const setContent = (value: any) => {
    setModalContent(value);
  };

  const setTitle = (value: string) => {
    setModalTitle(value);
  };

  const setIcon = (value: any) => {
    setModalIcon(value);
  };

  return (
    <>
      <DashBoardModal
        open={isModalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalIcon={modalIcon}
        onClose={() => setIsModalOpen(false)}
      />
      <AppBar
        onOpenModal={() => setIsModalOpen(true)}
        setModalContent={setContent}
        setModalTitle={setTitle}
        setModalIcon={setIcon}
      />
      <TransactionManagement />
      <Footer />
    </>
  );
}
