import React from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableBody,
  Box,
  Paper,
  Avatar,
  Grid,
  CircularProgress,
  IconButton,
} from '@mui/material';

import useTransactions from '../Transactions/useTransactions';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const TransactionsManagementTable = (props: any) => {
  const isLoading = props.transactionsArray.length === 0;

  const { order, orderBy, columns, handleCopyClick } = useTransactions();

  return (
    <Paper sx={{ width: '100%' }}>
      <Box
        ref={props.tableContainerRef}
        style={{
          maxHeight: `calc(100vh - (90px) )`,
          overflowY: 'auto',
        }}
      >
        <Table>
          <TableHead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'white',
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <Typography
                    fontSize={14}
                    sx={{ color: '#223354', cursor: 'pointer' }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody ref={props.tableBodyRef}>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={16}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress sx={{ height: 10, m: 1 }} size={20} />
                </TableCell>
              </TableRow>
            ) : (
              props?.transactionsArray.map((e: any) => (
                <TableRow
                  sx={{ cursor: 'pointer' }}
                  hover
                  key={`${e.sub}${e['GSI1-SK']}`}
                >
                  <TableCell>
                    {e?.sub}{' '}
                    {e['is_user_deleted'] === true ? null : (
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={(event) => handleCopyClick(event, e?.sub)}
                      >
                        <FileCopyIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Typography
                      sx={{
                        color: '#206294',
                        backgroundColor: '#E1F0FF',
                        fontSize: 'small',
                        padding: '2px 5px',
                        borderRadius: '5px',
                        display: 'inline-grid',
                      }}
                    >
                      {e?.type}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: '#EBEDF3',
                          color: '#71BF49',
                          borderRadius: 1,
                          width: 40,
                          height: 40,
                          fontSize: 14,
                        }}
                      >
                        {e?.first_name?.charAt(0)}
                      </Avatar>

                      <Grid ml={2}>
                        <Typography
                          sx={{
                            textAlign: 'start',
                            color: 'black',
                          }}
                        >
                          {e['is_user_deleted'] === true
                            ? 'User deleted'
                            : e.first_name + ' ' + e.last_name}
                        </Typography>
                        <Typography
                          sx={{
                            color: 'lightslategray',
                            textAlign: 'start',
                            fontSize: 'small',
                          }}
                        >
                          {e?.email}
                        </Typography>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell>{e?.amount}</TableCell>
                  <TableCell>
                    {new Date(e['GSI1-SK'])?.toLocaleDateString('en-GB')}
                  </TableCell>
                  <TableCell>{e?.processId}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          {props.lastEvaluatedKey !== null && (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={16}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress sx={{ height: 10, m: 1 }} size={20} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Box>
    </Paper>
  );
};

export default TransactionsManagementTable;
