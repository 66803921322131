import { useUser } from '../../AuthContext';
import Skeleton from '@mui/material/Skeleton';
import { useAdminInfos } from '../../Dashboard/AdminContext';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import MapIcon from '@mui/icons-material/Map';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupIcon from '@mui/icons-material/Group';
const useAdminInformation = () => {
  const { user } = useUser();
  const { adminInfos } = useAdminInfos();

  const handleLoading = (type: string) => {
    if (user === null) {
      return <Skeleton />;
    } else {
      return user[type];
    }
  };

  const handleLoadingAdmin = (type: string) => {
    if (adminInfos === null) {
      return <Skeleton />;
    } else {
      return adminInfos[type];
    }
  };

  const items = [
    {
      id: 1,
      icon: <GroupIcon sx={{ color: 'black' }} />,
      text: 'Total Users',
      number: handleLoadingAdmin('confirmed_users_count'),
    },
    {
      id: 2,
      icon: <ManageAccountsIcon sx={{ color: 'black' }} />,
      text: 'Unconfirmed Users',
      number: handleLoadingAdmin('unconfirmed_users_count'),
    },
    {
      id: 3,
      icon: <HandshakeIcon sx={{ color: 'black' }} />,
      text: 'New Users (< 30 days)',
      number: handleLoadingAdmin('new_users_count'),
    },
    {
      id: 4,
      icon: <ContentPasteIcon sx={{ color: 'black' }} />,
      text: 'Orders Processed',
      number: handleLoading('orders-processed'),
    },
    {
      id: 5,
      icon: <ContentPasteIcon sx={{ color: 'black' }} />,
      text: 'Orders In Progress',
      number: handleLoading('orders-active'),
    },

    {
      id: 6,
      icon: <MapIcon sx={{ color: 'black' }} />,
      text: 'Area Processed',
      number: `${handleLoading('area-processed')} km²`,
    },
  ];

  const informationItems = [
    { id: 1, title: 'Max. AOI', text: user?.['area-processed'] + 'km²' },
    {
      id: 2,
      title: 'Signup Date',
      text:
        user && user['GSI1-SK']
          ? new Date(user['GSI1-SK']).toLocaleDateString('en-GB')
          : '',
    },
    {
      id: 3,
      title: 'Last Updated',
      text:
        user && user['GSI1-SK']
          ? new Date(user['GSI1-SK']).toLocaleDateString('en-GB')
          : '',
    },
  ];
  return {
    informationItems,
    items,
    user,
  };
};
export default useAdminInformation;
