import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableBody,
  Paper,
  Box,
  Avatar,
  Grid,
  Button,
  IconButton,
} from '@mui/material';

import { confirmAccount } from '../../../api/usersApi';
import { useCallback } from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSnackbar } from '../../SnackbarProvider';
import { useConfirmationModal } from '../../ConfirmationModalProvider';
import { useConfirmUser } from '../../Dashboard/ConfirmUser';

const UserManagementTable = (props: any) => {
  const { showSnackbar } = useSnackbar();

  const handleCopyClick = (event: any, id: any) => {
    event.stopPropagation();

    navigator.clipboard
      .writeText(id)
      .then(() => {
        showSnackbar('User Id copied', '#4CAF50', 'white');
      })
      .catch((err) => {
        showSnackbar('Failed to copy', 'tomato', 'white');

        console.error('Failed to copy: ', err);
      });
  };

  const { showConfirmationModal } = useConfirmationModal();
  const { unconfirmedUsersList, setUnconfirmedUsersList } = useConfirmUser();
  let acceptedUsersList: any = [];

  const handleEnableUser = useCallback(async (id?: string | undefined) => {
    try {
      await confirmAccount(id);
      acceptedUsersList.push(id);
      const filteredList = unconfirmedUsersList?.filter(
        (user: any) => !acceptedUsersList.includes(user?.sub),
      );
      setUnconfirmedUsersList(filteredList);
      showSnackbar('User enabled successfully', '#4CAF50', 'white');
    } catch (error) {
      console.error('Error: ', error);
    }
  }, []);
  const handleAdd = (id: any) => {
    showConfirmationModal(`Are you sure you want to accept the user?`, () =>
      handleEnableUser(id),
    );
  };

  const columnsManagementTable = [
    { id: 'id', label: '#' },
    { id: 'userFirstName', label: 'User' },
    { id: 'email', label: 'Email' },
    { id: 'companyName', label: 'Company Name' },
    { id: 'country', label: 'Country' },
    { id: 'signupDate', label: 'Creation Date' },
    { id: 'lastModifiedDate', label: 'Last Modified Date' },
    { id: 'action', label: 'Action' },
  ];
  return (
    <Paper
      elevation={3}
      sx={{ width: '100%', overflowX: 'auto', height: '90%' }}
    >
      {' '}
      <Box
        style={{
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Table style={{ maxHeight: '100%' }}>
          <TableHead
            style={{
              top: 0,
              zIndex: 1,
              backgroundColor: 'white',
              height: '100%',
            }}
          >
            <TableRow>
              {columnsManagementTable.map((column) => (
                <TableCell key={column.id}>
                  <Typography
                    fontSize={14}
                    sx={{ color: '#223354', cursor: 'pointer' }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {unconfirmedUsersList?.length > 0 ? (
              unconfirmedUsersList?.map((e: any) => (
                <TableRow
                  sx={{
                    cursor: 'pointer',
                  }}
                  hover
                  key={e.sub}
                >
                  <TableCell>
                    {e.sub}
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={(event) => handleCopyClick(event, e.sub)}
                    >
                      <FileCopyIcon fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: '#EBEDF3',
                          color: '#71BF49',
                          borderRadius: 1,
                          width: 40,
                          height: 40,
                          fontSize: 14,
                        }}
                        variant="square"
                      >
                        {e['custom:first_name']
                          ? e['custom:first_name'].charAt(0)
                          : ''}
                      </Avatar>

                      <Grid ml={2}>
                        <Typography
                          sx={{
                            textAlign: 'start',
                            color: 'black',
                          }}
                        >
                          {e['custom:first_name']}
                        </Typography>
                        <Typography
                          sx={{
                            color: 'lightslategray',
                            textAlign: 'start',
                            fontSize: 'small',
                          }}
                        >
                          {e['custom:last_name']}
                        </Typography>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell>{e.email}</TableCell>
                  <TableCell>{e['custom:company_name']}</TableCell>
                  <TableCell>{e['custom:country']}</TableCell>
                  <TableCell>
                    {e.UserCreateDate
                      ? new Date(e.UserCreateDate).toLocaleDateString('en-GB')
                      : ''}
                  </TableCell>

                  <TableCell>
                    {e.UserLastModifiedDate
                      ? new Date(e.UserLastModifiedDate).toLocaleDateString(
                          'en-GB',
                        )
                      : ''}
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => handleAdd(e.sub)}
                    >
                      Accept
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={16}
                  sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}
                >
                  <Box sx={{ m: 1 }}>No user to confirm</Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};
export default UserManagementTable;
