import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import VerificationCode from './verificationCode';
import { useSnackbar } from '../../SnackbarProvider';

interface ForgotPasswordProps {
  onBack: () => void;
  onChangeScreen: any;
}

const ForgotPassword = ({ onBack, onChangeScreen }: ForgotPasswordProps) => {
  const [error, setError] = useState<any>(null);
  const [isloading, setLoading] = useState(false);
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const url = `${process.env.REACT_APP_API_URL}/forgot-password`;
    setLoading(true);

    const body = {
      username: data.get('email'),
    };

    axios
      .post(url, body)
      .then((res) => {
        const { detail } = res.data;

        showSnackbar(detail, '#4CAF50');

        setShowVerificationCode(true);
        setLoading(false);
      })
      .catch((error) => {
        const { detail } = error.response.data;

        setError(detail);
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {showVerificationCode ? (
        <VerificationCode onBack={onBack} />
      ) : (
        <>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Typography textAlign={'center'} mt={3} maxWidth={'70%'}>
            Enter the email address associated with your account and we'll send
            you a code to reset your password
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ m2: 2 }}
            onSubmit={handleSubmit}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onFocus={() => setError(null)}
            />
            {error && <Alert severity="error">{error}</Alert>}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, height: 37 }}
            >
              {isloading ? (
                <CircularProgress
                  sx={{ color: 'white', height: 10 }}
                  size={20}
                />
              ) : (
                'Submit'
              )}
            </Button>
            <Link
              sx={{ cursor: 'pointer' }}
              variant="body2"
              onClick={() =>
                onChangeScreen({ name: 'SignIn', from: 'ForgotPassword' })
              }
            >
              Back to login
            </Link>
          </Box>
        </>
      )}
    </Box>
  );
};
export default ForgotPassword;
