import ReplayIcon from '@mui/icons-material/Replay';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import PinDropIcon from '@mui/icons-material/PinDrop';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import InventoryIcon from '@mui/icons-material/Inventory';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Box } from '@mui/material';

export const Help = [
  {
    name: 'Area of interest',
    content: [
      {
        title: 'Upload Last Area of Interest',
        icon: <CloudUploadIcon style={{ color: 'gray' }} />,
        text: 'Any KML or KMZ file which covers an area of less than 5.00km2 is acceptable as long as the file only contains a single polygon.',
      },

      {
        title: 'Draw  Area of Interest',
        icon: <FormatShapesIcon style={{ color: 'gray' }} />,
        text: "To draw an area of interest (AOI), click the 'Draw AOI' button and then start clicking along the boundary of the area of interest to place nodes. To finish drawing, click either the first or last node once. When you have finshed drawing an AOI, it is selected by default (it will have a dashed outline and display in orange). To deselect it, simply click outside the AOI on the map. It will be displayed in blue with a solid outline when deselected. To delete an AOI, simply select it by clicking it once and then click 'Delete AOI' or press the Delete key on your keyboard. To edit an AOI, select it by clicking it once. To move the entire AOI, click on it and drag it to the new location. To move an exisiting node, click it once. The node will have a white border around it. Click and drag to move it or press the Delete key to remove it.",
      },
    ],
  },
  {
    name: 'Inputs',
    content: [
      {
        title: 'Default',
        icon: <CropSquareIcon style={{ color: 'gray' }} />,
        text: (
          <Box sx={{ padding: 1 }}>
            <Box sx={{ color: 'black' }}>Default</Box>
            <Box>- Mapbox: Vivid & aerial</Box>
            <Box>- Own: Use your own imagery</Box>
          </Box>
        ),
      },

      {
        title: 'Own imagery',
        icon: <InventoryIcon style={{ color: 'gray' }} />,
        text: 'If you select this option, you need to specify the imagery to use from Amazon S3.',
      },
    ],
  },
  {
    name: 'Outputs',
    content: [
      {
        title: '2D Vectors Layers Only',
        icon: <CropSquareIcon style={{ color: 'gray' }} />,
        text: "Use the '2D Vectors' option to extract building and tree footprints without heights. By default, this option is selected.",
      },
      {
        title: '2D & 3D vectors layers',
        icon: <InventoryIcon style={{ color: 'gray' }} />,
        text: "The '2D & 3D vectors layers' option includes vectors (buidlings and trees) with heights attached.",
      },
      {
        title: '2D & 3D vectors and rasters layers',
        icon: <InventoryIcon style={{ color: 'gray' }} />,
        text: "The '2D & 3D vectors and rasters layers' option includes vectors (buidlings and trees) with heights attached. Additionally, a land use (clutter) raster layer and digital terrain model (DTM) is also created.",
      },
    ],
  },
  {
    name: 'Checkout',
    content: [
      {
        title: 'Name the Process',
        icon: <LocalOfferIcon style={{ color: 'gray' }} />,
        text: 'To easily identify this job in your processing log later, give it a descriptive name in the field provided.',
      },
      {
        title: 'Start Processing',
        icon: <PlayArrowIcon style={{ color: 'gray' }} />,
        text: "To be able to process satellite imagery, please upload or draw an area of interest polygon. After a polygon was created, select the layers you want under the 'Layers' section.",
      },
    ],
  },
];
