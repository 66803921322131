// import * as React from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import ListSubheader from '@mui/material/ListSubheader';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

// export default function GroupedSelect() {
//   return (
//     <>
//       <FormControl sx={{ width: '100%' }}>
//         <InputLabel htmlFor="grouped-select">
//           Select optional formats
//         </InputLabel>
//         <Select defaultValue="" id="grouped-select" label="Grouping">
//           <ListSubheader>Vectors</ListSubheader>
//           <MenuItem value={1}>SpatiaLite</MenuItem>
//           <MenuItem value={1}>GeoPackage</MenuItem>
//           <MenuItem value={1}>Shapefile (zipped)</MenuItem>
//           <ListSubheader>Rasters</ListSubheader>
//           <MenuItem value={1}>GeoTIFF</MenuItem>
//         </Select>
//       </FormControl>
//     </>
//   );
// }

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Box, Typography } from '@mui/material';
import { useDashboardContext } from '../../DashboardContext';

interface CheckboxItem {
  value: string;
  label: string;
  type: string;
}

const GroupedSelect: React.FC = () => {
  // const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const { handleCheckboxChange, formDataValues } = useDashboardContext();
  const {
    output_raster_formats,
    output_vector_formats,
    extraction_type,
    layers_3D,
  } = formDataValues;
  const vectorsCheckboxes: CheckboxItem[] = [
    { type: 'Vector', value: 'shapefile', label: 'Shapefile' },
    { type: 'Vector', value: 'geojson', label: 'GeoJSON' },
    { type: 'Vector', value: 'geopackage', label: 'GeoPackage' },
  ];
  const rastersCheckboxes: CheckboxItem[] = [
    { type: 'Raster', value: 'geotiff', label: 'GeoTIFF' },
    // { type: 'Raster', value: 'png', label: 'PNG' },
  ];

  return (
    <FormGroup>
      <Typography variant="subtitle1" sx={{ ml: 1 }}>
        Vector Formats
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          '@media (max-width: 600px)': {
            flexDirection: 'column',
          },
        }}
      >
        {' '}
        {vectorsCheckboxes.map(({ value, label, type }) => (
          <FormControlLabel
            key={value}
            style={{ marginRight: '0px', marginLeft: '5px' }}
            control={
              <Checkbox
                checked={output_vector_formats.includes(value)}
                onChange={() => handleCheckboxChange(value, type)}
              />
            }
            label={label}
          />
        ))}
      </Box>

      {/* <Divider /> */}
      {layers_3D[0] === 'vectors&rasters' && (
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          Raster Formats
        </Typography>
      )}
      {layers_3D[0] === 'vectors&rasters' && (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {rastersCheckboxes.map(({ value, label, type }) => (
            <FormControlLabel
              sx={{ ml: 1 }}
              key={value}
              control={
                <Checkbox
                  checked={output_raster_formats.includes(value)}
                  onChange={() => handleCheckboxChange(value, type)}
                />
              }
              label={label}
            />
          ))}
        </Box>
      )}
    </FormGroup>
  );
};
export default GroupedSelect;
