import { apiCallWithToken } from '../components/apiCallWithToken';

export const getAllUsers = async (queryParams: string) => {
  try {
    const response = await apiCallWithToken(`/admin/users${queryParams}`);

    return response;
  } catch (error) {
    console.log('Error fetching users:', error);
    return [];
  }
};
export const changeAttributes = async (
  firstName: string,
  lastName: string,
  country: string,
) => {
  try {
    const response = await apiCallWithToken('/users/me/attributes', 'PATCH', {
      first_name: firstName,
      last_name: lastName,
      country: country,
    });
    return response;
  } catch (error) {
    console.error('Error updating attributes:', error);
    return { data: null, error: 'Error updating attributes.' };
  }
};

export const changePassword = async (
  current_password: string,
  new_password: string,
  user_name: string,
) => {
  try {
    const response = await apiCallWithToken('/users/me/password', 'PATCH', {
      current_password: current_password,
      new_password: new_password,
      user_name: user_name,
    });
    return response;
  } catch (error: any) {
    console.error('Error updating password:', error);
    return { data: null, error: 'Error updating password.' };
  }
};

export const getUserById = async (id: number) => {
  try {
    const response = await apiCallWithToken(`/users/${id}`);
    return response;
  } catch (error: any) {
    console.log('Error fetching user:', error);
    return {};
  }
};

export const creditAccount = async (
  id: string | undefined,
  amount: number | undefined,
) => {
  try {
    const response = await apiCallWithToken(
      `/admin/users/${id}/credit`,
      'POST',
      {
        source: 'ADMIN',
        amount: amount,
      },
    );
    return response;
  } catch (error: any) {
    console.log('Error:', error);
    return {};
  }
};
export const debitAccount = async (
  id: string | undefined,
  amount: number | undefined,
) => {
  try {
    const response = await apiCallWithToken(
      `/admin/users/${id}/debit`,
      'PATCH',
      {
        source: 'ADMIN',
        amount: amount,
        order_id: 'string',
      },
    );
    return response;
  } catch (error: any) {
    console.log('Error:', error);
    return {};
  }
};
export const confirmAccount = async (id: string | undefined) => {
  try {
    const response = await apiCallWithToken(
      `/admin/users/${id}/confirm`,
      'PATCH',
    );
    return response;
  } catch (error: any) {
    console.log('Error:', error);
    return {};
  }
};

export const disableAccount = async (id: string | undefined) => {
  try {
    const response = await apiCallWithToken(
      `/admin/users/${id}/disable`,
      'PATCH',
    );
    return response;
  } catch (error: any) {
    console.log('Error:', error);
    return {};
  }
};
export const enableAccount = async (id: string | undefined) => {
  try {
    const response = await apiCallWithToken(
      `/admin/users/${id}/enable`,
      'PATCH',
    );
    return response;
  } catch (error: any) {
    console.log('Error:', error);
    return {};
  }
};
export const deleteAccount = async (id: string | undefined) => {
  try {
    const response = await apiCallWithToken(
      `/admin/users/${id}/delete`,
      'DELETE',
    );
    return response;
  } catch (error: any) {
    console.log('Error:', error);
    return {};
  }
};
export const changeGroup = async (
  id: string | undefined,
  groupeName: string,
) => {
  try {
    const response = await apiCallWithToken(
      `/admin/users/${id}/groups/${groupeName}`,
      'PATCH',
    );
    return response;
  } catch (error: any) {
    console.log('Error:', error);
    return {};
  }
};

export const getUser = async (setUser: any, setAdminInfos: any) => {
  const response = await apiCallWithToken('/users/me');
  setUser(response);
  if (response && response.data !== null) {
    if (response.groups && response.groups.includes('Admin')) {
      const adminResponse = await apiCallWithToken('/admin/users/infos');
      setAdminInfos(adminResponse);
    }
  } else {
    console.log('response data null');
  }

  return response;
};

export const getUnconfirmedUser = async () => {
  try {
    const response = await apiCallWithToken(`/admin/users/unconfirmed`);

    return response;
  } catch (error) {
    console.log('Error fetching users:', error);
    return [];
  }
};
