/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
} from 'react';
import { Dispatch, SetStateAction, useEffect } from 'react';
import * as Cesium from 'cesium';

import { apiCallWithToken } from '../../apiCallWithToken';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { useDialog } from '../../apiCallWithToken/dialogContext';
interface AppContextType {
  polygon: any[] | null;
  area: number;
  cameraView: any;
  cost: number;
  savedArea: number;
  savedCost: number;
  savedPolygon: any[];
  wkt_aoi_to_send: any[];
  isDrawing: boolean;
  file: any;
  drawType: string;
  files: any[];
  viewerRef: any;
  formDataValues: any;
  isRecapModalOpen: boolean;
  isMapLoading: boolean;
  lastData: string;
  cesiumGlobe: any;
  results: any[];
  layersDisplayed: any[];
  fileContent: any;
  isLeftMenuOpen: boolean;
  setLeftMenuOpen: Dispatch<SetStateAction<boolean>>;
  setLayersDisplayed: Dispatch<SetStateAction<any[]>>;
  createPolygon: (polygon: any[]) => void;
  deletePolygon: () => void;
  setCost: (cost: number) => void;
  setAoi: (aoi: []) => void;
  setAreaToPolygon: (area: number) => void;
  setPolygonCost: (area: number) => void;
  changeCameraView: (view: any) => void;
  startDrawing: (type: string) => void;
  stopDrawing: () => void;
  uploadFile: (file: any) => void;
  removeFile: () => void;
  reUploadFile: () => void;
  uploadFiles: (data: any) => void;
  removeFiles: () => void;
  updateCesiumGlobe: Dispatch<SetStateAction<any>>;
  handleCheckboxChange: (value: string, type: string) => void;
  handle2Dlayerschange: (value: string) => void;
  handle3Dlayerschange: (value: string) => void;
  changeImagerySource: (value: string) => void;
  changeProjectName: (name: string) => void;
  changeExtractionType: (name: string, type?: string) => void;
  setWktAoi: (aoi: string) => void;
  setInputCog: (input: string) => void;
  setAwsSecret: (input: string) => void;
  setAwsAccess: (input: string) => void;
  setMapLoading: Dispatch<SetStateAction<boolean>>;
  setResults: Dispatch<SetStateAction<any[]>>;
  setRecapModalOpen: Dispatch<SetStateAction<boolean>>;
  setFileContent: (file: any) => void;
  setFormDataValues: (data: any) => void;
  setOutputSrs: (input: number) => void;
  projectName: string;
  line: any;
  setLine: (data: any) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const DashboardContext: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [isRecapModalOpen, setRecapModalOpen] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [isMapLoading, setMapLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const viewerRef = useRef<any>();
  const [polygon, setPolygon] = useState<any[]>([]);
  const [wkt_aoi_to_send, setWkt_aoi_to_send] = useState<any[]>([]);
  const [savedPolygon, setSavedPolygon] = useState<any[]>([]);
  const [area, setArea] = useState(0);
  const [cost, setCost] = useState(0);
  const [savedArea, setSavedArea] = useState(0);
  const [savedFile, setSavedFile] = useState<any>(null);
  const [savedCost, setSavedCost] = useState(0);
  const [results, setResults] = useState<any[]>([]);
  const [cameraView, setCameraView] = useState(null);
  const [layersDisplayed, setLayersDisplayed] = useState<any[]>([]);
  const [lastData, setLastData] = useState('');
  const [isLeftMenuOpen, setLeftMenuOpen] = useState(false);
  const [cesiumGlobe, updateCesiumGlobe] = useState<any>(null);
  const { setShowDialog } = useDialog();
  const [fileContent, setFileContent] = useState<File | null>(null);
  const [projectName, setProjectName] = useState('');
  const [drawType, setDrawType] = useState('');
  const [line, setLine] = useState(null);

  const [formDataValues, setFormDataValues] = useState<any>({
    resolution: 0.5,
    extraction_type: '2D',
    output_srs: 3857,
    project_name: '',
    layers_2D: ['building', 'tree', 'road'],
    layers_3D: [],
    output_raster_formats: ['geotiff'],
    output_vector_formats: ['shapefile'],
    file: '',
    imagery_source: 'mapbox',
    wkt_aoi: '',
    input_cog_uri: '',
    aws_secret_access_key: '',
    aws_access_key_id: '',
  });

  const startDrawing = (type: string) => {
    if (viewerRef.current) {
      const viewer = viewerRef.current.cesiumElement;
      viewer.dataSources.removeAll();
    }
    setDrawType(type);
    setIsDrawing(true);
    setWkt_aoi_to_send([]);
    setPolygon([]);
    setCost(0);
    setArea(0);
  };

  const getNewCost = async () => {
    setMapLoading(true);
    const formData = new FormData();
    for (const key in formDataValues) {
      formData.append(key, formDataValues[key]);
    }
    const response = await tokenWrapper(() =>
      apiCallWithToken('/cost', 'POST', formData, 'multipart/form-data'),
    );
    if (response === null) {
      setShowDialog(true);
    }
    setMapLoading(false);

    setArea(response?.area_size);
    setCost(response?.cost);
  };

  const setInputCog = (input: string) => {
    setFormDataValues((prevValues: any) => ({
      ...prevValues,
      input_cog_uri: input,
    }));
  };

  const setOutputSrs = (input: number) => {
    setFormDataValues((prevValues: any) => ({
      ...prevValues,
      output_srs: input,
    }));
  };

  const setAwsSecret = (input: string) => {
    setFormDataValues((prevValues: any) => ({
      ...prevValues,
      aws_secret_access_key: input,
    }));
  };

  const setAwsAccess = (input: string) => {
    setFormDataValues((prevValues: any) => ({
      ...prevValues,
      aws_access_key_id: input,
    }));
  };

  const stopDrawing = () => {
    setDrawType('');
    setIsDrawing(false);
  };

  const reUploadFile = () => {
    setFile(savedFile);
  };

  const uploadFile = (newFile: any) => {
    setLastData('file');
    setFile(newFile);
    setSavedFile(newFile);
  };

  const removeFile = () => {
    setFile(null);
  };

  const uploadFiles = (data: any[]) => {
    setFiles([...files, ...data]); // Add new files to existing files
  };

  const removeFiles = () => {
    setFiles([]);
  };

  const setWktAoi = (aoi: string) => {
    setFormDataValues((prevValues: any) => ({
      ...prevValues,
      wkt_aoi: aoi,
    }));
  };

  const changeImagerySource = (source: string) => {
    setFormDataValues((prevValues: any) => ({
      ...prevValues,
      imagery_source: source,
    }));
  };
  const changeProjectName = (name: string) => {
    setProjectName(name);
  };
  const changeExtractionType = (name: any, type?: string) => {
    if (name === '2D') {
      setFormDataValues((prevValues: any) => ({
        ...prevValues,
        extraction_type: '2D',
        layers_2D: ['building', 'tree', 'road'],
        layers_3D: [],
      }));
    } else if (name === '3D,vectors') {
      setFormDataValues((prevValues: any) => ({
        ...prevValues,
        extraction_type: '3D',
        layers_2D: [],
        layers_3D: ['vectors'],
      }));
    } else {
      setFormDataValues((prevValues: any) => ({
        ...prevValues,
        extraction_type: '3D',
        layers_2D: [],
        layers_3D: ['vectors&rasters'],
      }));
    }
  };

  useEffect(() => {
    if (formDataValues.wkt_aoi === '' && file === null) {
      return;
    }
    getNewCost();
  }, [formDataValues]);

  const handle3Dlayerschange = (value: string) => {
    if (value === 'vectors') {
      setFormDataValues((prevValues: any) => ({
        ...prevValues,
        layers_3D: [value],
      }));
    } else {
      setFormDataValues((prevValues: any) => ({
        ...prevValues,
        layers_3D: [value],
      }));
    }
  };

  const handle2Dlayerschange = (value: string) => {
    const { layers_2D } = formDataValues;
    if (layers_2D.includes(value)) {
      if (layers_2D.length === 1) {
        return;
      }
      setFormDataValues((prevValues: any) => ({
        ...prevValues,
        layers_2D: layers_2D.filter((item: any) => item !== value),
      }));
    } else {
      setFormDataValues((prevValues: any) => ({
        ...prevValues,
        layers_2D: [...layers_2D, value],
      }));
    }
  };

  const handleCheckboxChange = (value: string, type: string) => {
    const { output_raster_formats, output_vector_formats } = formDataValues;
    if (type === 'Raster') {
      if (output_raster_formats.includes(value)) {
        if (output_raster_formats.length === 1) {
          return;
        }
        setFormDataValues((prevValues: any) => ({
          ...prevValues,
          output_raster_formats: output_raster_formats.filter(
            (item: any) => item !== value,
          ),
        }));
      } else {
        setFormDataValues((prevValues: any) => ({
          ...prevValues,
          output_raster_formats: [...output_raster_formats, value],
        }));
      }
      return;
    }
    if (output_vector_formats.includes(value)) {
      if (output_vector_formats.length === 1) {
        return;
      }
      setFormDataValues((prevValues: any) => ({
        ...prevValues,
        output_vector_formats: output_vector_formats.filter(
          (item: any) => item !== value,
        ),
      }));
    } else {
      setFormDataValues((prevValues: any) => ({
        ...prevValues,
        output_vector_formats: [...output_vector_formats, value],
      }));
    }
  };

  const createPolygon = (polygon: any[]) => {
    setLastData('polygon');
    setPolygon(polygon);
    if (polygon.length > 2) {
      setSavedPolygon(polygon);
    }
  };

  const setAreaToPolygon = (area: number) => {
    setArea(area);
    setSavedArea(area);
  };

  const changeCameraView = (view: any) => {
    setCameraView(view);
  };

  const setPolygonCost = (cost: number) => {
    setCost(cost);
    setSavedCost(cost);
  };

  const setAoi = (aoi: []) => {
    if (aoi) {
      setWkt_aoi_to_send(aoi);
    }
  };

  const deletePolygon = () => {
    if (viewerRef.current) {
      const viewer = viewerRef.current.cesiumElement;
      viewer.dataSources.removeAll();
      viewerRef.current?.cesiumElement?.entities?.removeAll();
      viewerRef.current?.cesiumElement?.screenSpaceEventHandler?.removeInputAction(
        Cesium.ScreenSpaceEventType.MOUSE_MOVE,
      );
    }
    setPolygon([]);
    setWkt_aoi_to_send([]);
    setArea(0);
    removeFile();
  };

  return (
    <AppContext.Provider
      value={{
        cost,
        wkt_aoi_to_send,
        lastData,
        projectName,
        area,
        drawType,
        polygon,
        savedPolygon,
        savedArea,
        savedCost,
        cameraView,
        setFileContent,
        file,
        files,
        isDrawing,
        formDataValues,
        isMapLoading,
        isRecapModalOpen,
        viewerRef,
        cesiumGlobe,
        results,
        layersDisplayed,
        isLeftMenuOpen,
        setOutputSrs,
        setLayersDisplayed,
        changeCameraView,
        setAoi,
        setPolygonCost,
        createPolygon,
        deletePolygon,
        setAreaToPolygon,
        uploadFile,
        removeFile,
        startDrawing,
        stopDrawing,
        setWktAoi,
        uploadFiles,
        removeFiles,
        handleCheckboxChange,
        changeImagerySource,
        changeExtractionType,
        changeProjectName,
        handle2Dlayerschange,
        handle3Dlayerschange,
        setInputCog,
        setAwsSecret,
        setAwsAccess,
        setRecapModalOpen,
        setMapLoading,
        fileContent,
        reUploadFile,
        setFormDataValues,
        setCost,
        updateCesiumGlobe,
        setResults,
        setLeftMenuOpen,
        line,
        setLine,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error(
      'useDashboardContext must be used within a DashboardContextProvider',
    );
  }
  return context;
};
