import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import axios from 'axios';
// import { usePolygon } from '../PolygonContext';
import { useDashboardContext } from '../DashboardContext';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
interface FileUploadModalProps {
  open: boolean;
  onClose: () => void;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({ open, onClose }) => {
  // const [selectedType, setSlectedType] = useState('KML/KMZ');

  const {
    uploadFile,
    setAreaToPolygon,
    formDataValues,
    setPolygonCost,
    fileContent,
    setFileContent,
  } = useDashboardContext();

  // React.DragEvent<HTMLDivElement>
  const handleFileDrop = async (e: any, selectedFile: any) => {
    e.preventDefault();
    setFileContent(selectedFile);

    uploadFile(selectedFile);
    onClose();
    return;
  };

  // const handleFileType = async (filename: string, file: any) => {
  //   const positions = Cartesian3.fromDegreesArray([
  //     -108.0, 42.0, -100.0, 42.0, -104.0, 40.0,
  //   ]);
  //   switch (selectedType) {
  //     case 'KML/KMZ':
  //       return <KmlDataSource key={filename} data={file} />;
  //     case 'GeoJSON':
  //       const formData = new FormData();
  //       formData.append('file', file);
  //       const positions = await requestpositions(formData);
  //       console.log('positions', positions);
  //       return (
  //         <Entity name="PolygonGraphics" description="PolygonGraphics!!">
  //           <PolygonGraphics
  //             hierarchy={Cartesian3.fromDegreesArray(positions.coordinates)}
  //             material={Color.GREEN}
  //           />
  //         </Entity>
  //       );
  //     case 'SHP File':
  //       return <KmlDataSource key={filename} data={file} />;
  //     case 'TAB File':
  //       return <KmlDataSource key={filename} data={file} />;
  //     default:
  //       return <KmlDataSource key={filename} data={file} />;
  //   }
  // };
  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setFileContent(file);

      uploadFile(file);
      onClose();
    }
  };
  const handleDivClick = () => {
    document?.getElementById('fileInput')?.click();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Upload Area of Interest (AOI)</DialogTitle>
      <Divider />
      <DialogContent>
        <Divider />

        <div
          onClick={handleDivClick}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();

            const selectedFiles = e.dataTransfer.files[0];
            handleFileDrop(e, selectedFiles);
          }}
          style={{
            border: '2px dashed #206294',
            padding: '20px',
            textAlign: 'center',
          }}
        >
          <input
            id="fileInput"
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileInputChange}
          />
          {fileContent ? (
            <div>
              <InsertDriveFileIcon sx={{ fontSize: 62 }} />
              <pre>{fileContent ? fileContent.name : 'No file content'}</pre>
            </div>
          ) : (
            <div>
              <p>Drop AOI here or click to upload.</p>
              <p>Only KML/KMZ/GEOJSON files allowed.</p>
            </div>
          )}
        </div>
        <Divider sx={{ mt: 2 }} />

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              onChange={(e) => setSlectedType(e.target.value)}
              defaultValue="KML/KMZ"
              id="grouped-select"
              sx={{ height: 37 }}
            >
              <ListSubheader>Single file</ListSubheader>
              <MenuItem value={'KML/KMZ'}>KML/KMZ</MenuItem>
              <MenuItem value={'GeoJSON'}>GeoJSON</MenuItem>
              <ListSubheader>Zipped files</ListSubheader>
              <MenuItem value={'SHP File'}>SHP File</MenuItem>
              <MenuItem value={'TAB File'}>TAB File</MenuItem>
            </Select>
          </FormControl> */}
          <Button variant="outlined" onClick={onClose} style={{ height: 37 }}>
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadModal;
