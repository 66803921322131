import { Table, TableBody } from '@mui/material';
import TableRowLayer from './TableRowLayer';
import Alert from '@mui/material/Alert';
interface IProps {
  order: any;
}

export default function LayerAccordionElement({ order }: IProps) {
  const handleLoadingData = () => {
    const layers = order.layers;
    if (layers === null) {
      return <Alert severity="error">There is an error with the layers.</Alert>;
    }
    return layers?.map((layer: any, i: number) => (
      <TableRowLayer order={order} layer={layer} />
    ));
  };

  return (
    <Table>
      <TableBody>{handleLoadingData()}</TableBody>
    </Table>
  );
}
