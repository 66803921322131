import * as React from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { Box } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
interface SelectedMenuType {
  page: string;
  selectedMenu: number;
  setSelectedMenu: React.Dispatch<React.SetStateAction<number>>;
}
export default function IconMenu(props: SelectedMenuType) {
  const handleMenu = () => {
    const menuAdminItems = [
      { id: 1, icon: <FormatListBulletedIcon />, text: 'Manage Orders' },
      { id: 2, icon: <PersonIcon />, text: 'Manage Users' },
      { id: 3, icon: <PersonAddAltIcon />, text: 'Accept Users' },
      { id: 4, icon: <CreditCardIcon />, text: 'Manage Transactions' },
    ];

    const menuUserItems = [
      { id: 1, icon: <PlaylistPlayIcon />, text: 'Manage your orders' },
      { id: 2, icon: <PersonIcon />, text: 'Update Personal Information' },
      { id: 3, icon: <LockIcon />, text: 'Change Password' },
    ];
    const StyledMenuItem = styled(MenuItem)(({ theme, key }) => ({
      backgroundColor: key === props.selectedMenu ? '#f3f6f9' : 'transparent',
      color:
        key === props.selectedMenu ? theme.palette.primary.main : 'inherit',
      '&:hover': {
        backgroundColor: '#f3f6f9',
        color: theme.palette.primary.main,
      },
    }));

    return (props.page === 'user' ? menuUserItems : menuAdminItems)?.map(
      (x, i) => (
        <StyledMenuItem
          key={x.id}
          onClick={() => props.setSelectedMenu(x.id)}
          selected={x.id === props.selectedMenu}
        >
          <ListItemIcon>
            <StyledIcon icon={x.icon} selected={x.id === props.selectedMenu} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              sx: {
                color:
                  x.id === props.selectedMenu
                    ? (theme) => theme.palette.primary.main
                    : 'inherit',

                wordWrap: 'break-word',
                whiteSpace: 'normal',
                textAlign: 'start',
              },
            }}
          >
            {x.text}
          </ListItemText>
        </StyledMenuItem>
      ),
    );
  };

  const StyledIcon = styled(({ icon, selected, ...props }) =>
    React.cloneElement(icon, props),
  )(({ theme, selected }) => ({
    color: selected ? theme.palette.primary.main : 'inherit',
  }));

  return (
    <Box sx={{ width: 340, maxWidth: '100%', mb: 3, mt: 3 }}>
      <MenuList>{handleMenu()}</MenuList>
    </Box>
  );
}
