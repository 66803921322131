import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableBody,
  Box,
  Paper,
  CircularProgress,
} from '@mui/material';
import Row from './Row';
import { useCallback, useEffect, useState } from 'react';
import { deleteOrder } from '../../../api/ordersApi';

const ProcessingTable = (props: any) => {
  const [orderList, setOrderList] = useState(props?.userOrders);

  let deletedOrderList: any = [];
  useEffect(() => {
    setOrderList(props.userOrders);
  }, [props.userOrders]);
  const handleDeleteOrder = useCallback(
    async (id?: string | undefined, userId?: string | undefined) => {
      try {
        await deleteOrder(id, userId);

        deletedOrderList.push(id);
        const filteredList = orderList.filter(
          (e: any) => !deletedOrderList.includes(e['SK'].split('#')[1]),
        );

        setOrderList(filteredList);
      } catch (error) {
        console.error('Error: ', error);
      }
    },
    [],
  );
  return (
    <Paper
      elevation={3}
      sx={{ width: '100%', overflowX: 'auto', height: '90%' }}
    >
      <Box
        ref={props.tableContainerRef}
        style={{
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Table style={{ maxHeight: '100%' }}>
          <TableHead
            style={{
              top: 0,
              zIndex: 1,
              backgroundColor: 'white',
              height: '100%',
            }}
          >
            <TableRow>
              <TableCell />
              <TableCell>
                <Typography fontSize={14} sx={{ color: '#223354' }}>
                  Order ID
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={14} sx={{ color: '#223354' }}>
                  State
                </Typography>
              </TableCell>

              <TableCell>
                <Typography fontSize={14} sx={{ color: '#223354' }}>
                  Progress
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={14} sx={{ color: '#223354' }}>
                  Name
                </Typography>
              </TableCell>

              <TableCell>
                <Typography fontSize={14} sx={{ color: '#223354' }}>
                  Output
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={14} sx={{ color: '#223354' }}>
                  Download
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize={14} sx={{ color: '#223354' }}>
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={props?.tableBodyRef}>
            {orderList?.length > 0 ? (
              orderList?.map((e: any, id: any) => (
                <Row
                  row={e}
                  id={id}
                  page={'user'}
                  key={id}
                  handleDeleteOrder={handleDeleteOrder}
                />
              ))
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={16}
                    sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}
                  >
                    <Box sx={{ m: 1 }}>No data available in table</Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </TableBody>
          {props.lastEvaluatedKey !== null && (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={16}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress sx={{ height: 10, m: 1 }} size={20} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Box>
    </Paper>
  );
};
export default ProcessingTable;
