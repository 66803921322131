import { Button, Box, Divider, TextField, Stack } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useDashboardContext } from '../DashboardContext';
import Grid from '@mui/material/Grid';

const Checkout = () => {
  const {
    changeProjectName,
    formDataValues,
    setRecapModalOpen,
    setFormDataValues,
    projectName,
  } = useDashboardContext();

  return (
    <Stack direction="column">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          <TextField
            sx={{ width: '100%' }}
            size="small"
            placeholder="Custom Order Name"
            value={projectName}
            onChange={(e) => changeProjectName(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            sx={{ width: '100%' }}
            size="small"
            variant="contained"
            disabled={!Boolean(projectName)}
            onClick={() => {
              setFormDataValues((prevValues: any) => ({
                ...prevValues,
                project_name: projectName,
              }));
              setRecapModalOpen(true);
            }}
          >
            <PlayArrowIcon />
            Start
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Checkout;
