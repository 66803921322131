import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider/Divider';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
} from '@mui/material';
import BasicSelect from './BasicSelect';
import HelpIcon from '@mui/icons-material/Help';
import { useDashboardContext } from '../DashboardContext';
// import { usePolygon } from '../PolygonContext';
import { LightTooltip } from '../../Tools/Tools';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const TooltipContent = () => {
  return (
    <Box sx={{ padding: 1, color: '#a7a7a7' }}>
      <Box sx={{ padding: 1 }}>Imagery Source Explained</Box>
      <Divider />
      <Box sx={{ padding: 1 }}>
        <Box sx={{ color: 'black' }}>Default</Box>
        <Box>- Mapbox: Vivid & aerial</Box>
        <Box>- Vivid: SecureWatch Vivids</Box>
        <Box>- Own: Use your own imagery</Box>
      </Box>
      <Box sx={{ padding: 1 }}>
        <Box sx={{ color: 'black' }}>Own imagery</Box>
        <Box>
          If you select this option, you need to specify the imagery to use from
          Amazon S3.
        </Box>
      </Box>
    </Box>
  );
};

export default function Process() {
  const {
    changeImagerySource,
    formDataValues,
    changeProjectName,
    setInputCog,
    setAwsSecret,
    setAwsAccess,
    setRecapModalOpen,
  } = useDashboardContext();
  const [awsAccessKey, setAwsAccessKey] = useState<any>({});
  const togglePasswordVisibility = (key: string) => {
    setAwsAccessKey((prevState: any) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  // const { wkt_aoi_to_send } = usePolygon();
  const {
    imagery_source,
    input_cog_uri,
    aws_secret_access_key,

    aws_access_key_id,
  } = formDataValues;
  const options = [
    { value: 'mapbox', label: 'MapBox' },
    { value: 'securewatch', label: 'Maxar / Vivid Basic' },
    { value: 'UserImage', label: 'User Image' },
    // { value: 'bing', label: 'Bing' },
  ];

  const displayCustomImagery = () => {
    if (imagery_source === 'UserImage') {
      return (
        <Box>
          <Typography variant="body2" sx={{ pb: 1 }}>
            Path on S3 of the user image
          </Typography>
          <TextField
            onChange={(e) => setInputCog(e.target.value)}
            sx={{ width: '100%' }}
            size="small"
            value={input_cog_uri}
            placeholder="/<my-bucket>/.../<my-image.tif>"
          />
          <Typography variant="body2" sx={{ paddingBottom: 1, pt: 1 }}>
            AWS access key id{' '}
          </Typography>
          <TextField
            onChange={(e) => setAwsAccess(e.target.value)}
            sx={{ width: '100%' }}
            size="small"
            value={aws_access_key_id}
            type={awsAccessKey['aws_access_key'] ? 'text' : 'password'}
            placeholder="Your AWS access key ID"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => togglePasswordVisibility('aws_access_key')}
                    edge="end"
                  >
                    {awsAccessKey['aws_access_key'] ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="body2" sx={{ paddingBottom: 1, pt: 1 }}>
            AWS secret key{' '}
          </Typography>
          <TextField
            onChange={(e) => setAwsSecret(e.target.value)}
            sx={{ width: '100%' }}
            value={aws_secret_access_key}
            size="small"
            type={awsAccessKey['aws_secret_access'] ? 'text' : 'password'}
            placeholder="Your AWS secret key"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      togglePasswordVisibility('aws_secret_access')
                    }
                    edge="end"
                  >
                    {awsAccessKey['aws_secret_access'] ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      );
    }
  };

  return (
    <Stack spacing={2} direction="column">
      {/* <Box>
        Imagery source
        <LightTooltip title={TooltipContent()}>
          <IconButton>
            <HelpIcon />
          </IconButton>
        </LightTooltip>
      </Box> */}
      {/* <BasicSelect /> */}
      <BasicSelect options={options} onChange={changeImagerySource} />
      {displayCustomImagery()}
      {/* <Box>No custom image selected</Box> */}
    </Stack>
  );
}
