import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useSpring } from '@react-spring/web';
import axios from 'axios';
import { useSnackbar } from '../../SnackbarProvider';
interface VerificationCodeProps {
  onBack: () => void;
}
const VerificationCode = ({ onBack }: VerificationCodeProps) => {
  const [error, setError] = useState<any>(null);
  const [isloading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const formProps = useSpring({
    transform: `translateX(0%)`,
  });
  const handleBack = () => {
    formProps.transform.start({ to: { translateX: '100%' } }).then(onBack);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const url = `${process.env.REACT_APP_API_URL}/verification-code`;
    setLoading(true);

    const body = {
      username: data.get('email'),
      password: data.get('password'),
      confirmation_code: data.get('confirmation_code'),
    };

    axios
      .post(url, body)
      .then((res) => {
        const { detail } = res.data;

        showSnackbar(detail, '#4CAF50');

        setLoading(false);
        handleBack();
      })
      .catch((error) => {
        const { detail } = error.response.data;
        setError(detail);
        showSnackbar(detail, 'tomato');

        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h5">
        Verification Code
      </Typography>
      <Box
        component="form"
        noValidate
        sx={{ m: 2, maxWidth: 600 }}
        onSubmit={handleSubmit}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onFocus={() => setError(null)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="new-password"
          onFocus={() => setError(null)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmation_code"
          label="Password reset code"
          id="confirmation_code"
          autoComplete="confirmation_code"
          onFocus={() => setError(null)}
        />
        {error && <Alert severity="error">{error}</Alert>}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, height: 37 }}
        >
          {isloading ? (
            <CircularProgress sx={{ color: 'white', height: 10 }} size={20} />
          ) : (
            'Submit'
          )}
        </Button>
        <Link href="#" variant="body2" onClick={handleBack}>
          Back to login
        </Link>
      </Box>
    </Box>
  );
};
export default VerificationCode;
