import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { TableCell, TableRow, Grid } from '@mui/material';

import LayersIcon from '@mui/icons-material/Layers';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import CropDinIcon from '@mui/icons-material/CropDin';
import ForestIcon from '@mui/icons-material/Forest';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import HelpIcon from '@mui/icons-material/Help';
import RadioLayer from './RadioLayer';
import TerrainIcon from '@mui/icons-material/Terrain';
import { useDashboardContext } from '../../../DashboardContext';

interface IProps {
  order: any;
  layer: any;
}

interface layersArray {
  value: string;
  icon: any;
}

const layersIcon: layersArray[] = [
  { value: 'building', icon: <MapsHomeWorkIcon fontSize="small" /> },
  { value: 'tree', icon: <ForestIcon fontSize="small" /> },
  { value: 'road', icon: <PowerInputIcon fontSize="small" /> },
  { value: 'DTM', icon: <TerrainIcon fontSize="small" /> },
  { value: 'clutter', icon: <LayersIcon fontSize="small" /> },
  { value: 'AOI', icon: <CropDinIcon fontSize="small" /> },
];

const handleLayers = (layer: string) => {
  const foundElement = layersIcon.find(
    (item) => item.value.toLowerCase() === layer,
  );
  if (foundElement) {
    return foundElement.icon;
  } else {
    return <HelpIcon fontSize="small" />;
  }
};

export default function TableRowLayer({ order, layer }: IProps) {
  // const [radioValue, setRadio] = useState('Hide');

  const { viewerRef } = useDashboardContext();
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setRadio((event.target as HTMLInputElement).value);
  // };

  const createUniqueId = (layer: string, id: string, layerName: string) => {
    return `${id}-${layerName}-${layer}`;
  };

  const gridStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#206294',
  };
  const viewer = viewerRef.current.cesiumElement;
  const _3dTile = viewer.scene.primitives._primitives.find(
    (x: any) => x.id === createUniqueId('3D', order.orderId, layer.layer_name),
  );
  return (
    <TableRow key={layer.layer_name}>
      <TableCell sx={{ padding: '10px' }}>
        <Grid container>
          <Grid item xs={1} sx={gridStyle}>
            {' '}
            {handleLayers(layer.layer_name)}
          </Grid>
          <Grid item xs={4} sx={gridStyle}>
            {' '}
            {layer.display_name}
          </Grid>
          <Grid item xs={7}>
            <RadioLayer
              order={order}
              layer={layer}
              _3dTile={_3dTile}
              // radioValue={radioValue}
              // setRadio={handleChange}
            />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
