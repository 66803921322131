import React, { createContext, useState, useContext, ReactNode } from 'react';
import {
  Button,
  Grid,
  Typography,
  Box,
  Dialog,
  CircularProgress,
} from '@mui/material';
interface ConfirmationModalContextType {
  showConfirmationModal: (text: any, handleConfirm: any) => void;
  hideConfirmationModal: () => void;
}

const ConfirmationModalContext = createContext<
  ConfirmationModalContextType | undefined
>(undefined);

const useConfirmationModal = () => {
  const context = useContext(ConfirmationModalContext);
  if (!context) {
    throw new Error(
      'useConfirmationModal must be used within a ConfirmationModalProvider',
    );
  }
  return context;
};

const ConfirmationModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState<string | undefined>(undefined);
  const [confirmAction, setConfirmAction] = useState<(() => void) | null>(null);
  const [isActionLoading, setActionLoading] = useState(false);
  const showConfirmationModal = (text: string, handleConfirmation: any) => {
    setText(text);
    setConfirmAction(() => handleConfirmation);
    setIsOpen(true);
  };

  const hideConfirmationModal = () => {
    setIsOpen(false);
  };

  const handleConfirm = async () => {
    setActionLoading(true);

    if (confirmAction) {
      await confirmAction();
    }
    setActionLoading(false);
    setIsOpen(false);
  };

  const contextValues: ConfirmationModalContextType = {
    showConfirmationModal,
    hideConfirmationModal,
  };

  return (
    <ConfirmationModalContext.Provider value={contextValues}>
      {children}

      <Dialog maxWidth="xl" open={!!isOpen} onClose={hideConfirmationModal}>
        <Grid
          direction="column"
          container
          p={5}
          spacing={3}
          alignItems={'center'}
          width={'30rem'}
          maxWidth={'30rem'}
        >
          <>
            <Typography fontWeight={'bold'}>Confirmation</Typography>
            <Typography
              sx={{
                m: 1,
                textAlign: 'center',
              }}
            >
              {text}
            </Typography>
            <Grid item>
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button
                  onClick={hideConfirmationModal}
                  variant="contained"
                  sx={{ backgroundColor: '#757575', textTransform: 'none' }}
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleConfirm}
                  variant="contained"
                  sx={{ background: '#2778c4', textTransform: 'none' }}
                >
                  {isActionLoading ? (
                    <CircularProgress
                      sx={{ color: 'white', height: 10 }}
                      size={20}
                    />
                  ) : (
                    'Confirm'
                  )}
                </Button>
              </Box>
            </Grid>
          </>
        </Grid>
      </Dialog>
    </ConfirmationModalContext.Provider>
  );
};

export { ConfirmationModalProvider, useConfirmationModal };
