import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import { useUser } from '../../AuthContext';
import { useSnackbar } from '../../SnackbarProvider';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const UserInformation = () => {
  const { user } = useUser();
  const { showSnackbar } = useSnackbar();
  const handleCopyClick = (event: any, id: any) => {
    event.stopPropagation();

    navigator.clipboard
      .writeText(id)
      .then(() => {
        showSnackbar('User Id copied', '#4CAF50', 'white');
      })
      .catch((err) => {
        showSnackbar('Failed to copy', 'tomato', 'white');

        console.error('Failed to copy: ', err);
      });
  };
  return (
    <Grid maxWidth="100%">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{
            backgroundColor: '#206294',
            borderRadius: 1,
            width: 100,
            height: 100,
          }}
          variant="square"
        >
          {user?.first_name?.charAt(0)}
        </Avatar>

        <Grid ml={2}>
          <Typography variant="h5" sx={{ textAlign: 'start', color: 'black' }}>
            {user?.first_name + ' ' + user?.last_name}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'lightslategray', textAlign: 'start' }}
          >
            {(user && user.groups && user.groups.join(', ')) || ''}
          </Typography>
        </Grid>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Grid sx={{ display: 'flex', mt: 1 }}>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'start',
              marginRight: 1,
              color: 'black',
              flex: '30%',
            }}
          >
            User ID:
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: 'start', wordWrap: 'break-word', flex: '60%' }}
          >
            {user?.sub}
          </Typography>
          <IconButton
            color="primary"
            size="small"
            sx={{ flex: '10%' }}
            onClick={(event) => handleCopyClick(event, user?.sub)}
          >
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid sx={{ display: 'flex', mt: 0.5 }}>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'start',
              marginRight: 1,
              color: 'black',
              flex: '30%',
            }}
          >
            Email:
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'start', flex: '70%' }}>
            {user?.email}
          </Typography>
        </Grid>
        <Grid sx={{ display: 'flex', mt: 1 }}>
          <Typography
            variant="h5"
            sx={{ textAlign: 'start', mr: 1, color: 'black', flex: '30%' }}
          >
            Country:
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'start', flex: '70%' }}>
            {user?.country}
          </Typography>
        </Grid>
        <Grid sx={{ display: 'flex', mt: 1 }}>
          <Typography
            variant="h5"
            sx={{ textAlign: 'start', mr: 1, color: 'black', flex: '30%' }}
          >
            Signup Date:
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'start', flex: '70%' }}>
            {' '}
            {user && user['GSI1-SK']
              ? new Date(user['GSI1-SK']).toLocaleDateString('en-GB')
              : ''}
          </Typography>
        </Grid>
        <Grid sx={{ display: 'flex', mt: 1 }}>
          <Typography
            variant="h5"
            sx={{ textAlign: 'start', mr: 1, color: 'black', flex: '30%' }}
          >
            Max. AOI Size:
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'start', flex: '70%' }}>
            {user?.['area-processed'] + 'km²'}
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
};
export default UserInformation;
