import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableBody,
  Box,
  Paper,
  Avatar,
  Grid,
  CircularProgress,
  IconButton,
} from '@mui/material';

import useTransactions from './useTransactions';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LinearProgress from '@mui/material/LinearProgress';

const TransactionsTable = () => {
  const {
    columns,
    isLoading,
    transactionsArray,
    handleCopyClick,

    tableContainerRef,
    tableBodyRef,
    lastEvaluatedKey,
  } = useTransactions();

  return isLoading ? (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  ) : (
    <Paper
      elevation={3}
      sx={{ width: '100%', overflowX: 'auto', height: '100%' }}
    >
      <Box
        ref={tableContainerRef}
        style={{
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Table style={{ maxHeight: '100%' }}>
          <TableHead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'white',
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <Typography
                    fontSize={14}
                    sx={{ color: '#223354', cursor: 'pointer' }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody ref={tableBodyRef}>
            {transactionsArray?.length > 0 ? (
              transactionsArray.map((e) => (
                <TableRow
                  sx={{ cursor: 'pointer' }}
                  hover
                  key={`${e?.sub}${e['GSI1-SK']}`}
                >
                  <TableCell>
                    {e?.sub}

                    {e['is_user_deleted'] === true ? null : (
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={(event) => handleCopyClick(event, e?.sub)}
                      >
                        <FileCopyIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: '#206294',
                        backgroundColor: '#E1F0FF',
                        fontSize: 'small',
                        padding: '2px 5px',
                        borderRadius: '5px',
                        display: 'inline-grid',
                      }}
                    >
                      {e?.type}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: '#EBEDF3',
                          color: '#71BF49',
                          borderRadius: 1,
                          width: 40,
                          height: 40,
                          fontSize: 14,
                        }}
                      >
                        {e?.first_name?.charAt(0)}
                      </Avatar>

                      <Grid ml={2}>
                        <Typography
                          sx={{
                            textAlign: 'start',
                            color: 'black',
                          }}
                        >
                          {e['is_user_deleted'] === true
                            ? 'User deleted'
                            : e.first_name + ' ' + e.last_name}{' '}
                        </Typography>
                        <Typography
                          sx={{
                            color: 'lightslategray',
                            textAlign: 'start',
                            fontSize: 'small',
                          }}
                        >
                          {e?.email}
                        </Typography>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={'small'}>{e?.amount}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={'small'}>
                      {new Date(e['GSI1-SK']).toLocaleDateString('en-GB')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={'small'}>{e?.processId}</Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={16}
                  sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}
                >
                  <Box sx={{ m: 1 }}>No data available in table</Box>
                </TableCell>
              </TableRow>
            )}
            {lastEvaluatedKey !== null && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress sx={{ height: 10, m: 1 }} size={20} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export default TransactionsTable;
