/* eslint-disable react-hooks/exhaustive-deps */
import {
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  TableBody,
  Box,
  Button,
  Typography,
  Avatar,
  Grid,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import WarningIcon from '@mui/icons-material/Warning';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CircularWithValueLabel from '../CircularWithValueLabel';
import { apiCallWithToken } from '../../../apiCallWithToken';
import { useDashboardContext } from '../../../Dashboard/DashboardContext';
import { tokenWrapper } from '../../../apiCallWithToken/tokenWrapper';
import { useDialog } from '../../../apiCallWithToken/dialogContext';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSnackbar } from '../../../SnackbarProvider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PendingIcon from '@mui/icons-material/Pending';
import { useNavigate } from 'react-router-dom';
import { createItemToDisplay } from '../../../Shared';
import { useConfirmationModal } from '../../../ConfirmationModalProvider';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MapIcon from '@mui/icons-material/Map';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useUser } from '../../../AuthContext';
const Row = ({ row, id, page, handleDeleteOrder }: any) => {
  const [progress] = useState(row['progress']);
  const [status] = useState(row['GSI2-PK']?.split('#')[1]);
  const { setShowDialog } = useDialog();
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const { results, setResults } = useDashboardContext();
  const { user } = useUser();
  const { showConfirmationModal } = useConfirmationModal();
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();
  const handleCopyClick = (event: any, id: any) => {
    event.stopPropagation();

    navigator.clipboard
      .writeText(id)
      .then(() => {
        showSnackbar('Order Id copied', '#4CAF50', 'white');
      })
      .catch((err) => {
        showSnackbar('Failed to copy', 'tomato', 'white');

        console.error('Failed to copy: ', err);
      });
  };

  const displayToCesium = async () => {
    setIsLoading(true);
    const userId = row['sub'];
    const orderId = row['SK'].split('#')[1];
    const response = await tokenWrapper(() =>
      apiCallWithToken(`/users/${userId}/orders/${orderId}/details`),
    );
    if (response === null) {
      setShowDialog(true);
    }
    let res = [...results];

    const item = await createItemToDisplay(userId, orderId);

    res.unshift(item);
    setResults(res);

    navigate('/dashboard', {
      state: {
        initialAccordionOpen: true,
        orderId: row['SK'].split('#')[1],
      },
    });
    setIsLoading(false);
    showSnackbar('Layer added to the map', '#4CAF50', 'white');
  };

  const handleDeleteConfirmation = (order: any) => {
    showConfirmationModal(
      <Box>
        <Typography>
          Are you sure you really want to delete the order:
        </Typography>
        <Typography sx={{ fontWeight: 'bold' }}>
          {order?.params?.name}
        </Typography>
        <Typography sx={{ fontWeight: 'bold' }}>
          {order['SK'].split('#')[1]}
        </Typography>
        <Typography>
          All data associated with this order will be definitively deleted
        </Typography>
      </Box>,

      () => handleDeleteOrder(order['SK'].split('#')[1], order?.sub),
    );
  };

  const formatDate = (inputDate: string) => {
    const dateObj = new Date(inputDate);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getUTCHours()).padStart(2, '0');
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getUTCSeconds()).padStart(2, '0');
    const reformattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return reformattedDate;
  };

  const formatState = (state: string) => {
    const style = {
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      padding: '4px 9px',
      borderRadius: 5,
      justifyContent: 'center',
      width: '7rem',
    };
    switch (state) {
      case 'CREATED':
        return (
          <Chip
            label={'RUNNING'}
            style={{ width: '8.3rem' }}
            onDelete={() => { }}
            deleteIcon={<HourglassBottomIcon />}
          />
        );
      case 'FAILED':
        return (
          <Box sx={[style, { bgcolor: 'tomato' }]}>
            FAILED <WarningIcon sx={{ color: 'white' }} />
          </Box>
        );
      case 'SUCCEEDED':
        return (
          <Box sx={[style, { bgcolor: 'limegreen' }]}>
            SUCCEEDED <DoneAllIcon sx={{ color: 'white' }} />
          </Box>
        );
      case 'PENDING':
        return (
          <Chip
            label={state}
            style={{ width: '8.3rem' }}
            onDelete={() => { }}
            deleteIcon={<PendingIcon />}
          />
        );
      default:
        break;
    }
  };
  const submitTime = new Date(formatDate(row['GSI1-SK'])).getTime();

  const startTime = new Date(formatDate(row['completed'])).getTime();

  const durationInMilliseconds = submitTime - startTime;
  const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor(
    (durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
  );
  const otherData = [
    { name: 'Submit Time', data: formatDate(row['GSI1-SK']) },
    {
      name: 'Start Time',
      data: row['completed'] ? formatDate(row['completed']) : '-',
    },
    { name: 'Duration', data: row['completed'] ? `${hours}:${minutes}` : '-' },
    {
      name: 'Type',
      data: row && row.params ? row.params.type : '-',
    },
    {
      name: 'Area',
      data: row['area'],
    },
    {
      name: 'Cost',
      data: row['cost'],
    },
    {
      name: 'Resolution',
      data: row && row.params ? row.params.resolution : '-',
    },

    {
      name: 'Raster Formats',
      srs: 'RasterFormats',
      data:
        row && row.params && row.params.formats
          ? row.params.formats.raster.join(', ')
          : '-',
    },
    {
      name: ' Vector Formats',
      srs: 'VectorFormats',
      data:
        row && row.params && row.params.formats
          ? row.params.formats.vector.join(', ')
          : '-',
    },
  ];
  const commonDisplay = (
    <Button
      // fullWidth={true}
      disabled={
        status !== 'SUCCEEDED' ||
        results.some((x: any) => x.orderId === row['SK'].split('#')[1])
      }
      variant="outlined"
      onClick={() => displayToCesium()}
      startIcon={!isLoading && <MapIcon />}
    >
      {isLoading ? <CircularProgress size={22} /> : 'Display'}
    </Button>
  );

  const handleDownload = async () => {
    // console.log('user', user.gr);
    let user_id = 'me';
    if (user?.groups.includes('Admin')) {
      user_id = row['sub'];
    }
    setDownloadLoading(true);
    const orderId = row['SK'].split('#')[1];
    const response = await tokenWrapper(() =>
      apiCallWithToken(`/users/${user_id}/orders/${orderId}/download?single_result=true`),
    );
    console.log("resppp", response);
    if (response && response.download) {
      const anchorElement = document.createElement('a');
      anchorElement.href = response.download[`${orderId}.zip`];
      anchorElement.setAttribute('download', `${orderId}.zip`);
      anchorElement.click();
      setDownloadLoading(false);
    }
    else if (response && response.deeplink) {
      window.open(response.deeplink, '_blank');
      setDownloadLoading(false);
      return;
    }
    else {
      console.log('Failed to download');
      showSnackbar('This order cannot be downloaded', 'tomato', 'white');
      setDownloadLoading(false);
    }
  };

  const commonDownload = (
    <Button
      onClick={() => handleDownload()}
      disabled={status !== 'SUCCEEDED'}
      variant="outlined"
      startIcon={!isDownloadLoading && <CloudDownloadIcon />}
    >
      {isDownloadLoading ? <CircularProgress size={22} /> : 'Download'}
    </Button>
  );
  const commonDelete = (
    <Button
      variant="outlined"
      onClick={() => handleDeleteConfirmation(row)}
      startIcon={<DeleteOutlineIcon />}
    >
      Delete
    </Button>
  );
  const userField = {
    name: 'User',
    data: (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{
            backgroundColor: '#EBEDF3',
            color: '#71BF49',
            borderRadius: 1,
            width: 40,
            height: 40,
            fontSize: 14,
          }}
        >
          {row?.first_name?.charAt(0)}
        </Avatar>

        <Grid ml={2}>
          <Typography
            sx={{
              textAlign: 'start',
              color: 'black',
            }}
          >
            {row['is_user_deleted'] === true
              ? 'User deleted'
              : row?.first_name + ' ' + row?.last_name}{' '}
          </Typography>
          <Typography
            sx={{
              color: 'lightslategray',
              textAlign: 'start',
              fontSize: 'small',
            }}
          >
            {row?.email}
          </Typography>
        </Grid>
      </Box>
    ),
  };
  const rowFields = [
    { name: 'id', data: row['SK'].split('#')[1] },
    { name: 'State', data: formatState(row['GSI2-PK']?.split('#')[1]) },
    {
      name: 'Progress',
      data: <CircularWithValueLabel progress={row.progress} />,
    },
    { name: 'Name', data: row?.params?.name || '-' },
    { srs: 'Display', data: commonDisplay },
    { srs: 'Download', data: commonDownload },
    { srs: 'Action', data: commonDelete },
  ];

  const rowData = rowFields.map((field) => ({
    ...field,
    data:
      field.name === 'id' ? (
        <>
          {field.data}
          <IconButton
            color="primary"
            size="small"
            onClick={(event) => handleCopyClick(event, field.data)}
          >
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
        </>
      ) : (
        field.data
      ),
  }));

  const rowAdminData = [rowData[0], userField, ...rowData.slice(1)];
  const rowUserData = [...rowData];

  const rowDataFinal = page === 'user' ? rowUserData : rowAdminData;
  return (
    <>
      <TableRow key={id}>
        <TableCell>
          <IconButton
            onClick={() => setExpanded(!expanded)}
            aria-expanded={expanded}
            aria-label="show more"
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
        {rowDataFinal.map((x, i) => (
          <TableCell key={i}>
            {x.name === 'Name' ? (
              <Tooltip title={x.data}>
                <Typography
                  variant="h6"
                  sx={{
                    wordWrap: 'break-word',
                    maxWidth: '130px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {x.data}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                variant="body1"
                sx={{ wordWrap: 'break-word', maxWidth: '300px' }}
              >
                {x.data}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
      {expanded && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0 }} colSpan={12}>
            <Box display="flex" flexDirection="row">
              {otherData?.map((x, i) => (
                <Box key={i} flexGrow={1} textAlign="center">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> {x?.name}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="body1">{x?.data}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              ))}
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
export default Row;
