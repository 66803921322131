import BasicAccordion from '../AccordionDashBoard';
import Area from '../Area';
import Layers from '../Layers';
import Process from '../Process';
import HexagonIcon from '@mui/icons-material/Hexagon';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LogoutIcon from '@mui/icons-material/Logout';
import Checkout from '../Checkout';
import LoginIcon from '@mui/icons-material/Login';

import * as React from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Typography } from '@mui/material';

interface Params {
  formats: {
    raster: string[];
    vector: string[];
  };
  layers: string[];
  name: string;
  resolution: string;
  srs: string;
  type: string;
}

interface IOrder {
  params: Params;

  id: string;
  userId: string;
  state: string;
}

export default function StartProcessAccordion({
  onOpenModal,
  setModalContent,
  setModalTitle,
  setModalIcon,
}: any) {
  const [accordionOpen, setAccordionOpen] = React.useState(false);

  const displayAccordion = () => {
    const accordionElements = [
      {
        icon: <HexagonIcon style={{ color: '#9ccf84' }} />,
        title: 'Area of interest',
        component: <Area />,
      },
      {
        icon: <LoginIcon style={{ color: '#9ccf84' }} />,
        title: 'Inputs',
        component: <Process />,
      },
      {
        icon: <LogoutIcon style={{ color: '#9ccf84' }} />,
        title: 'Outputs',
        component: <Layers />,
      },
      {
        icon: <FormatListBulletedIcon style={{ color: '#9ccf84' }} />,
        title: 'Checkout',
        component: <Checkout />,
      },
    ];
    return accordionElements.map((x, i) => (
      <BasicAccordion
        key={i}
        icon={x.icon}
        title={x.title}
        component={x.component}
        onOpenModal={() => onOpenModal(true)}
        setModalContent={setModalContent}
        setModalTitle={setModalTitle}
        setModalIcon={setModalIcon}
      />
    ));
  };

  const handleAccordionOpen = () => {
    setAccordionOpen(true);
  };

  const handleAccordionClose = () => {
    setAccordionOpen(false);
  };

  return (
    <Accordion
      sx={{ width: 430, bgcolor: '#ebecf5' }}
      expanded={accordionOpen}
      onChange={accordionOpen ? handleAccordionClose : handleAccordionOpen}
    >
      <AccordionSummary
        expandIcon={<ExpandLessIcon sx={{ color: '#206294' }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          minHeight: '0px',
        }}
        sx={{
          '.MuiAccordionSummary-content.Mui-expanded': {
            margin: '10px',
            marginTop: '10px',
          },
        }}
      >
        <Typography sx={{ color: '#206294' }}>Process Order</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            overflow: 'auto',
            height: `calc(90vh - 100px)`,
            display: 'flex',
            gap: 1,
            flexDirection: 'column',
          }}
        >
          {displayAccordion()}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
