import BeTc from '../../../assets/Brightearth/BeTc.png';
import BeFc from '../../../assets/Brightearth/BeFc.png';
import BeNdvi from '../../../assets/Brightearth/BeNdvi.png';
import BeWi from '../../../assets/Brightearth/BeWi.png';
import BeCl from '../../../assets/Brightearth/BeCl.png';
import BeClLegend from '../../../assets/Brightearth/BeClLegend.png';
import * as Cesium from 'cesium';

const token = `Bearer ${localStorage.getItem("access_token")}`

type Authorization = {
  Authorization: string;
};

type Imagery = {
  name: string;
  category: string;
  tooltip: string;
  iconUrl: string;
  composer: string | undefined;
  layer: string | undefined;
  headers: Authorization;
  credit: string | undefined;
  hasThreshold: boolean;
  legend: string | undefined;
};

type Overlay = {
  name: string;
  category: string;
  tooltip: string;
  iconUrl: string;
  composer: string;
  layer: string;
  headers: Authorization;
  credit: string | undefined;
  hasThreshold: boolean;
  legend: string | undefined;
  button: null;
};

type Imageries = Imagery[];

type Overlays = Overlay[];

export const imageriesTypes: Imageries = [
  {
    name: 'Mosaic True Colors V4',
    category: 'BrightEarth LuxCarta',
    tooltip: `BrightEarth Sentinel-2 Mosaic (RGB) V4 - 2021`,
    iconUrl: BeTc,
    composer: 'tc',
    layer: 'mosaicv4',
    headers: { Authorization: token },
    credit: 'imageryType.credit || undefined',
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'Mosaic False Colors V4',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth Sentinel-2 Mosaic (IrRG) V4 - 2021',
    iconUrl: BeFc,
    layer: 'mosaicv4',
    composer: 'fc',
    headers: { Authorization: token },
    credit: undefined,
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'Mosaic True Colors V3',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth Sentinel-2 Mosaic (RGB) V3 - 2021',
    iconUrl: BeTc,
    layer: 'mosaicv3',
    composer: 'tc',
    headers: { Authorization: token },
    credit: undefined,
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'Mosaic False Colors V3',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth Sentinel-2 Mosaic (IrRG) V3 - 2021',
    iconUrl: BeFc,
    layer: 'mosaicv3',
    composer: 'fc',
    headers: { Authorization: token },
    credit: undefined,
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'Land Use Land Cover',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth Land Use Land Cover derived from Sentinel-2 Mosaic',
    iconUrl: BeCl,
    layer: 'mosaicv4',
    composer: 'ndvi',
    headers: { Authorization: token },
    credit: 'NDVI - BrightEarth/Luxcarta 2020',
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'Mosaic Water Index V4',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth Sentinel-2 Water Index (WI)',
    iconUrl: BeWi,
    layer: 'mosaicv4',
    composer: 'wi',
    headers: { Authorization: token },
    credit: 'WI - BrightEarth/Luxcarta 2020',
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'Mosaic Vegetation Index V3',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth Sentinel-2 Normalized water index (NDVI)',
    iconUrl: BeNdvi,
    layer: 'mosaicv3',
    composer: 'ndvi',
    headers: { Authorization: token },
    credit: 'NDVI - BrightEarth/Luxcarta 2020',
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'Mosaic Water Index V3',
    category: 'BrightEarth LuxCarta',
    tooltip: 'BrightEarth Sentinel-2 Water Index (WI)',
    iconUrl: BeWi,
    layer: 'mosaicv3',
    composer: 'wi',
    headers: { Authorization: token },
    credit: 'NDVI - BrightEarth/Luxcarta 2020',
    hasThreshold: false,
    legend: undefined,
  },
  {
    name: 'LULC',
    category: 'Layers',
    tooltip: 'BrightEarth Land Use Land Cover derived from Sentinel-2 Mosaic',
    iconUrl: BeCl,
    composer: 'generic',
    layer: 'clutterv2',
    headers: { Authorization: token },
    credit: 'imageryType.credit || undefined',
    hasThreshold: false,
    legend: BeClLegend,
  },
];



// imageryViewModels.push(new Cesium.ProviderViewModel({
//   name: "Earth at Night",
//   iconUrl: Cesium.buildModuleUrl("Widgets/Images/ImageryProviders/blackMarble.png"),
//   tooltip: "The lights of cities and villages trace the outlines of civilization \
// in this global view of the Earth at night as seen by NASA/NOAA's Suomi NPP satellite.",
//   creationFunction: () => {
//     return Cesium.IonImageryProvider.fromAssetId(3812);
//   }
// }));

// imageryViewModels.push(new Cesium.ProviderViewModel({
//   name: "Natural Earth\u00a0II",
//   iconUrl: Cesium.buildModuleUrl("Widgets/Images/ImageryProviders/naturalEarthII.png"),
//   tooltip: "Natural Earth II, darkened for contrast.\nhttp://www.naturalearthdata.com/",
//   creationFunction: () => {
//     return Cesium.TileMapServiceImageryProvider.fromUrl(
//       Cesium.buildModuleUrl("Assets/Textures/NaturalEarthII")
//     );
//   }
// }));