import React, { createContext, useContext, useState, ReactNode } from 'react';

export type User = {
  [key: string]: any;
  // 'GSI2-SK': string;
  // 'GSI1-SK': string;
  // credits: 98682314.47903313;
  // SK: string;
  // 'GSI1-PK': string;
  // 'GSI2-PK': string;
  // PK: string;
  // 'area-processed': 0.62;
  // sub: string;
  // country: string;
  // last_name: string;
  // email_verified: string;
  // company_name: string;
  // first_name: string;
  // email: string;
};

type UserContextType = {
  user: User | null;
  setUser: (userData: User) => void;
  userList: any | null;
  setUserList: (userListData: any) => void;

  // login: (userData: User) => void;
  // logout: () => void;
  isAuthenticated: boolean;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [userList, setUserList] = useState<any | null>(null);

  // const login = (userData: User) => {
  //   setUser(userData);
  // };

  const isAuthenticated = localStorage.getItem('isLogged') === 'true';

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isAuthenticated,
        userList,
        setUserList,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
