import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ButtonGroup from '@mui/material/ButtonGroup';
import DrawIcon from '@mui/icons-material/Draw';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadModal from '../FileUploadModal';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDashboardContext } from '../DashboardContext';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import * as Cesium from 'cesium';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PolylineIcon from '@mui/icons-material/Polyline';
import RectangleIcon from '@mui/icons-material/Rectangle';
export default function Area() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const {
    isDrawing,
    startDrawing,
    stopDrawing,
    deletePolygon,
    viewerRef,
    setFileContent,
    wkt_aoi_to_send,
    setFormDataValues,
    setCost,
  } = useDashboardContext();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [isDrawingMenuOpen, setIsDrawingMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const closeDrawingMenu = () => {
    setIsDrawingMenuOpen(false);
  };

  // const onChange = (e: any) => {
  //   const country = e.target.value;
  //   setValue(country);
  //   if (country.length === 0) {
  //     setSuggestions([]);
  //     return;
  //   }
  //   axios
  //     .get(`https://restcountries.com/v3.1/name/${country}?fields=name,latlng`)
  //     .then((res) => {
  //       setSuggestions(res.data.slice(0, 10));
  //     })
  //     .catch(() => {
  //       setSuggestions([]);
  //     });
  // };

  // const reloadLast = () => {
  //   if (lastData === 'file') {
  //     reUploadFile();
  //   } else {
  //     createPolygon(savedPolygon);
  //   }

  //   setPolygonCost(savedCost);
  //   setAreaToPolygon(savedArea);
  // };

  return (
    <Stack spacing={2} direction="column">
      <FileUploadModal open={modalOpen} onClose={handleCloseModal} />

      <ButtonGroup
        sx={{
          width: '100%',
          '.MuiButtonGroup-lastButton': {
            borderRight: '1px solid rgba(32, 98, 148, 0.5)',
          },
          '.Mui-disabled': {
            border: '1px solid rgba(0, 0, 0, 0.12)',
          },
        }}
      >
        <Button
          onClick={handleOpenModal}
          variant="contained"
          style={{ borderRight: 'inset', width: '100%' }}
        >
          <CloudUploadIcon style={{ marginRight: '4px' }} /> Upload
        </Button>
        {isDrawing ? (
          <Button
            sx={{ width: '100%', bgcolor: 'tomato' }}
            variant="contained"
            onClick={() => {
              stopDrawing();
              deletePolygon();
            }}
          >
            <CloseIcon />
            Cancel
          </Button>
        ) : (
          <>
            <Button
              style={{ borderRight: 'inset', width: '100%' }}
              variant="contained"
              onClick={(event) => {
                setIsDrawingMenuOpen(true);
                setAnchorEl(event.currentTarget);
              }}
            >
              <DrawIcon style={{ marginRight: '4px' }} />
              Draw
            </Button>
            <Menu
              open={isDrawingMenuOpen}
              onClose={closeDrawingMenu}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem
                style={{ color: '#206294' }}
                onClick={() => {
                  closeDrawingMenu();
                  startDrawing('polygon');
                }}
              >
                <PolylineIcon
                  style={{ marginRight: '4px', color: '#206294' }}
                />
                Polygon
              </MenuItem>
              <MenuItem
                style={{ color: '#206294' }}
                onClick={() => {
                  closeDrawingMenu();
                  startDrawing('rectangle');
                }}
              >
                <RectangleIcon
                  style={{ marginRight: '4px', color: '#206294' }}
                />
                Rectangle
              </MenuItem>
            </Menu>
          </>
        )}
        <Button
          variant="contained"
          sx={{
            width: '100%',
            borderRight: 'inset',
            '&.Mui-disabled': {
              backgroundColor: 'transparent',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            },
          }}
          disabled={wkt_aoi_to_send.length === 0}
          onClick={() => {
            const coordinates = wkt_aoi_to_send.map((coord) => {
              const [lon, lat] = coord.split(' ').map(Number);
              return new Cesium.Cartographic(
                Cesium.Math.toRadians(lon),
                Cesium.Math.toRadians(lat),
              );
            });

            const rectangle =
              Cesium.Rectangle.fromCartographicArray(coordinates);

            const viewer = viewerRef.current.cesiumElement;

            viewer.camera.flyTo({
              destination: rectangle,
            });
          }}
        >
          <ZoomInIcon style={{ marginRight: '4px' }} />
          Zoom
        </Button>
        <Button
          sx={{
            width: '100%',
          }}
          disabled={wkt_aoi_to_send.length === 0}
          variant="outlined"
          onClick={() => {
            stopDrawing();
            deletePolygon();
            setFileContent(null);
            setCost(0);
            setFormDataValues({
              resolution: 0.5,
              extraction_type: '2D',
              output_srs: '3857',
              project_name: '',
              layers_2D: ['building', 'tree', 'road'],
              layers_3D: [],
              output_raster_formats: ['geotiff'],
              output_vector_formats: ['shapefile'],
              file: '',
              imagery_source: 'mapbox',
              wkt_aoi: '',
              input_cog_uri: '',
              aws_secret_access_key: '',
              aws_access_key_id: '',
            });
          }}
        >
          <DeleteIcon />
          Delete
        </Button>
      </ButtonGroup>
    </Stack>
  );
}
