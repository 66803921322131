import { useSpring, animated } from '@react-spring/web';
import { apiCallWithToken } from '../../apiCallWithToken';
import { useAdminInfos } from '../../Dashboard/AdminContext';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { useDialog } from '../../apiCallWithToken/dialogContext';
import axios from 'axios';
import { useUser } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Box from '@mui/material/Box';

const useSignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { setShowDialog } = useDialog();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const { setUser } = useUser();
  const { setAdminInfos } = useAdminInfos();

  const [isloading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const navigate = useNavigate();

  const animationProps = useSpring({
    opacity: error ? 1 : 0,
    transform: error ? 'scale(1)' : 'scale(0.2)',
  });
  const AnimatedBox = animated(Box);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const url = `${process.env.REACT_APP_API_URL}/login`;
    setLoading(true);

    const body = {
      username: data.get('email'),
      password: data.get('password'),
    };

    axios
      .post(url, body)
      .then(async (res) => {
        const userData = res.data;
        localStorage.setItem('refresh_token', userData.refresh_token);
        localStorage.setItem('access_token', userData.access_token);
        localStorage.setItem('isLogged', JSON.stringify(true));
        try {
          const response = await tokenWrapper(() =>
            apiCallWithToken('/users/me'),
          );
          if (response === null) {
            setShowDialog(true);
          }
          setUser(response);
          if (response.groups.includes('Admin')) {
            const adminResponse = await tokenWrapper(() =>
              apiCallWithToken('/admin/users/infos'),
            );

            if (adminResponse === null) {
              setShowDialog(true);
              setAdminInfos({});
            }

            setAdminInfos(adminResponse);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
        // return;
        setLoading(false);
        setShowDialog(false);
        navigate('/dashboard');
      })
      .catch((error) => {
        const { detail } = error.response.data;
        setError(detail);
        setLoading(false);
      });
  };
  return {
    handleSubmit,
    setError,
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
    error,
    AnimatedBox,
    animationProps,
    isloading,
  };
};
export default useSignIn;
