import { apiCallWithToken } from '../components/apiCallWithToken';

export const getAllTransactions = async (queryParams: string) => {
  try {
    const response = await apiCallWithToken(
      `/admin/transactions${queryParams}`,
    );

    return response;
  } catch (error) {
    console.log('Error fetching transactions:', error);
    return [];
  }
};
