/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from 'react';
import ProcessingTable from './processingTable';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import LinearProgress from '@mui/material/LinearProgress';
import { getRunningOrders, getUserOrders } from '../../../api/ordersApi';
import { useDialog } from '../../apiCallWithToken/dialogContext';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';

const ProcessingLog = () => {
  const [userOrders, setUserOrders] = useState<any>([]);
  const fetchInProgressRef = useRef(false);
  const { setShowDialog } = useDialog();
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredOrders, setFilteredOrders] = useState<any>([]);
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const isLoading = userOrders.length === 0;
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<{
    PK: string;
    SK: string;
  } | null>(null);
  const fetchOrders = async () => {
    const queryParams = '?limit=10';
    try {
      await tokenWrapper(() => getRunningOrders());
      const data = await tokenWrapper(() => getUserOrders(queryParams));

      if (data === null) {
        setShowDialog(true);
      }
      setUserOrders(data.Items);
      setFilteredOrders(data.Items);

      setLastEvaluatedKey(data.LastEvaluatedKey);
    } catch (error) {
      console.error('Error fetching orders data:', error);
    }
  };
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value;
    setSearchInput(searchQuery);

    const filtered = userOrders.filter((order: any) => {
      return order?.params?.name?.includes(searchQuery.toLowerCase());
    });

    setFilteredOrders(filtered);
  };
  useLayoutEffect(() => {
    fetchOrders();
    const intervalId = setInterval(() => {
      fetchOrders();
      const originalScrollTop = tableContainerRef?.current?.scrollTop;

      if (originalScrollTop !== undefined && tableContainerRef.current) {
        tableContainerRef.current.scrollTop = originalScrollTop;
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchMoreOrders = useCallback(async () => {
    if (lastEvaluatedKey !== null) {
      if (fetchInProgressRef.current) {
        return;
      }
      fetchInProgressRef.current = true;
      try {
        const queryParams = lastEvaluatedKey
          ? `?last_evaluated_key=${lastEvaluatedKey}&limit=10`
          : '?limit=10';
        const response = await tokenWrapper(() => getUserOrders(queryParams));

        if (response === null) {
          setShowDialog(true);
        }

        setUserOrders((prevOrders: any) => {
          return prevOrders
            ? [...prevOrders, ...response?.Items]
            : response?.Items;
        });
        setFilteredOrders((prevOrders: any) => {
          return prevOrders
            ? [...prevOrders, ...response?.Items]
            : response?.Items;
        });

        setLastEvaluatedKey(response?.LastEvaluatedKey);
      } catch (error) {
        console.log('Error fetching more orders:', error);
      } finally {
        fetchInProgressRef.current = false;
      }
    }
  }, [lastEvaluatedKey, setLastEvaluatedKey, setUserOrders]);
  useEffect(() => {
    const handleScroll = () => {
      if (
        lastEvaluatedKey &&
        tableBodyRef.current &&
        tableContainerRef.current &&
        tableBodyRef.current.scrollHeight -
          tableContainerRef.current.clientHeight <=
          tableContainerRef.current.scrollTop &&
        !fetchInProgressRef.current
      ) {
        fetchMoreOrders();
      }
    };

    const handleResize = () => {
      const container = tableContainerRef.current;
      const body = tableBodyRef.current;

      if (
        lastEvaluatedKey &&
        container &&
        body &&
        container.scrollHeight - container.clientHeight <=
          container.scrollTop &&
        !fetchInProgressRef.current
      ) {
        fetchMoreOrders();
      }
    };
    const containerRef = tableContainerRef.current;
    if (containerRef) {
      containerRef.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (containerRef) {
        containerRef.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [lastEvaluatedKey, fetchMoreOrders, tableBodyRef, tableContainerRef]);

  const handleLoading = () => {
    if (isLoading) {
      return (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      );
    } else {
      return (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ m: 1, height: '8%' }}
          >
            <Box>
              <TextField
                size="medium"
                variant="standard"
                placeholder="Search"
                sx={{ m: 1 }}
                onChange={handleSearchInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <Button
                onClick={() => {
                  setUserOrders([]);
                  fetchOrders();
                }}
                variant="contained"
                color="primary"
                sx={{
                  ml: 1,
                  backgroundColor: '#71BF49',
                  '&:hover': {
                    backgroundColor: '#66AD42',
                  },
                }}
                startIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </Box>
          </Box>
          <ProcessingTable
            fetchOrders={fetchOrders}
            userOrders={filteredOrders}
            lastEvaluatedKey={lastEvaluatedKey}
            tableBodyRef={tableBodyRef}
            tableContainerRef={tableContainerRef}
          />
        </>
      );
    }
  };

  return <Box sx={{ width: '100%', height: '100%' }}>{handleLoading()}</Box>;
};
export default ProcessingLog;
