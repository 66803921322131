import { Card, LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';

interface BasicMenuProps {
  height: number;
  width: number;
}

export default function MenuNotifications({ height, width }: BasicMenuProps) {
  const itemHeight = height / 3;

  const backgroundImageUrl =
    'https://plannerdev.luxcarta.online/app/assets/media/misc/bg-1.jpg';
  const notifications: any[] = [];
  return (
    <Box sx={{ height: height, width: width }}>
      <Box
        sx={{
          height: itemHeight,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          color: 'white',
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Box sx={{ fontSize: 'medium', fontWeight: 'bold' }}>Notifications</Box>
      </Box>

      <Box
        sx={{
          overflow: 'auto',
          maxHeight: height - itemHeight,
        }}
      >
        {notifications.length === 0 ? (
          <>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
            <Box
              sx={{
                height: height / 2,
                padding: '16px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#B5B5C3',
              }}
            >
              The processing log is empty. Why don't you do some processing?
            </Box>
          </>
        ) : (
          <Box>
            {notifications.map((notification, index) => (
              <Card
                key={index}
                sx={{
                  width: '100%',
                  height: height / 5,
                  marginBottom: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {notification}
              </Card>
            ))}
          </Box>
        )}
      </Box>
      {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
    </Box>
  );
}
