/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import {
  Viewer,
  Entity,
  PointPrimitiveCollection,
  PolylineGraphics,
  PolygonGraphics,
  Scene,
  Camera,
  Globe,
  PolylineCollection,
  PointPrimitive,
} from 'resium';
import {
  Color,
  Cartesian2,
  Cartographic,
  Rectangle,
  ScreenSpaceEventType,
} from 'cesium';
import './style.css';
import * as Cesium from 'cesium';
import { useDashboardContext } from '../DashboardContext';
import { Box, Grid } from '@mui/material';
import { apiCallWithToken } from '../../apiCallWithToken';
import HexagonIcon from '@mui/icons-material/Hexagon';
import { useSpring, animated } from '@react-spring/web';
import { prettifyNumber } from '../../Tools/Tools';
import LayerDiplayer from '../LayersDisplayer';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { useDialog } from '../../apiCallWithToken/dialogContext';
import StartProcessAccordion from '../StartProcessAccordion';
import createProviderViewModels from './ImageryProviders';
import SellIcon from '@mui/icons-material/Sell';
const CesiumGlobe = ({
  onOpenModal,
  setModalContent,
  setModalTitle,
  setModalIcon,
}) => {
  const {
    isDrawing,
    stopDrawing,
    drawType,
    file,
    polygon,
    area,
    cost,

    viewerRef,
    wkt_aoi_to_send,
    createPolygon,
    setAreaToPolygon,
    setAoi,
    setPolygonCost,
    results,
    setWktAoi,
    formDataValues,
  } = useDashboardContext();
  const [, setIsOpen] = useState(false);
  const { setShowDialog } = useDialog();

  Cesium.Ion.defaultAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNzFlZTE5Ni0wOTc3LTRlNDEtYmQ4Ny1hMGY0YzVhZDhjNGEiLCJpZCI6NjM3NzQsImlhdCI6MTYzODIwMzg1OH0.t_Qao4QXebBc5E1SIm2iSniMIXd3FU8Gg3HZft4frLU';

  useEffect(() => {
    if (cost === 0) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [cost]);

  const addViVid = () => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const viewer = viewerRef.current.cesiumElement;
    const resource = new Cesium.Resource({
      url: `${API_BASE_URL}/vividtile/{z}/{x}/{y}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    var vivid_imagery = new Cesium.UrlTemplateImageryProvider({
      url: resource,
      tilingScheme: new Cesium.WebMercatorTilingScheme(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    const vivid_imagery_layer = new Cesium.ImageryLayer(vivid_imagery, {
      alpha: 1.0,
    });
    viewer.scene.imageryLayers.add(vivid_imagery_layer);

    return vivid_imagery_layer;

    // var layers = viewer.scene.imageryLayers;
    // layers.add(vivid_imagery_layer);
  };

  useEffect(() => {
    const initializeCesium = async () => {
      if (viewerRef.current.cesiumElement === undefined) {
        // Wait for the viewer to be defined
        await new Promise((resolve) => {
          const checkViewer = () => {
            if (viewerRef.current.cesiumElement !== undefined) {
              resolve();
            } else {
              requestAnimationFrame(checkViewer);
            }
          };
          checkViewer();
        });
      }

      const viewer = viewerRef.current.cesiumElement;
      viewer.scene.globe.depthTestAgainstTerrain = true;
      var baseLayerPicker = viewer.baseLayerPicker;
      var viewModel = baseLayerPicker.viewModel;
      const imageryProviderViewModels =
        baseLayerPicker.viewModel.imageryProviderViewModels;
      if (imageryProviderViewModels.length === 21) return;

      viewer.scene.light = new Cesium.DirectionalLight({
        direction: new Cesium.Cartesian3(1, 0, 0),
      });

      viewer.scene.preRender.addEventListener(function (scene, time) {
        viewer.scene.light.direction = Cesium.Cartesian3.clone(
          scene.camera.directionWC,
          viewer.scene.light.direction,
        );
      });

      const providersToRemove = [];

      for (const imageryProvider of imageryProviderViewModels) {
        if (imageryProvider.name.includes('Stadia')) {
          providersToRemove.push(imageryProvider);
        }
      }

      for (const providerToRemove of providersToRemove) {
        baseLayerPicker.viewModel.imageryProviderViewModels.remove(
          providerToRemove,
        );
      }

      baseLayerPicker.viewModel.terrainProviderViewModels.pop();
      baseLayerPicker.viewModel.terrainProviderViewModels.pop();
      var initialProviderViewModels = viewModel.imageryProviderViewModels;
      var luxcartaProviderViewModels = createProviderViewModels();
      var finalProviderViewModels = luxcartaProviderViewModels.concat(
        initialProviderViewModels,
      );
      viewModel.imageryProviderViewModels = finalProviderViewModels;
      const tableBody = document.querySelector(
        '.cesium-navigation-help-instructions tbody',
      );
      const firstImageSrc = document.querySelector(
        '.cesium-navigation-help-instructions tbody tr:first-child img',
      ).src;

      const newRow = tableBody.insertRow();

      const iconCell = newRow.insertCell();
      const iconImage = document.createElement('img');
      iconImage.src = firstImageSrc;
      iconImage.width = 48;
      iconImage.height = 48;
      iconCell.appendChild(iconImage);

      const detailsCell = newRow.insertCell();
      const customDetails = document.createElement('div');
      const title = document.createElement('div');
      title.textContent = 'Validate AOI';
      title.style.fontWeight = 'bold';
      title.style.color = '#66ccff';
      detailsCell.appendChild(title);
      customDetails.textContent =
        'Double-click to validate the area of interest';
      customDetails.style.color = 'black';
      detailsCell.appendChild(customDetails);
      // addViVid();
    };

    initializeCesium();
  }, []);

  const cameraRef = useRef();

  let firstClick = null;
  let temporaryShapeEntity = null;
  let clickedPoints = [];
  let pos = null;
  const handleMapClick = async (event) => {
    if (!isDrawing) return;
    const { cesiumElement } = viewerRef.current;
    const { x, y } = event.position;
    const scene = cesiumElement.scene;
    if (!scene) return;

    const windowPosition = new Cartesian2(x, y);
    const ray = scene.camera.getPickRay(windowPosition);
    const cartesian = scene.globe.pick(ray, scene);
    const cartographic = Cartographic.fromCartesian(cartesian);
    const longitude = Cesium.Math.toDegrees(cartographic.longitude);
    const latitude = Cesium.Math.toDegrees(cartographic.latitude);
    viewerRef.current.cesiumElement.screenSpaceEventHandler.setInputAction(
      () => {
        viewerRef.current.cesiumElement.infoBox.viewModel.show = false;
      },
      Cesium.ScreenSpaceEventType.LEFT_CLICK,
    );
    if (drawType === 'polygon') {
      pos = [...polygon, ...[cartesian]];
      createPolygon(pos);
      setAoi([...wkt_aoi_to_send, ...[`${longitude} ${latitude}`]]);
      clickedPoints.push(pos);

      viewerRef.current.cesiumElement.screenSpaceEventHandler.setInputAction(
        handleMouseMove,
        ScreenSpaceEventType.MOUSE_MOVE,
      );
    } else {
      if (!firstClick) {
        viewerRef.current?.cesiumElement?.entities?.removeAll();
        firstClick = cartesian;
        temporaryShapeEntity = viewerRef.current?.cesiumElement?.entities?.add({
          rectangle: {
            coordinates: new Rectangle(),
            material: polygonMaterial,
          },
        });

        viewerRef.current.cesiumElement.screenSpaceEventHandler.setInputAction(
          handleMouseMove,
          ScreenSpaceEventType.MOUSE_MOVE,
        );
      } else {
        const rectangle = Rectangle.fromCartesianArray([firstClick, cartesian]);
        temporaryShapeEntity.rectangle.coordinates = rectangle;
        const firstClickCartographic = Cartographic.fromCartesian(firstClick);
        const lon0 = Cesium.Math.toDegrees(firstClickCartographic.longitude);
        const lat0 = Cesium.Math.toDegrees(firstClickCartographic.latitude);
        const lon1 = longitude;
        const lat1 = latitude;

        const pos = [
          Cesium.Cartesian3.fromDegrees(lon0, lat0),
          Cesium.Cartesian3.fromDegrees(lon1, lat0),
          Cesium.Cartesian3.fromDegrees(lon1, lat1),
          Cesium.Cartesian3.fromDegrees(lon0, lat1),
        ];
        const aoi = [
          `${lon0} ${lat0}`,
          `${lon1} ${lat0}`,
          `${lon1} ${lat1}`,
          `${lon0} ${lat1}`,
        ];

        firstClick = null;
        temporaryShapeEntity = null;

        handleContextMenu(pos, aoi);
      }
    }
  };
  const handleContextMenu = async (pos, aoi) => {
    let validatedPolygon = polygon;
    let validatedAoi = wkt_aoi_to_send;
    if (drawType === 'rectangle') {
      validatedPolygon = pos;
      validatedAoi = aoi;
    }
    if (validatedPolygon.length < 1) return;
    viewerRef.current.cesiumElement.screenSpaceEventHandler.removeInputAction(
      ScreenSpaceEventType.MOUSE_MOVE,
    );
    const newPolygon = validatedPolygon;
    let firstCoordinate = newPolygon[0];
    newPolygon.push(firstCoordinate);
    const newWktAoi = validatedAoi;
    firstCoordinate = newWktAoi[0];
    newWktAoi.push(firstCoordinate);
    setAoi(newWktAoi);
    createPolygon(newPolygon);
    stopDrawing();
    setWktAoi(`POLYGON ((${validatedAoi.join(', ')}))`);
    const formData = new FormData();
    for (const key in formDataValues) {
      formData.append(key, formDataValues[key]);
    }
    formData.append('wkt_aoi', `POLYGON ((${validatedAoi.join(', ')}))`);
    const response = await tokenWrapper(() =>
      apiCallWithToken('/cost', 'POST', formData, 'multipart/form-data'),
    );
    if (response === null) {
      setShowDialog(true);
    }
    setAreaToPolygon(response?.area_size);
    setPolygonCost(response?.cost);
  };

  const handleMouseMove = (movement) => {
    const { cesiumElement } = viewerRef.current;
    const { x, y } = movement.endPosition;

    const windowPosition = new Cartesian2(x, y);
    const scene = cesiumElement.scene;
    if (!scene) return;

    const ray = scene.camera.getPickRay(windowPosition);
    const cartesian = scene.globe.pick(ray, scene);
    if (!cartesian) return;

    if (drawType === 'polygon') {
      const allPoints = [...pos, cartesian];

      if (pos.length >= 2) {
        const lastPoint = pos[pos.length - 1];
        const distance = Cesium.Cartesian3.distance(cartesian, lastPoint);
        const minimumDistance = 2;

        if (distance < minimumDistance) {
          return;
        }
      }
      if (temporaryShapeEntity) {
        viewerRef.current?.cesiumElement?.entities?.removeAll();
      }
      temporaryShapeEntity = viewerRef.current?.cesiumElement?.entities?.add({
        polygon: {
          hierarchy: new Cesium.PolygonHierarchy(),
          material: polygonMaterial,
        },
      });
      temporaryShapeEntity.polygon.hierarchy = new Cesium.PolygonHierarchy(
        allPoints,
      );
    } else if (firstClick && temporaryShapeEntity) {
      const rectangle = Rectangle.fromCartesianArray([firstClick, cartesian]);
      temporaryShapeEntity.rectangle.coordinates = rectangle;
    }
  };

  const polygonMaterial = new Color(1.0, 1.0, 1.0, 0.5);

  const handleDrawing = () => {
    if (polygon.length > 1) {
      if (polygon[0] === polygon[polygon.length - 1]) {
        return (
          <Entity name="PolygonGraphics" description="Polygon">
            <PolygonGraphics hierarchy={polygon} material={polygonMaterial} />
          </Entity>
        );
      }
    }
  };

  const handlePoints = () => {
    if (polygon.length === 0) {
      return;
    }
    return (
      <PointPrimitiveCollection show={drawType === 'polygon' ? true : false}>
        {polygon.map((x, i) => (
          <PointPrimitive
            key={i}
            show={isDrawing}
            position={x}
          ></PointPrimitive>
        ))}
      </PointPrimitiveCollection>
    );
  };

  const animationProps = useSpring({
    opacity: area > 0 && cost > 0 ? 1 : 0,
    transform: area > 0 && cost > 0 ? 'scale(1)' : 'scale(0.2)',
  });

  const AnimatedBox = animated(Box);

  const displaySize = () => {
    const data = [
      { name: 'Area', data: area, icon: <HexagonIcon /> },
      { name: 'Cost', data: cost, icon: <SellIcon /> },
    ];

    return (
      <AnimatedBox
        sx={{
          position: 'absolute',
          gap: 1,
          bottom: 5,
          left: 440,
          borderRadius: 2,
          flexDirection: 'column',
          display: 'flex',
          fontSize: 22,
        }}
        style={animationProps}
      >
        {data.map((x, i) => (
          <Grid
            key={i}
            container
            sx={{ bgcolor: 'white', padding: 1, width: 280, borderRadius: 2 }}
          >
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box>{x.icon}</Box>
            </Grid>
            <Grid item xs={8}>
              <Box sx={{ bgcolor: 'white' }}>{x?.name}</Box>
              <Box sx={{ bgcolor: 'white' }}>
                {x?.name === 'Cost'
                  ? prettifyNumber(x?.data)
                  : `${prettifyNumber(x?.data)} km²`}
              </Box>
            </Grid>
          </Grid>
        ))}
      </AnimatedBox>
    );
  };

  useEffect(() => {
    const viewer = viewerRef.current.cesiumElement;
    if (viewerRef.current.cesiumElement === undefined) {
      return;
    }
    if (results.length === 0) {
      viewer.camera.flyHome();
      return;
    }
    if (results[0].rectangle === null) {
      return;
    }
    viewer.camera.flyTo({
      destination: results[0].rectangle,
    });
  }, [results]);

  useEffect(() => {
    const element = document.getElementById('viewer');
    element.addEventListener('contextmenu', handleContextMenu);

    return () => element.removeEventListener('contextmenu', handleContextMenu);
  }, [polygon]);

  const handleUploadFile = (type) => {
    var dataSource;

    if (type === 'geojson') {
      let reader = new FileReader();

      reader.readAsText(file);

      reader.onload = () => {
        const geoJson = JSON.parse(reader.result);

        Cesium.GeoJsonDataSource.load(geoJson, {
          clampToGround: true,
        })
          .then((loadedDataSource) => {
            dataSource = loadedDataSource;
            processDataSource(dataSource);
          })
          .catch((error) => {
            console.error(error);
          });
      };

      reader.onerror = function () {
        console.log(reader.error);
      };
    } else {
      Cesium.KmlDataSource.load(file, {
        clampToGround: true,
      })
        .then((loadedDataSource) => {
          dataSource = loadedDataSource;
          processDataSource(dataSource);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const processDataSource = (dataSource) => {
    const viewer = viewerRef.current.cesiumElement;
    viewer.dataSources.removeAll();
    viewer.dataSources.add(dataSource);

    const multipolygonArray = [];

    dataSource.entities.values.forEach((entity) => {
      if (entity.polygon) {
        const hierarchy = entity.polygon._hierarchy.getValue();
        if (hierarchy && hierarchy.positions) {
          const cartographicPositions = hierarchy.positions.map(
            (cartesianPosition) => {
              const { latitude, longitude } =
                Cesium.Cartographic.fromCartesian(cartesianPosition);
              return {
                latitude: Cesium.Math.toDegrees(latitude),
                longitude: Cesium.Math.toDegrees(longitude),
              };
            },
          );
          const cartographicPosition = hierarchy.positions.map(
            (cartesianPosition) => {
              const { latitude, longitude } =
                Cesium.Cartographic.fromCartesian(cartesianPosition);
              const latInDegrees = Cesium.Math.toDegrees(latitude);
              const lonInDegrees = Cesium.Math.toDegrees(longitude);
              return `${lonInDegrees} ${latInDegrees}`;
            },
          );
          entity.polygon.material = polygonMaterial;
          entity.polygon.fill = entity.polygon.outline = true;
          entity.polygon.outlineColor = Cesium.Color.WHITE;
          viewer.flyTo(dataSource);

          setAoi(cartographicPosition);

          const polygonString = cartographicPositions
            .map(({ longitude, latitude }) => `${longitude} ${latitude}`)
            .join(', ');
          multipolygonArray.push(`((${polygonString}))`);
        }
      } else {
        console.error('Unsupported geometry:');
      }
    });
    const wktAoi =
      multipolygonArray.length > 1
        ? `MULTIPOLYGON ( ${multipolygonArray.join(', ')})`
        : `POLYGON ${multipolygonArray[0]}`;

    setWktAoi(wktAoi);
  };

  useEffect(() => {
    const handleFile = async () => {
      if (file) {
        const type = file.name.split('.')[1];
        if (type === 'kml' || type === 'kmz') {
          handleUploadFile('kml');
        }
        if (type === 'geojson') {
          handleUploadFile('geojson');
        }
      }
    };
    handleFile();
  }, [file]);

  const handleViewerUpdate = (newViewer) => {
    viewerRef.current = newViewer;

    const infoBox = viewerRef?.current?.cesiumElement?._infoBox;

    const applyStyle = (element, style) =>
      element && Object.assign(element.style, style);

    const applyBaseLayerPickerStyles = (selector, styles) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((element) => Object.assign(element.style, styles));
    };

    const applyStyling = () => {
      const container = infoBox?.container;
      const iframe = container?.querySelector(
        '.cesium-infoBox .cesium-infoBox-iframe',
      );
      const description = iframe?.contentDocument.body.querySelector(
        '.cesium-infoBox-description',
      );

      const applyNavigationStyles = (selector, styles) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => applyStyle(element, styles));
      };

      applyNavigationStyles(
        '.cesium-navigation-help, .cesium-navigation-help-visible',
        {
          backgroundColor: 'white',
        },
      );

      applyNavigationStyles(
        '.cesium-click-navigation-help, .cesium-navigation-help-instructions, .cesium-touch-navigation-help, .cesium-touch-navigation-help-visible',
        {
          backgroundColor: '#ebedf3',
        },
      );

      const navigationButtons = document.querySelectorAll(
        '.cesium-navigation-button, .cesium-navigation-button-left, .cesium-navigation-button-selected',
      );
      const navigationDetails = document.querySelectorAll(
        '.cesium-navigation-help-details',
      );

      navigationButtons.forEach((button) =>
        applyStyle(button, {
          backgroundColor: '#ebedf3',
          color: 'black',
        }),
      );
      navigationDetails.forEach((detail) =>
        applyStyle(detail, { color: 'black' }),
      );

      applyStyle(container?.querySelector('.cesium-infoBox-title'), {
        display: 'none',
      });
      applyStyle(container?.querySelector('.cesium-infoBox-camera'), {
        display: 'none',
      });

      const buttonClose = container?.querySelector('.cesium-infoBox-close');
      applyStyle(buttonClose, {
        color: '#206294',
        fontSize: '18px',
        top: '0px',
        right: '2px',
        padding: '0px 5px',
      });

      if (description) {
        applyStyle(description, { color: 'black' });

        const observer = new MutationObserver(() =>
          applyTableStyling(description),
        );
        observer.observe(description, { childList: true, subtree: true });
      }

      const visible = container?.querySelector('.cesium-infoBox');
      applyStyle(visible, { fontSize: '0px' });

      const baseLayerPickerDropDown = document.querySelector(
        '.cesium-baseLayerPicker-dropDown',
      );
      applyStyle(baseLayerPickerDropDown, {
        backgroundColor: '#ebecf5',
        'z-index': 1,
        borderRadius: '4px',
        border: 'none',
        boxShadow: '0 0 10px 1px #000',
      });
    };

    const applyTableStyling = (description) => {
      const container = infoBox?.container;

      const box = container?.querySelector('.cesium-infoBox ');
      applyStyle(box, {
        'z-index': 1,
        border: 'none',
      });
      const iframe = container?.querySelector(
        '.cesium-infoBox .cesium-infoBox-iframe',
      );

      applyStyle(iframe, {
        fontSize: '14px',
        paddingTop: '10px',
        backgroundColor: '#ebecf5',
        color: '#206294',
        borderRadius: '4px',
        height: 'auto',
      });

      const table = description?.querySelector('.cesium-infoBox-defaultTable');
      if (table) {
        applyStyle(table, { borderCollapse: 'collapse', color: '#206294' });
        const applyTableRowStyles = (row) => {
          const thContent = row.querySelector('th')?.textContent;
          if (thContent && thContent.trim() === 'ZSOURCE') {
            row.remove();
          } else {
            applyStyle(row, {
              backgroundColor: 'white',
              border: '2px solid #206294',
            });
            const cells = row.querySelectorAll('td, th');
            cells.forEach((cell) =>
              applyStyle(cell, {
                fontSize: '14px',
                backgroundColor: 'white',
                border: '2px solid rgba(32, 98, 148, 0.08)',
                textAlign: 'center',
                width: '200px',
              }),
            );
          }
        };

        table.querySelectorAll('tr').forEach(applyTableRowStyles);
      }
    };

    applyBaseLayerPickerStyles('.cesium-baseLayerPicker-choices', {
      backgroundColor: 'white',
      border: 'none',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)',
    });
    applyBaseLayerPickerStyles('.cesium-baseLayerPicker-categoryTitle', {
      color: 'black',
      fontSize: '9pt',
      margin: '3px ',
    });

    applyBaseLayerPickerStyles('.cesium-baseLayerPicker-sectionTitle', {
      color: '#206294',
      fontSize: '14px',
    });

    applyBaseLayerPickerStyles('.cesium-baseLayerPicker-itemLabel', {
      color: '#206294',
    });
    applyBaseLayerPickerStyles('.cesium-sceneModePicker-wrapper', {
      display: 'none',
    });
    applyStyling();
  };

  const createTerrain = async () => {
    try {
      const terrain_url = 'https://terrain.luxcarta.online/terrain/luxcarta/';

      const response = await apiCallWithToken('/users/me/orders');
      if (response.data === null) {
        console.error('Invalid token.');
        return null; // or throw an error
      }
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('Token is missing.');
        return null; // or throw an error
      }
      const resource = new Cesium.Resource({
        url: terrain_url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const terrain_provider = Cesium.CesiumTerrainProvider.fromUrl(resource, {
        requestVertexNormals: false,
      });
      return terrain_provider;
    } catch (error) {
      console.error('Error creating terrain:', error);
      return null; // or throw an error
    }
  };

  // const createTerrain = () => {
  //   const terrain_url = 'https://terrain.luxcarta.online/terrain/luxcarta/';
  //   // const terrain_url = 'https://planner.luxcarta.online/demo/terrain/luxcarta';
  //   const resource = new Cesium.Resource({
  //     url: terrain_url,
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  //     },
  //   });
  //   const terrain_provider = Cesium.CesiumTerrainProvider.fromUrl(resource, {
  //     requestVertexNormals: false,
  //   });
  //   return terrain_provider;
  // };

  return (
    <Viewer
      ref={handleViewerUpdate}
      full
      terrainProvider={createTerrain()}
      id="viewer"
      style={
        isDrawing
          ? {
              cursor: 'crosshair',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }
          : {
              cursor: 'auto',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }
      }
      timeline={false}
      animation={false}
      onClick={handleMapClick}
      onDoubleClick={handleContextMenu}
    >
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isMapLoading}
 
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

      <Globe enableLighting={false}>
        <Scene>
          {/* {handleFiles()} */}
          <Box sx={{ position: 'absolute', bottom: 5, right: 5 }}>
            <LayerDiplayer />
          </Box>

          <Box sx={{ position: 'absolute', bottom: 5, left: 5 }}>
            <StartProcessAccordion
              onOpenModal={onOpenModal}
              setModalContent={setModalContent}
              setModalTitle={setModalTitle}
              setModalIcon={setModalIcon}
            />
          </Box>

          <Camera ref={cameraRef}>
            {displaySize()}
            {handleDrawing()}
            {handlePoints()}
            <Entity name="PolygonGraphics" description="Polygon1">
              <PolylineCollection show={true}>
                <PolylineGraphics
                  show={isDrawing}
                  positions={polygon}
                  clampToGround={true}
                />
              </PolylineCollection>
            </Entity>
          </Camera>
        </Scene>
      </Globe>
    </Viewer>
    // </div>
  );
};

export default CesiumGlobe;
