import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { useDashboardContext } from '../Dashboard/DashboardContext';

function Copyright() {
  return (
    <Typography
      sx={{ color: 'white' }}
      variant="body2"
      color="text.secondary"
      align="center"
    >
      {'Copyright © '}
      <Link color="inherit" href="https://www.luxarta.com/">
        LuxCarta
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer: React.FC = () => {
  const { isMapLoading, setLeftMenuOpen, isLeftMenuOpen } =
    useDashboardContext();

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        bgcolor: '#206294',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      }}
    >
      <Box sx={{ flexGrow: 1, ml: 10 }}>
        {/* <Button
          variant="outlined"
          sx={{ color: 'white' }}
          onClick={() => localStorage.setItem('access_token', 'access_token')}
        >
          About BrightEarth
        </Button>
        <Button
          variant="outlined"
          sx={{ color: 'white' }}
          onClick={() => localStorage.setItem('refresh_token', 'refresh_token')}
        >
          About BrightEarth
        </Button> */}
        {process.env.REACT_APP_MODE === 'local' && !isLeftMenuOpen && (
          <Button
            variant="outlined"
            sx={{ color: 'white' }}
            onClick={() => setLeftMenuOpen(true)}
          >
            Show menu
          </Button>
        )}
      </Box>
      <Box sx={{ mr: 10 }}>{Copyright()}</Box>
    </Box>
  );
};

export default Footer;
