import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import AccordionWrapper from '../AccordionWrapper';
import { Help } from '../Help/index';
import { Typography } from '@mui/material';

interface AccordionProps {
  component: any;
  onOpenModal: any;
  title: string;
  icon: any;
  setModalContent: any;
  setModalTitle: any;
  setModalIcon?: any;
}

export default function BasicAccordion({
  component,
  onOpenModal,
  title,
  icon,
  setModalContent,
  setModalTitle,
  setModalIcon,
}: AccordionProps) {
  const displayModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    onOpenModal();
    setModalContent(
      <AccordionWrapper
        array={Help?.find((x) => x?.name === title)?.content}
      />,
    );
    setModalTitle(title);
    setModalIcon(icon);
  };

  return (
    <Box>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            minHeight: '0px',
          }}
          sx={{
            '.MuiAccordionSummary-content.Mui-expanded': {
              margin: '0px',
            },
          }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '15px',
              fontWeight: 600,
            }}
          >
            {icon}
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <Button onClick={displayModal}>
              {' '}
              <HelpIcon />
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Divider /> */}
          <Box>{component}</Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
