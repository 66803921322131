import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './components/AuthContext';
import MultiProvider from './contexts/multiProvider';
import { DashboardContext } from './components/Dashboard/DashboardContext';
import { SnackbarProvider } from './components/SnackbarProvider';
import { SnackbarErrorProvider } from './components/SnackbarErrorProvider';
import { AdminInfosProvider } from './components/Dashboard/AdminContext';
import { DialogProvider } from './components/apiCallWithToken/dialogContext';
import { ConfirmationModalProvider } from './components/ConfirmationModalProvider';
import { ProgressProvider } from './components/ProgressProvider';
import { ConfirmUserProvider } from './components/Dashboard/ConfirmUser';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <MultiProvider
    providers={[
      <UserProvider children />,
      <SnackbarProvider children />,
      <SnackbarErrorProvider children />,
      <AdminInfosProvider children />,
      <DialogProvider children></DialogProvider>,
      <ConfirmationModalProvider children />,
      <ProgressProvider children />,
      <ConfirmUserProvider children />,
    ]}
  >
    <DashboardContext>
      <App />
    </DashboardContext>
  </MultiProvider>,
);

reportWebVitals();
