/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PublicIcon from '@mui/icons-material/Public';
import PolylineIcon from '@mui/icons-material/Polyline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useEffect } from 'react';
import { getUserById } from '../../../api/usersApi';
import ApartmentIcon from '@mui/icons-material/Apartment';
import useUsers from './useUsers';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import VerifiedIcon from '@mui/icons-material/Verified';
import BlockIcon from '@mui/icons-material/Block';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CloseIcon from '@mui/icons-material/Close';
import { useDialog } from '../../apiCallWithToken/dialogContext';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';

const UserModal = (props: any) => {
  const { setShowDialog } = useDialog();

  const {
    setUser,
    user,
    isUserLoading,
    handleConfirmAccount,
    handleDisableAccount,
    handleDeleteConfirmation,
    setAddAmount,
    handleCalculate,
    setSubAmount,
    isActionLoading,
    currentOperation,
    addAmount,
    handleEnableAccount,
    subAmount,
    handleSelectChange,
  } = useUsers(props);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userData = await tokenWrapper(() =>
          getUserById(props.selectedUser.sub),
        );
        if (userData === null) {
          setShowDialog(true);
        }
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUsers();
  }, []);

  return (
    <>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography variant="h6">User Information</Typography>
            {user?.enabled === false ? (
              <Alert severity="warning" sx={{ marginLeft: '16px' }}>
                This user is disabled
              </Alert>
            ) : null}
          </Box>
          <IconButton onClick={() => props.setIsModalOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Card
        sx={{
          width: 1000,
          minHeight: 600,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isUserLoading ? (
          <CircularProgress sx={{ height: 10, m: 1 }} size={20} />
        ) : (
          <CardContent
            sx={{
              m: 1,
              width: '100%',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'row',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Box sx={{ flex: 1, overflow: 'auto' }}>
              <Box
                sx={{
                  m: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                }}
              >
                <FormControl variant="standard">
                  <TextField
                    size="small"
                    label="Name"
                    value={user?.first_name || ''}
                    sx={{
                      minWidth: '100%',
                      '& input::placeholder': {
                        fontSize: '15px',
                      },
                      '& label': {
                        color: '#3F4254',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl variant="standard" style={{ marginTop: '2rem' }}>
                  <TextField
                    size="small"
                    label={'Surname'}
                    value={user?.last_name || ''}
                    sx={{
                      minWidth: '100%',
                      '& input::placeholder': {
                        fontSize: '15px',
                      },
                      '& label': {
                        color: '#3F4254',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl variant="standard" style={{ marginTop: '2rem' }}>
                  <TextField
                    size="small"
                    sx={{
                      minWidth: '100%',
                      '& input::placeholder': {
                        fontSize: '15px',
                      },
                      '& label': {
                        color: '#3F4254',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                    }}
                    value={user?.email || ''}
                    label={'Email Address'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl variant="standard" style={{ marginTop: '2rem' }}>
                  <TextField
                    size="small"
                    label={'Type'}
                    value={
                      (user && user.groups && user.groups.join(', ')) || ''
                    }
                    sx={{
                      minWidth: '100%',
                      '& input::placeholder': {
                        fontSize: '15px',
                      },
                      '& label': {
                        color: '#3F4254',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AdminPanelSettingsIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl variant="standard" style={{ marginTop: '2rem' }}>
                  <TextField
                    size="small"
                    sx={{
                      minWidth: '100%',
                      '& label': {
                        color: '#3F4254',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                    }}
                    value={user?.company_name || ''}
                    label={'Company name'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ApartmentIcon
                            sx={{ marginRight: 1, color: ' #8E8E8E' }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              color: ' #8E8E8E',
                            }}
                          ></Box>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl variant="standard" style={{ marginTop: '2rem' }}>
                  <TextField
                    size="small"
                    label={'Country'}
                    value={user?.country || ''}
                    sx={{
                      minWidth: '100%',
                      '& input::placeholder': {
                        fontSize: '15px',
                      },
                      '& label': {
                        color: '#3F4254',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PublicIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl variant="standard" style={{ marginTop: '2rem' }}>
                  <TextField
                    size="small"
                    sx={{
                      minWidth: '100%',
                      '& label': {
                        color: '#3F4254',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                    }}
                    type="number"
                    value={user && user['area-processed']}
                    label={'Maximum AOI Size'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PolylineIcon
                            sx={{ marginRight: 1, color: ' #8E8E8E' }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              color: ' #8E8E8E',
                            }}
                          >
                            Km²
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl variant="standard" style={{ marginTop: '2rem' }}>
                  <TextField
                    value={
                      (user && user.groups && user.groups.join(', ')) || ''
                    }
                    onChange={handleSelectChange}
                    select
                    size="small"
                    variant="outlined"
                    label="Group"
                    sx={{
                      minWidth: '100%',
                      '& label': {
                        color: '#3F4254',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                    }}
                  >
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Customer">Customer</MenuItem>
                    <MenuItem value="Prod">Prod</MenuItem>
                  </TextField>
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                m: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <FormControl variant="standard" sx={{ flex: 3 }}>
                  <TextField
                    size="small"
                    sx={{
                      minWidth: '100%',
                      '& label': {
                        color: '#3F4254',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                    }}
                    value={
                      user?.credits
                        ? parseFloat(String(user?.credits)).toFixed(2)
                        : 0
                    }
                    label={'Credits'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyIcon
                            sx={{ marginRight: 1, color: ' #8E8E8E' }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              color: ' #8E8E8E',
                            }}
                          >
                            credits
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </FormControl>

                <Box sx={{ flex: 1 }}></Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '2rem',
                }}
              >
                <FormControl variant="standard" sx={{ flex: 3 }}>
                  <TextField
                    size="small"
                    sx={{
                      minWidth: '100%',
                      '& label': {
                        color: '#3F4254',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                    }}
                    type="number"
                    value={addAmount}
                    onChange={(e: any) => {
                      setAddAmount(e.target.value);
                    }}
                    label={'Add'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </FormControl>

                <Box sx={{ flex: 1 }}>
                  <Button
                    disabled={!addAmount}
                    variant="contained"
                    sx={{ ml: 1, width: '100%' }}
                    onClick={() => {
                      handleCalculate('Addition');
                    }}
                    startIcon={
                      isActionLoading &&
                      currentOperation === 'Addition' ? null : (
                        <AddIcon />
                      )
                    }
                  >
                    {isActionLoading && currentOperation === 'Addition' ? (
                      <CircularProgress
                        sx={{ color: 'white', height: 10 }}
                        size={20}
                      />
                    ) : (
                      'CREDIT'
                    )}
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '2rem',
                }}
              >
                <FormControl variant="standard" sx={{ flex: 3 }}>
                  <TextField
                    size="small"
                    sx={{
                      minWidth: '100%',
                      '& label': {
                        color: '#3F4254',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      },
                      '& input': {
                        fontSize: '14px',
                      },
                    }}
                    value={subAmount}
                    type="number"
                    onChange={(e: any) => {
                      setSubAmount(e.target.value);
                    }}
                    label={'Debit'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </FormControl>

                <Box sx={{ flex: 1 }}>
                  <Button
                    disabled={!subAmount}
                    variant="contained"
                    sx={{ ml: 1, width: '100%' }}
                    onClick={() => {
                      handleCalculate('Subtract');
                    }}
                    startIcon={
                      isActionLoading &&
                      currentOperation === 'Subtract' ? null : (
                        <RemoveIcon />
                      )
                    }
                  >
                    {isActionLoading && currentOperation === 'Subtract' ? (
                      <CircularProgress
                        sx={{ color: 'white', height: 10 }}
                        size={20}
                      />
                    ) : (
                      'DEBIT'
                    )}
                  </Button>
                </Box>
              </Box>
              <Divider sx={{ mt: 7 }}>User Actions</Divider>
              <Button
                variant="contained"
                sx={{ mt: '2rem' }}
                size="medium"
                onClick={handleConfirmAccount}
                disabled={user?.user_status === 'CONFIRMED'}
                startIcon={
                  isActionLoading && currentOperation === 'confirm' ? null : (
                    <VerifiedIcon />
                  )
                }
              >
                {isActionLoading && currentOperation === 'confirm' ? (
                  <CircularProgress
                    sx={{ color: 'white', height: 10 }}
                    size={20}
                  />
                ) : (
                  'Accept User'
                )}
              </Button>
              {user?.enabled === false ? (
                <Button
                  variant="contained"
                  sx={{ mt: '2rem' }}
                  size="medium"
                  onClick={handleEnableAccount}
                  startIcon={
                    isActionLoading && currentOperation === 'enable' ? null : (
                      <TaskAltIcon />
                    )
                  }
                >
                  {isActionLoading && currentOperation === 'enable' ? (
                    <CircularProgress
                      sx={{ color: 'white', height: 10 }}
                      size={20}
                    />
                  ) : (
                    'Enable User'
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ mt: '2rem' }}
                  size="medium"
                  onClick={handleDisableAccount}
                  startIcon={
                    isActionLoading && currentOperation === 'disable' ? null : (
                      <BlockIcon />
                    )
                  }
                >
                  {isActionLoading && currentOperation === 'disable' ? (
                    <CircularProgress
                      sx={{ color: 'white', height: 10 }}
                      size={20}
                    />
                  ) : (
                    'Disable User'
                  )}
                </Button>
              )}

              <Button
                variant="contained"
                sx={{ mt: '2rem' }}
                size="medium"
                onClick={() => {
                  handleDeleteConfirmation(user?.sub);
                }}
                startIcon={
                  !isActionLoading && currentOperation === 'delete' ? null : (
                    <PersonRemoveIcon />
                  )
                }
              >
                {isActionLoading && currentOperation === 'delete' ? (
                  <CircularProgress
                    sx={{ color: 'white', height: 10 }}
                    size={20}
                  />
                ) : (
                  'delete User'
                )}
              </Button>
            </Box>
          </CardContent>
        )}
      </Card>
    </>
  );
};
export default UserModal;
