import { Box } from '@mui/material';
import UsersTable from './usersTable';

const UsersCard = () => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <UsersTable />
    </Box>
  );
};
export default UsersCard;
