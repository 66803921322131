import * as React from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import LayerAccordionElement from './LayerAccordionElement';
import { useDashboardContext } from '../DashboardContext';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import LayerAccordionHeader from './LayerAccordionHeader';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { getUserOrders } from '../../../api/ordersApi';

interface Params {
  formats: {
    raster: string[];
    vector: string[];
  };
  layers: string[];
  name: string;
  resolution: string;
  srs: string;
  type: string;
}

interface IOrder {
  params: Params;

  orderId: string;
  userId: string;
  state: string;
}

export default function LayerDiplayer() {
  const { results, setResults } = useDashboardContext();
  const [accordionOpen, setAccordionOpen] = React.useState(false);
  const storedOrderId = window.history.state?.usr?.orderId;

  React.useEffect(() => {
    if (storedOrderId) {
      setAccordionOpen(true);
    }
  }, [storedOrderId]);

  React.useEffect(() => {
    const handleBeforeUnload = () => {
      window.history.replaceState(
        {
          ...window.history.state,
          usr: { ...window.history.state?.usr, orderId: null },
        },
        '',
      );
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = '?limit=100';

        // Assume tokenWrapper and getUserOrders are defined elsewhere
        const data = await tokenWrapper(() => getUserOrders(queryParams));

        // Filter orders with 'GSI2-PK' equal to 'CREATED'
        const filteredOrders = data.Items.filter(
          (order: any) => order['GSI2-PK']?.split('#')[1] === 'CREATED',
        );

        // Map orders to a simplified format
        const mappedOrders = filteredOrders.map((row: any) => ({
          layers: null,
          orderId: row['SK'].split('#')[1],
          params: row.params,
          rectangle: null,
          state: row['GSI2-PK'].split('#')[1],
          userId: row['PK'].split('#')[1],
        }));

        // Filter out orders that already exist in results based on their ID
        const uniqueMappedOrders = mappedOrders.filter(
          (order: any) =>
            !results.some((existingOrder) => existingOrder.id === order.id),
        );

        // Update results state with unique orders
        setResults((prev) => [...prev, ...uniqueMappedOrders]);
      } catch (error) {
        console.error('Error fetching user orders:', error);
      }
    };

    // Fetch data when the component mounts
    fetchData();
  }, []); // The empty dependency array ensures this effect runs only once on mount

  const handleGetOrders = () => {
    if (!results || results.length === 0) {
      return (
        <Box>
          <Alert severity="info">The order list is empty</Alert>
        </Box>
      );
    }
    return [...results].map((order: IOrder, i: number) => {
      return (
        <Box key={i}>
          <Accordion
            defaultExpanded={order.orderId === storedOrderId ? true : false}
          >
            <LayerAccordionHeader order={order} />
            <AccordionDetails>
              <Box>
                {order.state === 'SUCCEEDED' ? (
                  <LayerAccordionElement order={order} />
                ) : order.state === 'CREATED' ? (
                  <LinearProgressWithLabel orderId={order.orderId} />
                ) : (
                  <Typography color="tomato" ml={'16px'}>
                    Progress stopped
                  </Typography>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      );
    });
  };

  return (
    <Accordion
      sx={{ width: 410, bgcolor: '#ebecf5' }}
      expanded={accordionOpen}
      onChange={(event, expanded) =>
        setAccordionOpen((prevAccordionOpen) => !prevAccordionOpen)
      }
    >
      <AccordionSummary
        expandIcon={<ExpandLessIcon sx={{ color: '#206294' }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          minHeight: '0px',
        }}
        sx={{
          '.MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px',
            marginTop: '10px',
          },
        }}
      >
        <Typography sx={{ color: '#206294' }}>Orders</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            overflow: 'auto',
            height: `calc(90vh - 130px)`,
            display: 'flex',
            gap: 1,
            flexDirection: 'column',
          }}
        >
          {handleGetOrders()}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
