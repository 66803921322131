/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { AllUsers } from '../../../contexts/types/userType';
import {
  changeGroup,
  confirmAccount,
  creditAccount,
  debitAccount,
  deleteAccount,
  disableAccount,
  enableAccount,
  getAllUsers,
} from '../../../api/usersApi';
import { useSnackbar } from '../../SnackbarProvider';
import { tokenWrapper } from '../../apiCallWithToken/tokenWrapper';
import { useDialog } from '../../apiCallWithToken/dialogContext';
import { useConfirmationModal } from '../../ConfirmationModalProvider';
import { useUser } from '../../AuthContext';

const useUsers = (props?: any) => {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<AllUsers | null>(
    null,
  );

  const fetchInProgressRef = useRef(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [currentOperation, setCurrentOperation] = useState<string>('');
  const { showConfirmationModal } = useConfirmationModal();
  const { userList, setUserList } = useUser();

  const [selectedUser, setSelectedUser] = useState<AllUsers | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [user, setUser] = useState<AllUsers | null>(null);
  const [addAmount, setAddAmount] = useState<any>();
  const [subAmount, setSubAmount] = useState<any>();
  const { setShowDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  // const isLoading = userList?.length === 0;
  const isUserLoading = user === null;

  const usersArray = userList;

  const handleRowClick = (user: AllUsers) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleSortClick = (columnId: string) => {
    const isAscending = orderBy === columnId && order === 'asc';
    const newOrder = isAscending ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(columnId);
  };
  const styles = {
    Admin: {
      backgroundColor: '#FFF4DE',
      color: '#FFA800',
    },
    Employee: {
      backgroundColor: '#EBEDF3',
      color: '#71BF49',
    },
    Standard: {
      backgroundColor: '#E1F0FF',
      color: '#206294',
    },
    'Sup. Admin': {
      backgroundColor: '#FFE2E5',
      color: '#F64E60',
    },
    USER: {
      backgroundColor: '#EBEDF3',
      color: '#71BF49',
    },
  };

  const columns = [
    { id: 'id', label: '#' },
    { id: 'userFirstName', label: 'User' },
    { id: 'email', label: 'Email' },
    { id: 'userType', label: 'User Type' },
    { id: 'country', label: 'Country' },
    { id: 'signupDate', label: 'Signup Date' },
    { id: 'maxAOIZise', label: 'Max AOI Size' },
    { id: 'credits', label: 'Credits' },
  ];

  const descendingComparator = (a: AllUsers, b: AllUsers, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };
  const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
    return order === 'desc'
      ? (a: AllUsers, b: AllUsers) => descendingComparator(a, b, orderBy)
      : (a: AllUsers, b: AllUsers) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (
    array: AllUsers[],
    comparator: (a: AllUsers, b: AllUsers) => number,
  ) => {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [AllUsers, number],
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  };

  const fetchMoreUsers = useCallback(async () => {
    if (lastEvaluatedKey !== null && !fetchInProgressRef.current) {
      fetchInProgressRef.current = true;
      try {
        const queryParams = lastEvaluatedKey
          ? `?last_evaluated_key=${lastEvaluatedKey}&limit=10`
          : '?limit=10';

        const response = await tokenWrapper(() => getAllUsers(queryParams));
        if (response === null) {
          setShowDialog(true);
        }
        setUserList((prevUsers: any) =>
          prevUsers ? [...prevUsers, ...response?.Items] : response?.Items,
        );
        setLastEvaluatedKey(response?.LastEvaluatedKey);
      } catch (error) {
        console.log('Error fetching more users:', error);
      } finally {
        fetchInProgressRef.current = false;
      }
    }
  }, [lastEvaluatedKey, setUserList, setLastEvaluatedKey]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        lastEvaluatedKey &&
        tableBodyRef.current &&
        tableContainerRef.current &&
        tableBodyRef.current.scrollHeight -
          tableContainerRef.current.clientHeight <=
          tableContainerRef.current.scrollTop &&
        !fetchInProgressRef.current
      ) {
        fetchMoreUsers();
      }
    };

    const handleResize = () => {
      const container = tableContainerRef.current;
      const body = tableBodyRef.current;

      if (
        lastEvaluatedKey &&
        container &&
        body &&
        container.scrollHeight - container.clientHeight <=
          container.scrollTop &&
        !fetchInProgressRef.current
      ) {
        fetchMoreUsers();
      }
    };
    const containerRef = tableContainerRef.current;
    if (containerRef) {
      containerRef.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (containerRef) {
        containerRef.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [lastEvaluatedKey, fetchMoreUsers, tableBodyRef, tableContainerRef]);
  const fetchUsers = useCallback(async (queryParams: any) => {
    setIsLoading(true);

    try {
      const userData = await tokenWrapper(() => getAllUsers(queryParams));
      if (userData === null) {
        setShowDialog(true);
      }
      setUserList(userData.Items);
      setLastEvaluatedKey(userData.LastEvaluatedKey);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);
  useEffect(() => {
    fetchUsers('?limit=30');
  }, []);

  const handleCalculate = async (calculate: string) => {
    try {
      let response;
      setIsActionLoading(true);
      setCurrentOperation(calculate);

      if (calculate === 'Addition') {
        response = await tokenWrapper(() =>
          creditAccount(user?.sub, addAmount),
        );
        if (response === null) {
          setShowDialog(true);
        }
        setAddAmount('');
      } else if (calculate === 'Subtract') {
        response = await tokenWrapper(() => debitAccount(user?.sub, subAmount));
        if (response === null) {
          setShowDialog(true);
        }
        setSubAmount('');
      }

      if (response) {
        if (response.status === 'SUCCESS') {
          setUser((prev: any) => ({
            ...prev,
            credits:
              (prev.credits || 0) +
              (calculate === 'Addition'
                ? parseFloat(addAmount || 0)
                : -parseFloat(subAmount || 0)),
          }));

          const successMessage =
            calculate === 'Addition'
              ? 'Credits successfully added'
              : 'Credits successfully debited';

          showSnackbar(successMessage, '#4CAF50', 'white');
          setIsActionLoading(false);
          setCurrentOperation('');
        } else {
          const failedMessage =
            calculate === 'Addition'
              ? 'Failed to add credits'
              : 'Failed to debit credits';

          showSnackbar(failedMessage, 'tomato', 'white');
          setIsActionLoading(false);
          setCurrentOperation('');
        }
      }
    } catch (error) {
      console.error('Error updating attributes:', error);
      showSnackbar('An error occurred', 'tomato', 'white');
      setIsActionLoading(false);
      setCurrentOperation('');
    }
  };

  const handleOperation = async (
    operationFn: (sub: string | undefined) => Promise<any>,
    updateEnabled?: boolean,
  ) => {
    try {
      setIsActionLoading(true);
      const response = await tokenWrapper(() => operationFn(user?.sub));
      if (response === null) {
        setShowDialog(true);
      } else if (response && response.detail) {
        showSnackbar(response.detail, '#4CAF50', 'white');
        setIsActionLoading(false);

        setCurrentOperation('');
        if (updateEnabled !== undefined) {
          setUser((prev: any) => ({
            ...prev,
            enabled: updateEnabled,
          }));
        }
      } else {
        showSnackbar('Failed', 'tomato', 'white');
        setIsActionLoading(false);
        setCurrentOperation('');
      }
    } catch (error) {
      console.error('Error:', error);
      setIsActionLoading(false);
      setCurrentOperation('');
    }
  };
  const handleSelectChange = async (event: any) => {
    const newGroupName = event.target.value;

    try {
      const response = await tokenWrapper(() =>
        changeGroup(user?.sub, newGroupName),
      );
      if (response === null) {
        setShowDialog(true);
      } else if (response && response.detail) {
        setUser((prev: any) => ({
          ...prev,
          groups: [newGroupName, ...prev.groups.slice(1)],
        }));
        showSnackbar(response.detail, '#4CAF50', 'white');
      } else {
        showSnackbar('Failed', 'tomato', 'white');
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const handleCopyClick = (event: any, id: any) => {
    event.stopPropagation();

    navigator.clipboard
      .writeText(id)
      .then(() => {
        showSnackbar('User Id copied', '#4CAF50', 'white');
      })
      .catch((err) => {
        showSnackbar('Failed to copy', 'tomato', 'white');

        console.error('Failed to copy: ', err);
      });
  };
  const handleConfirmAccount = () => handleOperation(confirmAccount);
  const handleDisableAccount = () => handleOperation(disableAccount, false);
  const handleEnableAccount = () => handleOperation(enableAccount, true);
  let deletedUsersList: any = [];
  const handleDeleteAccount = useCallback(async (id?: string | undefined) => {
    try {
      await deleteAccount(id);
      deletedUsersList.push(id);
      const filteredList = userList.filter(
        (e: any) => !deletedUsersList.includes(e?.sub),
      );
      setUserList(filteredList);
      props.setIsModalOpen(false);
    } catch (error) {
      console.error('Error: ', error);
    }
  }, []);
  const handleDeleteConfirmation = (id: any) => {
    showConfirmationModal(`Are you sure you want to delete the user?`, () =>
      handleDeleteAccount(id),
    );
  };

  return {
    setUser,
    setAddAmount,
    handleCalculate,
    handleCopyClick,
    userList,
    setSubAmount,
    user,
    isUserLoading,
    handleConfirmAccount,
    handleDisableAccount,
    handleDeleteAccount,

    handleDeleteConfirmation,
    tableContainerRef,
    columns,
    handleSortClick,
    tableBodyRef,
    isLoading,
    stableSort,
    usersArray,
    getComparator,
    order,
    orderBy,
    handleRowClick,
    styles,
    isModalOpen,
    setIsModalOpen,
    selectedUser,
    lastEvaluatedKey,
    isActionLoading,
    currentOperation,
    addAmount,
    subAmount,
    handleEnableAccount,
    handleSelectChange,
  };
};

export default useUsers;
