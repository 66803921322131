import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Alert, Box, Grid } from '@mui/material';
import AdminInformation from '../AdminInformation';
import JobsManagementCard from './jobsManagementCard';

import useOrders from '../ProcessingJobsCard/useOrders';

const ProcessingJobsManagement = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    marginTop: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const {
    tableContainerRef,
    tableBodyRef,
    orderArray,
    lastEvaluatedKey,
    handleRowClick,
    selectedRow,
  } = useOrders();
  return (
    <Box
      sx={{
        mt: '70px',
        minHeight: `calc(100vh - (90px) )`,
        bgcolor: '#eef0f8',
      }}
    >
      <Grid container sx={{ padding: 2 }} spacing={'20px'}>
        <Grid item xs={12}>
          <Item>
            <AdminInformation />
          </Item>
          <Item>
            <Alert
              severity="info"
              sx={{
                backgroundColor: 'white',
                display: 'flex',
                minHeight: 50,
                alignItems: 'center',
              }}
            >
              All orders are listed below. Use the search box to search any
              column. Click a header to sort by that column.
            </Alert>
          </Item>
          <Item>
            <JobsManagementCard
              tableContainerRef={tableContainerRef}
              tableBodyRef={tableBodyRef}
              orderArray={orderArray}
              lastEvaluatedKey={lastEvaluatedKey}
              selectedRow={selectedRow}
              onRowClick={handleRowClick}
            />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProcessingJobsManagement;
